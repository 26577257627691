import type { Ref } from 'vue';
import { computed } from 'vue';
import type { ContentTheme } from '@/components/ContentTable/types';
import { simplifyTailwindClasses } from '@/utils/simplifyTailwindClasses';

const headerThemeClasses: Record<ContentTheme, string> = {
  none: 'bg-transparent',
  default: '',
  gray: 'border-1 border-[#D7DFEA] bg-[#F2F4F8] p-4 text-black rounded-t-lg',
  light: 'border-1 border-[#D7DFEA] bg-[#F2F4F8] p-4 text-black rounded-t-lg',
};

const cellThemeClasses: Record<ContentTheme, string> = {
  none: 'bg-transparent',
  default: 'border p-3',
  gray: 'h-30 min-h-30 border-none my-0.5',
  light: 'h-12 min-h-12 border-none !rounded-none bg-white',
};

const cellTitleThemeClasses: Record<ContentTheme, string> = {
  none: '',
  default: '',
  gray: 'border-1 !border-[#D7DFEA] !bg-[#F2F4F8] p-4 rounded-bl-none rounded-tl-none !rounded-r-lg h-30 min-h-30 my-0.5 ml-1',
  light:
    'border-1 !border-[#D7DFEA] !bg-[#F2F4F8] rounded-bl-none rounded-tl-none !rounded-r-lg h-12 min-h-12 p-1 py-2',
};

const headerThemeWidth: Record<ContentTheme, { width?: string }> = {
  none: {},
  default: {},
  gray: { width: '175px' },
  light: { width: '175px' },
};

const headerTitleThemeWidth: Record<ContentTheme, { width?: string }> = {
  none: {},
  default: {},
  gray: { width: '83px' },
  light: { width: '83px' },
};

export type UseInputClassesProps = {
  theme: Ref<ContentTheme>;
};

export function useContentClasses(props: UseInputClassesProps | any): {
  headerClasses: Ref<string>;
  cellClasses: (key: string) => string;
  headerWidth: (key: string) => { width?: string };
} {
  const headerClasses = computed(() => {
    return simplifyTailwindClasses(
      headerThemeClasses[props.theme.value],
      'justify-center',
    );
  });

  const cellClasses = (key: string) => {
    let cellClasses = cellThemeClasses[props.theme.value];
    if (key === 'title') {
      cellClasses = cellTitleThemeClasses[props.theme.value];
    }
    return simplifyTailwindClasses(
      props.display.value === 'flex'
        ? 'border min-h-60 bg-secondary-75 border-secondary-300 rounded-lg'
        : '',
      cellClasses,
    );
  };

  const headerWidth = (key: string) => {
    if (key === 'title') {
      return headerTitleThemeWidth[props.theme.value];
    }
    return headerThemeWidth[props.theme.value];
  };

  return {
    cellClasses,
    headerWidth,
    headerClasses,
  };
}
