<template>
  <PVDialog
    v-if="isOpen"
    v-model:visible="isOpen"
    :draggable="false"
    :modal="true"
    :closable="true"
    :dismissable-mask="true"
    :style="{ width: '40rem' }"
    :pt="{
      content: {
        style: {
          boxShadow: 'inset 0 -2px 4px 0 rgba(0, 0, 0, 0.05)',
        },
      },
    }"
    @hide="emit('close')"
  >
    <template #header>
      <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <Avatar
            :label="ownerAvatar"
            class="!h-6 !w-6"
            shape="circle"
            style="
              background-color: #ece9fc;
              color: #2a1261;

              font-size: 14px;
            "
          />

          <div class="text-secondary-975 font-simplerBold text-md">
            {{ getPostOwnerName(postToEdit.author.nickname) }}
          </div>
          <div class="text-secondary-800 text-xs">
            {{ timeAgo(postToEdit.created_at) }}
          </div>
        </div>
      </div>
    </template>
    <div>
      <div class="flex flex-col gap-3 w-full min-h-60 editor-content">
        <TiptapContent v-if="editor" :editor="editor" />
        <div
          class="border-b p-2 border-cyan-gentle-100 flex w-full justify-between items-center"
        >
          <div class="text-secondary-800 text-xs">
            {{ postToEdit.comments.length }} תגובות
          </div>
          <div class="flex items-center gap-2 text-secondary-800 text-xs">
            <span class="flex items-center text-secondary-800">
              <span v-if="postToEdit.reactions?.length">
                {{ reactionsUsers.length }}
              </span>
              <button class="text-white rounded-full" @click.stop="handleLike">
                <div class="flex justify-center items-center">
                  <div
                    class="flex justify-center items-center h-6 w-6 rounded-full"
                  >
                    <BlueLike v-if="userLiked" />
                    <GreyLike v-else />
                  </div>
                </div>
              </button>
            </span>
          </div>
        </div>
        <div class="flex flex-col gap-3">
          <div v-for="comment of sortedComments" class="flex gap-2 w-full">
            <Avatar
              :label="getPostAvatarName(comment?.sender?.nickname)"
              class="!h-6 !w-6"
              shape="circle"
              style="background-color: #ece9fc; color: #2a1261; font-size: 14px"
            />
            <div
              v-if="!editCommentId || editCommentId !== comment.messageId"
              class="bg-[#F2F6F9] p-3 rounded-l-lg rounded-br-lg w-90/100 w-full"
            >
              <div class="flex justify-start items-center gap-3">
                <span class="font-simplerBold">
                  {{ comment?.sender?.nickname }}
                </span>
                <span class="text-secondary-800 text-xs">
                  {{
                    timeAgo(comment.createdAt ? comment.createdAt : new Date())
                  }}
                </span>
              </div>
              <div class="flex justify-between items-center">
                <span>
                  {{ comment.message }}
                </span>
                <span class="">
                  <BaseDropdown
                    v-if="
                      !comment?.sender?.userId ||
                      comment?.sender?.userId === currentUser.id ||
                      isOperator
                    "
                    placement="bottom"
                    align="left"
                  >
                    <template #trigger>
                      <Ellipsis
                        class="w-7 h-6 active:bg-secondary-250 hover:bg-gray-200 rounded-xl"
                        @click.stop
                      />
                    </template>
                    <div
                      class="flex flex-col rounded-full p-1 cursor-pointer divide-y divide-solid"
                    >
                      <Pencil
                        class="h-6 stroke-[1.2px] p-1 hover:bg-gray-200 rounded-md"
                        @click="handleEditComment(comment.messageId)"
                      />
                      <Trash2
                        class="h-6 stroke-[1.2px] p-1 hover:bg-gray-200"
                        @click="handleDeleteComment(comment.messageId)"
                      />
                    </div>
                  </BaseDropdown>
                </span>
              </div>
            </div>

            <BaseInput
              v-else
              ref="editCommentInput"
              v-model="localEditComment"
              class="grow border-transparent placeholder-slate-500 rounded-lg"
              placeholder="התגובה שלי"
              variant="post"
              @blur="onEditCommentBlur"
              @keydown.enter.stop="handleEnterEditComment"
            >
              <template #suffix>
                <!-- <ArrowLeft class=" h-4" @click="publishNewComment" /> -->
                <button
                  :disabled="publishEditCommentDisable"
                  @mousedown="publishEditComment"
                >
                  <div
                    class="h-7 w-7 rounded-full flex items-center justify-center"
                    :class="{
                      'bg-secondary-500': !publishEditCommentDisable,
                      'bg-secondary-250': publishEditCommentDisable,
                    }"
                  >
                    <ArrowLeft class="stroke-white h-4" />
                  </div>
                </button>
              </template>
            </BaseInput>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Avatar
        :label="currentUserAvatar"
        class="!h-6 !w-6"
        shape="circle"
        style="background-color: #ece9fc; color: #2a1261; font-size: 14px"
      />

      <BaseInput
        v-model="localComment"
        class="grow border-transparent placeholder-slate-500 rounded-lg edit-comment-input"
        placeholder="התגובה שלי"
        variant="post"
        @keydown.enter.stop="handleEnterComment"
      >
        <template #suffix>
          <button
            class="edit-comment-button"
            :disabled="publishCommentDisable"
            @click="publishNewComment"
          >
            <div
              class="h-7 w-7 rounded-full flex items-center justify-center"
              :class="{
                'bg-secondary-500': !publishCommentDisable,
                'bg-secondary-250': publishCommentDisable,
              }"
            >
              <ArrowLeft class="stroke-white h-4" />
            </div>
          </button>
        </template>
      </BaseInput>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import Button from 'primevue/button';
import PVDialog from 'primevue/dialog';
import {
  containsRealText,
  getPostAvatarName,
  getPostOwnerName,
  timeAgo,
} from '../composables/utils';
import { computed, nextTick, onMounted, PropType, ref, watch } from 'vue';
import BaseInput from '@/components/Input/BaseInput.vue';
import { ArrowLeft, ThumbsUp, Ellipsis, Trash2, Pencil } from 'lucide-vue-next';
import { BaseDropdown } from '@/components';
import { Post, PostData } from '../types/post';
import BlueLike from '@/assets/blue-like.svg?component';
import GreyLike from '@/assets/grey-like.svg?component';
import Avatar from 'primevue/avatar';
import { useEditor } from '@amit/tiptap';
import TiptapContent from '@amit/tiptap/src/TiptapContent.vue';
const { getEditor, createEditor } = useEditor();
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  postToEdit: {
    type: Object as PropType<Post>,
    default: null,
  },
  currentUser: {
    type: Object as PropType<{ id: string; name: string }>,
    default: null,
  },
  isOperator: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'close',
  'comment',
  'delete-comment',
  'edit-comment',
  'add-like',
]);
const isOpen = useVModel(props, 'open', emit, { eventName: 'close' });
const localComment = ref('');
const localEditComment = ref('');
const ownerAvatar = ref('');
const currentUserAvatar = ref('');

const reactionsUsers = computed(
  () => props.postToEdit?.reactions[0].userIds, //.map(reaction => reaction.userIds),
);

onMounted(() => {
  ownerAvatar.value = getPostAvatarName(props.postToEdit?.author?.nickname);
  currentUserAvatar.value = getPostAvatarName(props.currentUser?.name);
});

const alertDeleteVisable = ref(false);
const editCommentInput = ref<HTMLInputElement | null>();
const userLiked = computed(() =>
  props.postToEdit?.reactions.find(reaction =>
    reaction.userIds.includes(props.currentUser.id),
  ),
);

const sortedComments = computed(() => {
  return props.postToEdit.comments.sort((a: any, b: any) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
});

const handleDeleteComment = (commentId: number) => {
  const postData: PostData = {
    message_id: commentId,
    parentId: props.postToEdit.message_id,
  };
  emit('delete-comment', postData);
};

const onEditCommentBlur = () => {
  editCommentId.value = null;
  localEditComment.value = '';
};

const editCommentId = ref<number | null>(null);
const handleEditComment = (commentId: number) => {
  editCommentId.value = commentId;
  localEditComment.value =
    props.postToEdit.comments.find(
      (comment: any) => comment.messageId === commentId,
    )?.message || '';
};

const publishCommentDisable = computed(() => {
  return !containsRealText(localComment.value);
});

const handleEnterComment = () => {
  if (publishCommentDisable.value) {
    return;
  }
  publishNewComment();
};

const publishEditCommentDisable = computed(() => {
  return (
    !containsRealText(localEditComment.value) ||
    props.postToEdit.comments.find(x => x.messageId === editCommentId.value)
      ?.message === localEditComment.value
  );
});

const handleEnterEditComment = () => {
  if (publishEditCommentDisable.value) {
    return;
  }
  publishEditComment();
  editCommentId.value = null;
};

const publishNewComment = () => {
  const postData = {
    text: localComment.value,
  };
  localComment.value = '';
  emit('comment', { post: postData, parentId: props.postToEdit.message_id });
  editCommentId.value = null;
};

const publishEditComment = () => {
  const postData: PostData = {
    text: localEditComment.value,
    title: localEditComment.value,
    message_id: editCommentId.value || 0,
    parentId: props.postToEdit.message_id,
  };
  localEditComment.value = '';

  emit('edit-comment', postData);
};
const handleLike = () => {
  userLiked.value
    ? emit('add-like', props.postToEdit.message_id, true)
    : emit('add-like', props.postToEdit.message_id, false);
};

const id = ref(`show-${props.postToEdit.post_id}`);
createEditor({
  id: id.value,
  editable: false,
  model: props.postToEdit.title,
});

const editor = getEditor(id.value);
</script>

<style scoped>
.border-footer {
  margin-left: -20px;
  margin-right: -20px;
}

.bubble-menu {
  display: flex;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 99999;
}

:deep(.editor-content-temp) {
  background-color: #fdf4d8;
}
</style>

<style>
.modal-post-text-content p {
  font-size: 16px !important;
  margin-bottom: 2px;
}
.post-editor-content {
  background-color: #fdf4d8;
}
</style>
