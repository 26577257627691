<script lang="ts" setup>
import type { IPostElement } from '@/types/interfaces';
import BaseImage from '@/components/BaseImage/BaseImage.vue';
import { computed } from 'vue';
interface IBoxItem {
  disabled: boolean;
  error: boolean;
  selected: boolean;
  success: boolean;
  item: Partial<IPostElement>;
}
const props = withDefaults(defineProps<IBoxItem>(), {
  disabled: false,
  error: false,
  selected: false,
  success: false,
});

const emit = defineEmits(['update:selected']);

const isOnlyImage = computed(
  () => !props.item.title && !props.item.description,
);
const handleClick = () => {
  if (!props.disabled) emit('update:selected');
};
</script>

<template>
  <div
    class="rounded-3xl cursor-pointer text-center aspect-square h-50 border"
    :class="{
      'border-primary bg-primary-200': selected,
      'border-secondary-300 bg-secondary-75': !selected,
    }"
    @click="handleClick">
    <div class="flex h-full flex-col justify-center items-center">
      <div v-if="item.img" class="flex-1 flex justify-center items-end">
        <BaseImage
          :src="item.img"
          :width="isOnlyImage ? '100%' : '60px'"
          :height="isOnlyImage ? '100%' : '60px'"
          :density="isOnlyImage ? 'comfortable' : 'compact'"
          fit="fill"
          placement="center"
          ratio="square"/>
      </div>
      <div
        v-if="item.title"
        class="w-full flex flex-col items-center justify-center p-4 flex-1">
        <div class="text-secondary-900 text-lg font-simplerBold p-2">
          {{ item.title }}
        </div>
        <p
          v-if="item.description"
          class="text-secondary-900 text-body-md font-simplerRegular mt-4">
          {{ item.description }}
        </p>
      </div>
    </div>
  </div>
</template>
