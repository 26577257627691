<template>
  <BaseInput v-model="model" :label="label" @click.prevent="openWidget">
    <template #suffix>
      <div class="flex gap-1 items-center">
        <XCircle
          class="w-4"
          :class="[
            model
              ? 'pointer-events-auto cursor-pointer stroke-red-500'
              : 'stroke-secondary-400',
          ]"
          @mousedown.prevent="model = ''"/>
        <UploadIcon class="stroke-primary w-4" />
      </div>
    </template>
  </BaseInput>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import BaseInput from '@/components/Input/BaseInput.vue';
import {
  defaultSources,
  reactiveUploadResult,
  updateAndOpenInstance,
} from '@amit/composables';
import { Upload as UploadIcon, XCircle } from 'lucide-vue-next';
import type { PropType } from 'vue';
//   import { imageFormats, videoFormats } from '' //'/@/  utils';
import { useVModel } from '@vueuse/core';

const imageFormats = ['image/jpeg', 'image/png', 'image/gif'];
const videoFormats = [
  // 'video/x-msvideo', // .avi
  'video/quicktime', // .mov
  // 'video/x-ms-wmv', // .wmv
  // 'video/x-flv', // .flv
  // 'video/mpeg', // .mpg
  // 'video/webm', // .webm
  'video/mp4',
];

type mediaType = 'image' | 'video';
const formats: Record<mediaType, Array<string>> = {
  image: imageFormats,
  video: videoFormats,
};
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  blockId: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<mediaType>,
    default: 'image',
  },
});
const emit = defineEmits(['upload']);

const model = useVModel(props, 'modelValue', emit);

const allowedFormats = computed(() => {
  return formats[props.type];
});

const openWidget = () => {
  const sources =
    props.type === 'image'
      ? defaultSources
      : defaultSources.filter(source => source !== 'unsplash');
  updateAndOpenInstance(
    {
      clientAllowedFormats: allowedFormats.value,
      resourceType: props.type,
      sources,
    },
    props.blockId,
  );
};

watch(
  () => reactiveUploadResult.value,
  () => {
    if (
      reactiveUploadResult.value.block === props.blockId &&
      reactiveUploadResult.value.type === 'success'
    ) {
      emit('upload', reactiveUploadResult.value.result);
    }
  },
  { deep: true },
);
</script>
