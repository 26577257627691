<template>
  <FormControl :title="title">
    <div class="inline-block">
      <div class="flex items-center w-3/4">
        <div
          v-for="header in headers"
          :key="header.key"
          :class="['ml-2 mt-5', header.type === 'select' ? 'w-1/2' : 'w-full']">
          {{ header.title }}
        </div>
      </div>
      <div class="inline-block space-y-2 mt-2 mb-6 w-full">
        <div
          v-for="(row, index) in model"
          :key="row.id"
          class="flex items-center w-3/4">
          <div
            v-for="header in headers"
            :key="header.key"
            :class="[
              'mt-1 ml-2',
              header.type === 'select' ? 'w-1/2' : 'w-full',
            ]"
            :style="{ height: maxHeight + 'px' }">
            <div
              v-for="({}, contentIndex) in row[header.key]?.content"
              :key="contentIndex"
              class="w-full h-full group flex items-center ml-2 overflow-hidden focus-within:border-primary">
              <BaseDropdown
                v-if="header.type === 'select'"
                v-model="model[index][header.key].content[contentIndex]"
                :label="header.selectLabel"
                :options="header.options"
                :selected-options="selectedOption[header.key]"/>
              <BaseTextArea
                v-else-if="header.type === 'textarea' || header.editable"
                v-model="model[index][header.key].content[contentIndex]"
                :min="formatting.textarea.min"
                :max="formatting.textarea.max"
                :max-height="maxHeight"
                :placeholder="header.placeHolder"
                :type="header.type"
                @update-height="updateHeight"/>
              <div
                v-else
                class="rounded-4 h-full text-sm border-1 w-full bg-[#F2F4F8] p-3 border-[#d8dfe9]">
                {{ row[header.key].content[contentIndex].text }}
              </div>
            </div>
          </div>
          <DeleteIcon
            v-if="model.length > formatting.minRows"
            class="stroke-1 cursor-pointer w-10"
            @click="deleteRow(index)"/>
        </div>
        <div v-if="isNewRow" class="group flex ml-2 overflow-hidden w-3/4">
          <div
            v-for="header in disabledHeader"
            :key="header.key"
            class="w-full">
            <div
              v-for="({}, contentIndex) in newRow[header.key].content"
              :key="contentIndex">
              <div
                class="group flex items-center ml-2 overflow-hidden focus-within:border-primary">
                <BaseTextArea
                  v-model="newRow[header.key].content[contentIndex]"
                  :min="formatting.textarea.min"
                  :max="formatting.textarea.max"
                  :max-height="maxHeight"
                  :placeholder="header.placeHolder"
                  @update-height="updateHeight"/>
              </div>
            </div>
          </div>
          <div class="flex justify-end items-center w-full">
            <BaseButton
              color="none"
              class="text-secondary-500"
              @click="cancelRow()">
              ביטול
            </BaseButton>
            <BaseButton :disabled="isRowFilled" @click="addRow()">
              <CornerDownLeft height="15px" width="15px" />
            </BaseButton>
          </div>
        </div>
        <div
          v-if="!isNewRow && model?.length < formatting.maxRows"
          class="flex items-center px-5 bg-[#F3FBFF] cursor-pointer pl-2 rounded-lg h-8 w-3/4"
          @click.stop="isNewRow = true">
          + הוספת שאלה
        </div>
      </div>
    </div>
  </FormControl>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import BaseButton from '@/components/Button/BaseButton.vue';
import BaseDropdown from '@/components/Table/BaseDropdown.vue';
import BaseTextArea from '@/components/Table/BaseTextArea.vue';
import { generateObjectId } from '@/utils';
import { Trash2 as DeleteIcon, CornerDownLeft } from 'lucide-vue-next';

interface Row {
  id: string;
  [key: string]: any;
}

type Option = {
  text: string;
  value: number;
  icon: string;
};

interface Header {
  title: string;
  key: string;
  type: string;
  selectLabel: string;
  placeHolder: string;
  editable: boolean;
  options: Option[];
}

interface Content {
  id: string;
  [key: string]: any;
}

const props = defineProps({
  modelValue: {
    type: Array as () => Array<Row>,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  headers: {
    type: Array as () => Array<Header>,
    default: () => [],
  },
  items: {
    type: Array as () => Array<Content>,
    default: () => [],
  },
  formatting: {
    type: Object,
    default: () => ({
      minRows: 1,
      maxRows: 15,
      textarea: {
        min: 1,
        max: 2,
      },
    }),
  },
});

const selectedOption = computed(() => {
  const selectableHeaders = props.headers.filter(
    header => header.type === 'select',
  );
  const result = {};
  selectableHeaders.forEach(selectableHeader => {
    const usedOption = model.value.flatMap(row =>
      row[selectableHeader.key].content.map(contentItem => contentItem.value),
    );
    result[selectableHeader.key] = usedOption;
  });
  return result;
});

const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);

const generateNewRow = () =>
  props.headers.reduce(
    (acc: any, header: Header) => {
      acc[header.key] = {
        id: generateObjectId(),
        content: [{ id: generateObjectId(), text: '' }],
      };
      return acc;
    },
    { id: generateObjectId() },
  );

const newRow = ref(generateNewRow());

const disabledHeader = computed(() =>
  props.headers.length > 1
    ? props.headers.filter(header => header.type === 'text')
    : props.headers,
);

const isRowFilled = computed(
  () =>
    !disabledHeader.value.every(header =>
      newRow.value[header.key].content.every(
        contentItem => contentItem.text !== '',
      ),
    ),
);

const isNewRow = ref(false);
const addRow = () => {
  isNewRow.value = false;
  model.value.push(newRow.value);
  newRow.value = generateNewRow();
};

const cancelRow = () => {
  isNewRow.value = false;
  newRow.value = generateNewRow();
};

const deleteRow = (rowIndex: any) => {
  if (!(model.value.length > 1 || rowIndex !== model.value.length - 1)) return;
  model.value.splice(rowIndex, 1);
};

watch(
  () => props.modelValue,
  () => {
    if (model.value?.length) {
      return;
    }
    props.items.forEach((item: any) => {
      model.value.push(item);
    });
  },
  { immediate: true },
);

const maxHeight = ref('auto') as any;

const cellsHeight = ref({});

const updateHeight = height => {
  cellsHeight.value = { ...cellsHeight.value, ...height };
  const heightValues = Object.values(cellsHeight.value) as any;
  const heightMax = Math.max(...heightValues);
  maxHeight.value = heightMax;
};
</script>
