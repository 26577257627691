import { ref } from 'vue';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}

export function useYoutubeApi() {
  const youtubeReady = ref(false);
  const id = 'youtube-iframe-js-api-script';

  const waitForYouTubeAPI = callback => {
    if (
      typeof window.YT === 'undefined' ||
      typeof window.YT.Player === 'undefined'
    ) {
      setTimeout(() => waitForYouTubeAPI(callback), 100); // Check again after 100ms
    } else {
      callback();
    }
  };

  let tag = document.getElementById(id) as HTMLScriptElement;
  if (!tag) {
    // If the script tag does not exist, create it and set up the callback
    window.onYouTubeIframeAPIReady = () => {
      youtubeReady.value = true;
    };

    tag = document.createElement('script');
    tag.id = id;
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag && firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  } else {
    // If the script tag exists, wait for the API to be ready
    waitForYouTubeAPI(() => {
      youtubeReady.value = true;
    });
  }

  return { youtubeReady };
}
