<template>
  <FormControl :title="title" :sub-title="subTitle">
    <BankButton
      v-if="showBankButton"
      :text="getLocalizedText('word_bank')"
      :show-bank="showBank"
      @toggle="showBank = !showBank"/>
    <div ref="bankTextElement" class="grid gap-2">
      <div
        v-for="(line, lineIndex) in transformedArray"
        :key="lineIndex"
        class="flex flex-wrap gap-2 items-center">
        <div v-for="(word, wordIndex) in line" :key="wordIndex" class="flex">
          <div v-if="isBlank(word)">
            <VueDraggable
              v-if="format === 'dnd'"
              v-model="model[word.id]"
              :group="getDragGroup(word)"
              item-key="id"
              :sort="false"
              class="bg hover-bg h-[28px] w-[153px] xl:h-[38px] xl:w-[196px] 2xl:h-[44px] rounded-lg flex items-center border-dashed bg-no-repeat bg-center hover:border-primary border-1"
              :class="[
                draggableBlankKey === word.id
                  ? 'border-primary'
                  : 'border-secondary-900',
              ]"
              @start="onDragStart(word.id)"
              @end="onDragEnd">
              <template #item="{ index }">
                <div
                  class="h-full flex items-center bg-primary-200 border border-primary rounded-lg px-2 gap-1"
                  :class="{ 'w-full': draggableBlankKey !== word.id }">
                  <button @click="removeElement(index, word.id)">
                    <CloseIcon class="h-4" />
                  </button>
                  <span class="text-xs xl:text-sm 2xl:text-base">
                    {{ model[word.id][index] }}
                  </span>
                </div>
              </template>
            </VueDraggable>
            <BaseDropdown
              v-else-if="format === 'select'"
              fixed
              :text="model[word?.id][0]">
              <template #trigger>
                <BaseButton color="dark" outline class="min-w-20">
                  <div class="flex justify-between gap-2">
                    <div class="grow">{{ model[word?.id][0] }}</div>
                    <ChevronDown :size="16" />
                  </div>
                </BaseButton>
              </template>
              <ListGroup>
                <ListGroupItem
                  v-for="(option, optionIndex) in word?.options"
                  :key="optionIndex"
                  @click="model[word?.id][0] = option">
                  {{ option }}
                </ListGroupItem>
              </ListGroup>
            </BaseDropdown>
            <input
              v-else-if="format === 'input'"
              v-model="model[word.id][0]"
              type="text"
              class="rounded-lg bg-secondary-75 p-2 caret-primary focus:ring-0"/>
          </div>
          <span v-else v-html="word" />
        </div>
      </div>
    </div>
    <div
      v-if="showBankButton && showBank"
      class="bank-button sticky left-2 right-2 bottom-0 shadow-box-light">
      <BankBanner
        v-model="optionsRef"
        group-name="answers"
        @hidden="closeBank"/>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import BankBanner from '@/components/BankBanner/BankBanner.vue';
import BankButton from '@/components/BankButton/BankButton.vue';
import BaseDropdown from '@/components/Dropdown/BaseDropdown.vue';
import ListGroup from '@/components/ListGroup/ListGroup.vue';
import ListGroupItem from '@/components/ListGroup/components/ListGroupItem/ListGroupItem.vue';
import BaseButton from '@/components/Button/BaseButton.vue';
import { watch, computed, ref, watchEffect } from 'vue';
import { useElementVisibility, useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import VueDraggable from 'vuedraggable';
import type { IBlanksModel } from '@/types/interfaces';
import { X as CloseIcon, ChevronDown } from 'lucide-vue-next';
import { uniq } from 'lodash';
import { useLocale } from '@/composables/useLocale';

const closeBank = () => {
  showBank.value = false;
};
interface FillBlanksProps {
  title?: string;
  subTitle?: string;
  text: string;
  format: string;
  options?: Array<any>;
  blanks?: IBlanksModel[];
  modelValue?: Record<string, string[]>;
  locale?: string;
}

const props = withDefaults(defineProps<FillBlanksProps>(), {
  title: '',
  subTitle: '',
  options: () => [],
  blanks: () => [],
  locale: 'he',
  modelValue: () => ({}),
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
const draggableBlankKey = ref('');
const drag = ref(false);
const bankTextElement = ref();
const targetIsVisible = useElementVisibility(bankTextElement);
const showBank = ref(false);
const showBankButton = computed(() => props.format === 'dnd');
const locale = computed(() => props.locale);

const { getLocalizedText } = useLocale(locale);

watchEffect(() => {
  showBank.value = targetIsVisible.value;
});

const optionsRef = ref();
const cleanedText = computed(() => {
  let text = props.text
    .replace(/<p[^>]*>/g, '\n')
    .replace(/<\/p>/g, '')
    .replace(/<ul[^>]*>/g, '\n<ul>\n')
    .replace(/<\/ul>/g, '\n</ul>\n')
    .replace(/<ol[^>]*>/g, '\n<ol>\n')
    .replace(/<\/ol>/g, '\n</ol>\n');

  let listCounter = 1;

  text = text.replace(
    /<li[^>]*>(.*?)<\/li>/gs,
    (match, content, offset, input) => {
      const isOrdered = /<ol[^>]*>/.test(input.slice(0, offset));
      const bullet = isOrdered ? `${listCounter++}.` : '•';
      return `${bullet} ${content.replace(/\n/g, ' ')}\n`;
    },
  );

  return text.split('\n').filter(line => line.trim() !== '');
});

const splitText = computed(() => {
  return cleanedText.value.map(line =>
    line.split(/(<blank.*?>.*?<\/blank>|<blank.*?\/>)/g),
  );
});

const isBlank = word => typeof word === 'object';
const shuffleArray = array => {
  return array.sort(() => Math.random() - 0.5);
};

watchEffect(() => {
  const allOptions = shuffleArray([
    ...props.blanks.flatMap(blank => [
      ...blank.matches,
      ...(blank?.fillers ?? []),
    ]),
    ...props.options,
  ]);
  const selectedOptions = Object.values(model.value).flat();

  selectedOptions.forEach(option => {
    const index = allOptions.indexOf(option);
    if (index !== -1) {
      allOptions.splice(index, 1);
    }
  });

  optionsRef.value = allOptions;
});

const getDragGroup = word =>
  model.value[word.id]?.length < 1 || draggableBlankKey.value === word.id
    ? 'answers'
    : `answer${word.id}`;

const onDragStart = wordId => {
  drag.value = true;
  draggableBlankKey.value = wordId;
};

const onDragEnd = () => {
  drag.value = false;
  draggableBlankKey.value = '';
};

const removeElement = (index, wordId) => {
  const element = model.value[wordId].splice(index, 1)[0];
  optionsRef.value.push(element);
};

const transformedArray = computed(() => {
  return splitText.value.map((line: any) => {
    return line.map((word: any) => {
      const match = word.match(/<blank\s+id="([^"]+)"(?:>([^<]*)<\/blank>)?/);
      if (match && match[1]) {
        const id = match[1];
        const blank = props.blanks.find(blank => blank.id === id);
        return {
          id,
          options: shuffleArray(
            uniq([
              ...(blank?.matches ?? []),
              ...(blank?.fillers ?? []),
              ...props.options,
            ]),
          ),
        };
      }
      return word;
    });
  });
});

watch(
  () => props.modelValue,
  () => {
    props.blanks.forEach(blank => {
      if (!model.value[blank.id]) {
        model.value[blank.id] = [];
      }
    });
  },
  { immediate: true },
);
</script>

<style scoped>
.hover-bg:hover {
  background-image: url('@/assets/plusBgPrimary.svg');
}
.bg {
  background-image: url('@/assets/plusGray.svg');
}
</style>
