<template>
  <BaseDropdown placement="top" class="w-full h-full">
    <template #trigger>
      <BaseButton
        color="light"
        outline
        class="w-full h-full !border-1 !border-secondary-250 !px-1 !py-1"
        square>
        <div
          class="flex justify-between items-center w-full h-full p-2"
          :class="{ 'bg-secondary-100': model.text }">
          <div v-if="model.text" class="flex items-center">
            <component
              :is="icons[model.icon]"
              v-if="icons[model.icon]"
              class="ml-2"/>
            <div>
              {{ model.text }}
            </div>
          </div>
          <div
            v-else
            class="text-secondary-600 font-simplerRegular font-normal">
            {{ label }}
          </div>
          <ChevronDown :size="16" />
        </div>
      </BaseButton>
    </template>
    <ListGroup>
      <div
        class="flex justify-center w-full p-2 cursor-pointer"
        @click="model = { text: '', value: 0 }">
        נקה בחירה
      </div>
      <ListGroupItem
        v-for="(option, optionIndex) in availableOptions"
        :key="optionIndex"
        :class="{ 'bg-[#f3f4f6]': model === option }"
        @click="model = option">
        <component
          :is="icons[option.icon]"
          v-if="icons[option.icon]"
          class="ml-2"/>
        {{ option.text }}
      </ListGroupItem>
    </ListGroup>
  </BaseDropdown>
</template>
<script lang="ts" setup>
import BaseDropdown from '@/components/Dropdown/BaseDropdown.vue';
import ListGroup from '@/components/ListGroup/ListGroup.vue';
import ListGroupItem from '@/components/ListGroup/components/ListGroupItem/ListGroupItem.vue';
import BaseButton from '@/components/Button/BaseButton.vue';
import { useVModel } from '@vueuse/core';
import { ChevronDown } from 'lucide-vue-next';
import StarIcon from '@/assets/star.svg?component';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Array as () => Array<any>,
    required: true,
  },
  label: {
    type: String,
    default: 'בחירה',
  },
  options: {
    type: Array as () => Array<any>,
    required: true,
  },
  selectedOptions: {
    type: Array as () => Array<any>,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);

const icons = {
  star: StarIcon,
};

const availableOptions = computed(() => {
  return props.options.filter(
    option =>
      !props.selectedOptions.includes(option.value) || model.value === option,
  );
});
</script>
