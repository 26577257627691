import { useInteractions, useReveal } from '@/apps/slideshow/useReveal';

import {
  ChoiceGroup,
  CustomVideo,
  PersonasSelection,
  UploadFile,
  TasksTable,
  PlanControl,
  SmartTable,
  PlanCheck,
  ColorsLegendBank,
  MultiTextarea,
  TimeEstimation,
  CalendarEvents,
  DraggableMatch,
  DraggableCalendar,
  DraggableGantt,
  DraggableTable,
  BaseInput,
  ContentTable,
  SummaryText,
  BaseImage,
  DoughnutChart,
  BaseAlert,
  BaseIframe,
  CustomLink,
  FillBlanks,
  AudioPlayer,
  PairingMatch,
  AudioListener,
  HighlightText,
  WordCloud,
  DynamicTable,
  AdvancedTable,
  EvaluationTable,
  WeightingTable,
} from '@/components';
import { TiptapText } from '@amit/tiptap';
import { PollBlock } from '@amit/blocks/';
import { SortBlock } from '@amit/blocks/';
import { ref, watch } from 'vue';
import { startCase } from 'lodash';
import LayoutBlock from '../LayoutBlock.vue';
import DecisionMakingGame from '@/apps/slideshowGame/DecisionMakingButton.vue';
import { CollaborativeBoardBlock } from '@amit/blocks';
import NonEditableText from '@amit/tiptap/src/NonEditableText.vue';

export const resolveComponent = (name, blocksMap) => {
  const nameToComponent = {
    two_columns: LayoutBlock,
    custom_video: CustomVideo,
    choice: ChoiceGroup,
    rich_text: NonEditableText,
    disclaimer: BaseAlert,
    textarea: MultiTextarea,
    color_legend: ColorsLegendBank,
    persona_selection: PersonasSelection,
    fill_blanks: FillBlanks,
    smart_goal: SmartTable,
    project_plan: TasksTable,
    plan_control: PlanControl,
    match: DraggableMatch,
    free_text: TiptapText,
    table: ContentTable,
    calendar_drag: DraggableCalendar,
    calendar: CalendarEvents,
    heading: SummaryText,
    file_upload: UploadFile,
    plan_check: PlanCheck,
    priority_quadrant: DraggableTable,
    gantt: DraggableGantt,
    draggable_table: DraggableTable,
    image: BaseImage,
    embed: BaseIframe,
    time_estimation: TimeEstimation,
    chart: DoughnutChart,
    input: BaseInput,
    link: CustomLink,
    audio_player: AudioPlayer,
    pairing: PairingMatch,
    audio_listener: AudioListener,
    highlight: HighlightText,
    decision_making_game: DecisionMakingGame,
    word_cloud: WordCloud,
    dynamic_table: DynamicTable,
    advanced_table: AdvancedTable,
    evaluation_table: EvaluationTable,
    weighting_table: WeightingTable,
    poll: PollBlock,
    collaborative_board: CollaborativeBoardBlock,
    sort: SortBlock,
  };

  const componentsMap = {
    ...nameToComponent,
    ...blocksMap,
  };

  return componentsMap[name];
};

export const getAttributes = ({
  block,
  props,
  // editable,
  locale,
  display,
  state,
  emits,
}) => {
  // FIXME: we should not pass anything not defined on component props
  const attributes: Record<string, any> = { ...block.content };

  const { onAction, onEmit } = useInteractions();
  const { context, rtl } = useReveal();
  const modelValue = ref(state);
  const model = {
    modelValue: modelValue.value,
    'onUpdate:modelValue': value => {
      modelValue.value = value;
    },
  };
  watch(
    () => modelValue.value,
    async value => {
      await onAction.trigger({
        context: context.value,
        event: { block, value },
      });
    },
    { deep: true },
  );

  if (props.modelValue) {
    Object.assign(attributes, model);
  }

  if (props.id) {
    Object.assign(attributes, { id: block.id });
  }

  if (block.name === 'rich_text') {
    delete attributes.blocks;
    delete attributes.text;
    Object.assign(attributes, { editable: false });
  }

  if (block.name === 'sort') {
    Object.assign(attributes, { locale });
  }

  if (block.name === 'embed') {
    Object.assign(attributes, { display });
  }

  if (props.rtl) {
    Object.assign(attributes, { rtl: rtl.value });
  }

  if (props.locale) {
    Object.assign(attributes, { locale: locale });
  }

  if (emits?.length) {
    emits.forEach(emit => {
      if (emit !== 'update:modelValue') {
        const emitName = `on${startCase(emit).replace(/ /g, '')}`;
        Object.assign(attributes, {
          [emitName]: (event: any = {}) => {
            event.name = `${block.name}:${emit}`;
            event.source = block.id;
            onEmit.trigger({ event, context: context.value });
          },
        });
      }
    });
  }
  return attributes;
};
