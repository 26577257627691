<template>
  <div class="col-span-12">
    <div class="text-lg font-simplerRegular whitespace-pre-line">
      {{ title }}
    </div>
    <BaseTable>
      <TableHead>
        <TableHeadCell
          v-for="header in headers"
          :key="header.key"
          :style="headerWidth(header.key)">
          <div
            v-if="header.title"
            class="flex"
            :class="[
              headerClasses,
              { 'selected-header': header.key === selectedHeader },
            ]">
            {{ header.title }}:
          </div>
          <slot name="subheader" v-bind="{ header }"></slot>
        </TableHeadCell>
      </TableHead>
      <TableBody>
        <TableRow v-for="(item, rowIndex) in items" :key="rowIndex">
          <TableCell
            v-for="header in headers"
            :key="header.key"
            class="align-top"
            :style="{ width: columnWidth }">
            <div
              v-if="item[header.key]"
              class="space-y-2"
              :class="cellClasses(header.key)">
              <div
                v-if="item[header.key]?.title"
                class="flex justify-center w-full font-simplerBold">
                {{ item[header.key]?.title }}
              </div>
              <div
                v-for="(content, indexCell) in item[header.key]?.content"
                :key="indexCell"
                class="space-y-2">
                <slot name="cell" v-bind="{ content, rowIndex, header }">
                  <div
                    v-if="content.text.length > 1"
                    class="bg-primary-200 border border-primary w-full rounded-lg text-xs p-2">
                    {{ content.text }}
                  </div>
                </slot>
              </div>
            </div>
          </TableCell>
        </TableRow>
        <TableRow v-if="$slots.totalrow">
          <TableCell
            v-for="header in headers"
            :key="header.key"
            class="align-top"
            :style="{ width: columnWidth }">
            <slot name="totalrow" v-bind="{ header }"></slot>
          </TableCell>
        </TableRow>
      </TableBody>
    </BaseTable>
  </div>
</template>
<script setup lang="ts">
import { PropType, computed, toRefs } from 'vue';
import BaseTable from '@/components/Table/BaseTable.vue';
import TableHead from '@/components/Table/TableHead.vue';
import TableBody from '@/components/Table/TableBody.vue';
import TableCell from '@/components/Table/TableCell.vue';
import TableHeadCell from '@/components/Table/TableHeadCell.vue';
import TableRow from '@/components/Table/TableRow.vue';
import { useContentClasses } from '@/components/ContentTable/composables/useContentClasses';
import type { ContentTheme } from '@/components/ContentTable/types';

const props = defineProps({
  title: {
    type: String as any,
    default: '',
  },
  headers: {
    type: Array as any,
    default: () => [],
  },
  items: {
    type: Array as any,
    default: () => [],
  },
  selectedHeader: {
    type: String,
    default: '',
  },
  display: {
    type: String,
    default: 'flex',
  },
  theme: {
    type: String as PropType<ContentTheme>,
    default: 'default',
  },
});

const { headerClasses, cellClasses, headerWidth } = useContentClasses(
  toRefs(props),
);

const columnWidth = computed(() => {
  const colsNum = props.headers.length;
  return 100 / colsNum + '%';
});
</script>
