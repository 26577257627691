<template>
  <FormControl :title="title" :sub-title="subTitle">
    <div class="flex gap-10" :class="containerClasses">
      <div class="flex flex-col gap-5">
        <PairingMatchCard
          v-for="(option, index) in options"
          :id="option.id as string"
          :key="index"
          :active="active(option.id)"
          :title="option.title"
          :image="option.image"
          :label="option.label"
          @click="() => setActiveOption(option.id)"/>
      </div>
      <div class="flex flex-col gap-5">
        <PairingMatchCard
          v-for="(match, index) in shuffledMatches"
          :id="match.id as string"
          :key="index"
          :title="match.title"
          :image="match.image"
          :label="getMatchLabel(match.id)"
          :active="active(match.id)"
          @click="() => handleMatchOptionClick(match.id)"/>
      </div>
    </div>
  </FormControl>
</template>
<script setup lang="ts">
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { ref, computed } from 'vue';
import { useVModel } from '@vueuse/core';
import { shuffle } from 'lodash';
import PairingMatchCard from '@/components/PairingMatchCard/PairingMatchCard.vue';
import classNames from 'classnames';

interface PairMatchOption {
  id: string;
  image?: string;
  title?: string;
  subTitle?: string;
  label?: string;
  matches?: string[];
}

interface IProps {
  options: PairMatchOption[];
  matches: PairMatchOption[];
  title?: string;
  subTitle?: string;
  placement?: string;
  modelValue: Record<string, string[]>;
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => ({}),
  title: '',
  subTitle: '',
  placement: 'start',
});

defineEmits(['update:modelValue']);
const model: any = useVModel(props, 'modelValue');

const activeOption = ref();
const shuffledMatches = computed(() => shuffle(props.matches));

const setActiveOption = (id: any) => {
  if (model.value[id]) {
    removeActiveOption(id);
  } else if (activeOption.value === id) {
    activeOption.value = null;
  } else {
    activeOption.value = id;
  }
};
const removeActiveOption = id => {
  delete model.value[id];
  if (activeOption.value === id) {
    activeOption.value = null;
  }
};

const handleMatchOptionClick = (id: any) => {
  //@ts-ignore
  for (const [key, [value]] of Object.entries(model.value)) {
    if (value === id) {
      delete model.value[key];
      activeOption.value = key;
      return;
    }
  }

  if (activeOption.value) {
    model.value[activeOption.value] = [id];
  }
};

const getMatchLabel = id => {
  //@ts-ignore
  for (const [key, value] of Object.entries(model.value)) {
    //@ts-ignore
    if (value.includes(id)) {
      return props.options.find(option => option.id === key)?.label || '';
    }
  }
  return '';
};

const active = id =>
  Object.keys(model.value)?.some(key => {
    const [value] = model.value[key];
    return value === id || key === id;
  }) || activeOption.value === id;

type PlacementType = 'center' | 'start' | 'end';

const placementClasses: Record<PlacementType, string> = {
  center: 'justify-center',
  start: 'justify-start',
  end: 'justify-end',
};
const containerClasses = computed(() =>
  classNames(placementClasses[props.placement]),
);
</script>
