<template>
  <div class="flex flex-col gap-5">
    <div>יעד {{ currentTabIndex + 1 }} מתוך {{ models.length }}</div>
    <BaseTabs v-model="activeTab" color="blue" variant="progress">
      <template #pane="{ index, onClick }">
        <div
          class="grow h-2 rounded-full cursor-pointer progress-tab"
          :class="[
            index <= currentTabIndex ? 'bg-primary' : 'bg-secondary-300',
          ]"
          @click="onClick"></div>
      </template>
      <BaseTab v-for="(model, index) in models" :key="index" :name="model.id">
        <div v-if="model.status !== 'overdue'" class="flex flex-col gap-5 pt-4">
          <FormControl
            title="בחנו את תוכנית הפעולה שבניתם, עברו על היעדים וענו על השאלות:">
            <TaskLine v-model="models[index]" :editable="false" />
          </FormControl>
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-5">
              <div>האם עמדתם ביעד?</div>
              <div class="flex gap-5.5">
                <div
                  class="control-choice"
                  :class="{
                    'control-choice-selected': model.status === 'done',
                  }"
                  @click="setStatus('done')">
                  <img :src="StarsFace" alt="stars_face" />
                  <span>כן</span>
                </div>
                <div
                  class="control-choice"
                  :class="{ 'control-choice-selected': showControlQuestion }"
                  @click="handleControlQuestion">
                  <img :src="SadFace" alt="stars_face" />
                  <span>לא</span>
                </div>
              </div>
            </div>
            <div
              v-if="showControlQuestion"
              ref="controlQuestion"
              class="flex flex-col gap-5">
              <div>האם תצליחו להשיג את היעד במסגרת הזמן שהגדרתם?</div>
              <div class="flex gap-5.5">
                <div
                  class="control-choice"
                  :class="{
                    'control-choice-selected': model.status === 'on_track',
                  }"
                  @click="setStatus('on_track')">
                  <img :src="StarsFace" alt="stars_face" />
                  <span>כן</span>
                </div>
                <div class="control-choice" @click="setStatus('overdue')">
                  <img :src="SadFace" alt="stars_face" />
                  <span>לא</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Transition v-else name="fade" appear>
          <div class="flex flex-col gap-5 pt-4">
            <ChoiceGroup
              v-model="model.reason"
              title="מה הסיבה שלא תצליחו לעמוד ביעד בזמן?"
              type="radio"
              :options="reasons"/>
            <FormControl title="שנו את תוכנית הפעולה לפי המסקנות">
              <TaskLine v-model="models[index]" />
            </FormControl>
            <div
              v-if="showJumpToNextTab"
              class="self-end flex items-center gap-3 cursor-pointer"
              @click="nextTab">
              <span class="font-simplerBold text-primary">
                סיימתי אפשר לעבור ליעד הבא
              </span>
              <button
                class="px-2 py-1 rounded-lg text-white bg-primary items-center justify-center">
                <ChevronLeft class="stroke-white w-4" />
              </button>
            </div>
          </div>
        </Transition>
      </BaseTab>
    </BaseTabs>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, watch, computed, nextTick } from 'vue';
import type { PropType } from 'vue';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { TaskLine, BaseTabs, BaseTab, ChoiceGroup } from '@/components/index';
import type {
  ITaskModel,
  IPostElement,
  IPlanControlModel,
} from '@/types/interfaces';
import { each } from 'lodash';
import { ChevronLeft } from 'lucide-vue-next';
import StarsFace from '@/assets/starsFace.svg?url';
import SadFace from '@/assets/sadFace.svg?url';

const props = defineProps({
  options: {
    type: Array as PropType<ITaskModel[]>,
    default: () => [],
  },
  modelValue: {
    type: Array as PropType<IPlanControlModel[]>,
    default: () => [],
  },
  reasons: {
    type: Array as PropType<IPostElement[]>,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);
const models = useVModel(props, 'modelValue', emit, {
  passive: true,
  deep: true,
});

const currentTabIndex = ref(0);
const controlQuestion: any = ref();
const activeTab = computed({
  get: () => models.value[currentTabIndex.value]?.id,
  set: value =>
    (currentTabIndex.value = models.value.findIndex(
      model => model.id === value,
    )),
});

const showControlQuestion = ref(false);
const handleControlQuestion = () => {
  models.value[currentTabIndex.value].status = '';
  showControlQuestion.value = true;
  nextTick(() => {
    controlQuestion.value?.[0]?.scrollIntoView({ behavior: 'smooth' });
  });
};
const isFilled = value => {
  return (
    (typeof value === 'string' || Array.isArray(value)) && value.length > 0
  );
};

const requiredFields = [
  'reason',
  'criteria',
  'estimation',
  'tasks',
  'resources',
];

const showJumpToNextTab = computed(() => {
  return (
    currentTabIndex.value < models.value.length - 1 &&
    requiredFields.every(field =>
      isFilled(models.value[currentTabIndex.value][field]),
    )
  );
});
const nextTab = () => {
  if (currentTabIndex.value < models.value.length - 1) {
    currentTabIndex.value = currentTabIndex.value + 1;
  }
};

watch(
  () => currentTabIndex.value,
  currentTabIndex => {
    showControlQuestion.value = ['on_track', 'overdue'].includes(
      models.value?.[currentTabIndex]?.status,
    );
  },
  {
    immediate: true,
  },
);

watchEffect(() => {
  if (!props.modelValue.length) {
    each(props.options, option => {
      let optionIndex = models.value.findIndex(model => option.id === model.id);

      const modelOption = {
        ...JSON.parse(JSON.stringify(option)),
        status: '',
        reason: [],
      };

      if (optionIndex > -1) {
        models.value[optionIndex] = modelOption;
      } else {
        models.value.push(modelOption);
      }
    });
  }
});

const setStatus = status => {
  models.value[currentTabIndex.value].status = status;
  if (['done', 'on_track'].includes(status)) {
    nextTab();
  }
};
</script>
<style scoped>
.control-choice {
  @apply w-[182px] h-[88px] border-2 border-secondary-300 rounded-xl bg-secondary-75 flex gap-1.5 justify-center items-center cursor-pointer;
}
.control-choice > span {
  @apply text-lg text-default font-simplerBold;
}
.control-choice-selected {
  @apply border-primary-700 bg-primary-200;
}
</style>
