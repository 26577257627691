<template>
  <div class="flex flex-col space-y-3 w-full">
    <ClassicChoice
      v-model="model[0]"
      :options="options"
      type="radio"
      :is-preview="isPreview"
      :disabled="false"
      @add-option="$emit('add-option')"
      @toggle-option="id => (model = [id])"
      @delete-option="index => $emit('delete-option', index)"/>
  </div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { ClassicChoice } from '@amit/components';
import type { PropType } from 'vue';

interface Option {
  title: string;
  id: string;
}

const props = defineProps({
  type: {
    type: String,
    default: 'radio',
  },
  options: {
    type: Array as PropType<Option[]>,
    default: () => [],
  },
  isPreview: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Array<String>,
    default: () => [],
  },
  addOptionEnable: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['add-option', 'delete-option']);

const model = useVModel(props, 'modelValue');
</script>
