<template>
  <div
    class="w-45 h-fit border-1 border-solid border-secondary-250 hover:border-primary-500 rounded-xl relative"
    :class="{
      '!bg-secondary-250 p-10': presentIcon,
      ' p-3 bg-white': !presentIcon,
    }"
    @mousedown="activateCard"
    @mouseup="deactivateCard">
    <div class="flex flex-col gap-2 items-center h-full w-full">
      <div
        v-if="presentIcon"
        class="w-full rounded-t-3 cursor-pointer flex flex-col items-center justify-center max-h-[50%] flex-[0.5]">
        <div
          class="flex flex-col rounded-t-3 items-center justify-center gap-2 whitespace-nowrap w-full h-full px-9 py-2">
          <slot name="icon"></slot>
        </div>
      </div>

      <div
        v-else
        class="w-full rounded-t-3 cursor-pointer flex flex-col items-center justify-center max-h-[50%] flex-[0.5] hover:bg-secondary-250"
        @click="togglePanel">
        <div
          v-if="!localOption.image"
          class="flex flex-col rounded-t-3 items-center justify-center gap-2 bg-secondary-75 whitespace-nowrap w-full h-full px-9 py-2">
          <div>
            <FolderUp
              :class="{
                'stroke-primary-650': isActive,
                'stroke-secondary-900': !isActive,
              }"/>
          </div>
          <span
            class="text-xs"
            :class="{
              'text-primary-650': isActive,
              'text-secondary-900': !isActive,
            }">
            {{ t('blocksSettings.pairing.default_image_text') }}
          </span>
          <span
            class="text-[10px] leading-4"
            dir="rtl"
            :class="{
              'text-primary-650': isActive,
              'text-secondary-500': !isActive,
            }">
            {{ t('blocksSettings.pairing.default_image_secondary_text') }}
          </span>
        </div>
        <div v-else class="w-[60%] h-full rounded-t-3">
          <XCircle
            class="absolute top-8 left-3 stroke-primary h-4 w-4"
            @click.stop="removeImage"/>
          <img
            :src="localOption.image"
            class="object-fill rounded-t-3 w-full h-20"/>
        </div>
      </div>

      <div class="w-full flex items-center justify-center">
        <HeadLine
          v-model="localOption.title"
          :closable="false"
          :placeholder="placeholder"
          max-width="100%"
          placement="center"
          :max-rows="3"
          :max-length="50"/>
      </div>
    </div>
    <OverlayPanel ref="teamPanel">
      <PollImageInput
        :id="localOption.id || ''"
        ref="imageEditor"
        :is-first="isFirst"
        :is-last="isLast"
        :placement="'end'"
        @replace-image="setImage"/>
    </OverlayPanel>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { FolderUp, XCircle } from 'lucide-vue-next';
import { HeadLine } from '@amit/components';
import PollImageInput from './PollImageInput.vue';
import { useI18n } from 'vue-i18n';
import type { PollOption } from '../types/poll.ts';
import type { PropType } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';

const { t } = useI18n();

const props = defineProps({
  option: {
    type: Object as PropType<PollOption>,
    default: () => {},
  },
  isFirst: {
    type: Boolean,
  },
  isLast: {
    type: Boolean,
  },
  isActive: {
    type: Boolean,
  },
  presentIcon: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
});
const teamPanel = ref();

const placeholder = computed(
  () =>
    props.placeholder ||
    t('blocksSettings.choice.default_title_field_placeholder'),
);

const emit = defineEmits(['title-changed', 'remove-option', 'image-changed']);

const localOption = ref({ ...props.option });
const isActive = ref(props.isActive);
// onMounted(()=> props.placeholder === ''?)

watch(
  () => localOption.value.title,
  newTitle => {
    emit('title-changed', newTitle);
  },
);
watch(
  () => localOption.value.image,
  newImage => {
    emit('image-changed', newImage);
  },
);

const imageEditor = ref(null);
const imageBuilderOpen = ref(false);

onClickOutside(imageEditor, () => {
  toggleImageBuilder(false);
});

const toggleImageBuilder = isOpen => {
  imageBuilderOpen.value = isOpen;
};

const setImage = (id, url) => {
  localOption.value!.image = url;
};

const removeImage = () => {
  localOption.value!.image = '';
};

const activateCard = () => {
  isActive.value = true;
};

const deactivateCard = () => {
  isActive.value = false;
};

const togglePanel = event => {
  teamPanel.value.toggle(event);
};
</script>
