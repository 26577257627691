<template>
  <FormControl :title="title" :sub-title="subTitle">
    <BankButton
      :text="getLocalizedText('word_bank')"
      :show-bank="showBank"
      @toggle="showBank = !showBank"/>
    <div
      ref="bankTextElement"
      class="grid-12 gap-5 mt-2 grid-flow-row auto-rows-auto">
      <div
        v-for="(match, index) in matches"
        :key="index"
        class="col-span-2 lg:col-span-3">
        <TagsInput
          v-model="model[match.id]"
          :title="match.title"
          :is-draggable="true"/>
      </div>
    </div>
    <div class="bank-button sticky left-2 right-2 bottom-0 shadow-box-light">
      <BankBanner
        v-if="showBank"
        v-model="optionsRef"
        group-name="answers"
        @hidden="closeBank">
        <template #item="{}"></template>
      </BankBanner>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import BankBanner from '@/components/BankBanner/BankBanner.vue';
import type { PropType } from 'vue';
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import type { ITag, ITaskModel } from '@/types/interfaces';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useElementVisibility, useVModel } from '@vueuse/core';
import TagsInput from '@/components/TagsInput/TagsInput.vue';
import BankButton from '@/components/BankButton/BankButton.vue';
import { useLocale } from '@/composables/useLocale';
import { shuffle as shuffleArray } from 'lodash';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  matches: {
    type: Array as PropType<ITag[]>,
    required: true,
    default: () => [],
  },
  options: {
    type: Array as PropType<ITaskModel[]>,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  locale: {
    type: String,
    default: 'he',
  },
  shuffle: {
    type: Boolean,
    default: false,
  },
});

const optionsRef: any = ref();
const showBank = ref(false);
const locale = computed(() => props.locale);
const { getLocalizedText } = useLocale(locale);
const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
const bankTextElement = ref();
const targetIsVisible = useElementVisibility(bankTextElement);

const closeBank = () => {
  showBank.value = false;
};

watchEffect(() => {
  showBank.value = targetIsVisible.value;
});

onMounted(() => {
  props.matches.forEach(match => {
    if (!model.value[match.id]) {
      model.value[match.id] = [];
    }
  });
});

watch(
  () => props.modelValue,
  () => {
    const modelValues = Object.values(model.value).flat();
    const options = props.options.filter(option => {
      return !modelValues.some(
        (modelValue: any) => modelValue.id === option.id,
      );
    });
    optionsRef.value = props.shuffle ? shuffleArray(options) : options;
  },
  { immediate: true },
);
</script>
