<template>
  <div class="h-full">
    <div
      v-if="type == 'select'"
      ref="target"
      :title="item.title"
      class="h-full px-1 py-1 flex items-center justify-center hover:bg-secondary-250 rounded-md cursor-pointer"
      @click="onSelectClick"
    >
      <div class="flex flex-col">
        <div class="relative flex items-center justify-center">
          <div v-if="item?.icon" class="w-5 flex items-center justify-center">
            <component
              :is="item?.icon"
              :size="16"
              color="#000"
              stroke-width="1.2"
            />
          </div>
          <p v-if="item?.text" class="w-full text-xs me-1">{{ item?.text }}</p>
          <ChevronDown
            v-if="item.showArrow"
            :size="16"
            color="#000"
            stroke-width="1.2"
          />
        </div>
        <Transition name="to-bottom">
          <div v-if="visible" class="absolute z-50 top-10" @click.stop>
            <component
              :is="item.component"
              v-bind="item.props"
              @action="onClick"
              @close="turnOffVisible"
            />
          </div>
        </Transition>
      </div>
    </div>

    <BaseButton
      v-else
      color="none"
      :title="item?.title"
      class="rounded-md items-center justify-center align-center select-none focus:ring-0 focus:outline-none !px-1 !py-1 hover:bg-secondary-250 !h-full"
      :class="{
        'opacity-50 pointer-events-none': isDisabled,
        'bg-secondary-250': item?.isActive() || open,
      }"
      @click="onClick"
    >
      <div
        class="w-5 bg-transparent rounded-md flex justify-center items-center"
      >
        <component :is="icon" :size="16" color="#000" stroke-width="1.2" />
      </div>
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { ref, inject } from 'vue';

//@ts-ignore
import { BaseButton } from '@';
import { onClickOutside } from '@vueuse/core';
import { ChevronDown } from 'lucide-vue-next';
import { useEditor } from './composables/useEditor';
const { configureEditorStyle, undoEditorStyle } = useEditor();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  icon: {
    type: Function,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
});

const open = ref(false);
const visible = ref(false);
const target = ref(null);
const onClick = e => {
  props.item.action(e);
};

const blockId = inject('blockId');

const onSelectClick = () => {
  if (props.item.loseFocus) {
    configureEditorStyle(blockId);
  }
  visible.value = !visible.value;
};

const turnOffVisible = () => {
  if (props.item.loseFocus) {
    undoEditorStyle(blockId);
  }
  visible.value = false;
};

onClickOutside(target, () => {
  if (visible.value && !props.item.loseFocus) {
    turnOffVisible();
  }
});
</script>
