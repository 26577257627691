<template>
  <FormControl :title="title" :sub-title="subTile">
    <div dir="ltr" class="flex items-center justify-center flex-col">
      <div v-if="!audioUrl" class="border-dotted border-b-8 w-1/2"></div>

      <div
        v-else
        class="flex items-center justify-center py-[24px] border h-[85px] px-[24px] xs:px-[24px] xl:px-[32px] 3xl:px-[40px] xs:gap-[18px] xl:gap-[20px] 3xl:gap-[12px] 3xl:h-[86px] custom-box-shadow"
        :class="rtl ? 'border-radius-rtl' : 'border-radius-ltr'">
        <div>
          <BaseButton
            size="sm"
            color="blue-light"
            class="xs:w-[34px] xl:w-[37px] xs:h-[34px] xl:h-[37px] !p-[9px] !bg-primary border-primary-500 !rounded-full"
            @click="pausePlayHandle">
            <div class="flex items-center">
              <Play v-if="!playing" class="stroke-white" :size="18" />

              <Pause v-else class="stroke-white h-[18px] w-[18px]" />
            </div>
          </BaseButton>
        </div>

        <audio ref="player" :src="audioUrl" :autoplay="autoplay" />
        <canvas ref="canvas" />
        <div class="text-center">
          <span class="sm:text-sm xl:text-base 3xl:text-md font-semibold">
            {{ time }}
          </span>
        </div>
      </div>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { BaseButton } from '@/components';
import { Play, Pause } from 'lucide-vue-next';
import { useAVWaveform } from 'vue-audio-visual';
import { useMediaControls, useWindowSize } from '@vueuse/core';
import { parseToMinutes } from '@/utils/helpers';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useReveal } from '@/apps/slideshow/useReveal';
import { mdSize, smSize } from '@/utils/breakpoints';

const { rtl } = useReveal();

interface IProps {
  src: string;
  title: string;
  subTile: string;
  autoplay: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  src: '',
  title: '',
  subTitle: '',
  autoplay: false,
});
const player = ref<any>();
const isPaused = ref(true);

const canvas = ref<any>();
const audioUrl = computed(() => props.src);
const playing = ref<any>(props.autoplay);
const waveWidth = ref<any>(null);
const { width } = useWindowSize();
const { currentTime, ended } = useMediaControls(player, {
  src: audioUrl.value,
});
const emit = defineEmits(['update:modelValue', 'audio-next-enabled']);
const time = computed(() => parseToMinutes(currentTime.value));

watch(isPaused, () => {
  if (isPaused.value) {
    playing.value = false;
  }
});

watch(
  () => props.src,
  () => {
    if (!props.autoplay) {
      playing.value = false;
    }
  },
);

watch(
  () => width.value,
  () => {
    if (width.value < smSize) {
      waveWidth.value = 177;
    }
    if (width.value >= smSize && width.value <= mdSize) {
      waveWidth.value = 219;
    }
    if (width.value > mdSize) {
      waveWidth.value = 250;
    }
  },
  { immediate: true },
);

const pausePlayHandle = () => {
  playing.value = !playing.value;
  playing.value ? player.value.play() : player.value.pause(); //stopTimer();
};

const createWaveForm = audio => {
  useAVWaveform(player, canvas, {
    canvHeight: 40,
    canvWidth: waveWidth.value,
    playtimeSliderColor: '#000',
    src: audio,
    playtime: false,
    playedLineWidth: 2,
    noplayedLineColor: '#B0BAC8',
    playedLineColor: '#000',
  });
};

watch(
  () => [audioUrl.value, waveWidth.value],
  () => {
    if (audioUrl.value) {
      createWaveForm(audioUrl.value);
    }
  },
  { immediate: true },
);

watch(ended, value => {
  if (value) {
    playing.value = false;
    emit('audio-next-enabled');
  }
});

const updatePausedState = () => {
  if (player.value) {
    isPaused.value = player.value.paused;
  }
};

onMounted(() => {
  if (player.value) {
    player.value.addEventListener('play', updatePausedState);
    player.value.addEventListener('pause', updatePausedState);
    updatePausedState();
  }
});

onUnmounted(() => {
  if (player.value) {
    player.value.removeEventListener('play', updatePausedState);
    player.value.removeEventListener('pause', updatePausedState);
  }
});
</script>

<style>
.custom-box-shadow {
  box-shadow:
    0px 1px 6px 2px rgba(12, 74, 110, 0.05),
    0px 2px 10px 0px rgba(12, 74, 110, 0.1);
}
.border-radius-rtl {
  border-radius: 0px 22px 22px 22px;
}
.border-radius-ltr {
  border-radius: 0px 22px 22px 22px;
}
</style>
