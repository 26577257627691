<template>
  <FormControl
    :title="title"
    :sub-title="subTitle"
    :label="label"
    :disabled="disabled"
    :truncated="true"
    class="flex flex-col">
    <div
      class="tag-box h-50 w-full overflow-y-auto"
      :class="{ 'mt-9': !title }"
      @click="inputFocus">
      <div
        class="z-20 flex flex-col border border-secondary-300 bg-secondary-75 rounded-xl h-50 overflow-y-auto p-2 w-full h-full"
        :class="{ 'border-dashed border-2': isDraggable }">
        <div
          v-if="isDraggable"
          class="h-full relative w-full"
          :class="{ 'flex flex-1 items-center justify-center': !tags.length }">
          <VueDraggable
            v-model="tags"
            group="answers"
            item-key="id"
            :sort="false"
            class="h-full w-full absolute"
            @add="emit('add')">
            <template #item="{ index }">
              <div
                class="tag-input rounded-4 mb-3 flex flex-col items-stretch tag-text">
                <TagInput
                  :title="tags[index].title"
                  :color="tags[index].label"
                  :removable="false"
                  class="h-full"/>
              </div>
            </template>
          </VueDraggable>
          <div
            v-if="!tags.length"
            class="flex h-full items-center justify-center">
            <Plus class="stroke-secondary-900 h-8 w-8" />
          </div>
        </div>
        <div v-else>
          <div
            class="tag-input w-full flex flex-wrap items-start justify-start gap-2">
            <TagInput
              v-for="(tag, index) in tags"
              :key="tag.id || index"
              :title="tags[index].title"
              :color="color"
              :class="{ duplicate: tags[index].title === duplicate }"
              :removable="!disabled"
              @remove-tag="removeTag(index)"/>
          </div>
          <div class="tag-input flex-1 flex justify-start items-start">
            <input
              ref="input"
              v-model="newTag"
              type="text"
              autocomplete="off"
              :placeholder="`הוספת ${placeholder}`"
              class="w-full h-10 bg-transparent z-10 border-0"
              :disabled="disabled"
              @keydown.enter="addTag(newTag)"
              @blur="addTag(newTag)"
              @keydown.prevent.tab="addTag(newTag)"
              @keydown.delete="newTag.length || removeTag(tags.length - 1)"/>
          </div>
        </div>
      </div>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import TagInput from './TagInput.vue';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import type { PropType } from 'vue';
import type { InputSize } from '@/components/Input/types';
import { provide, ref } from 'vue';
import type { TagType } from '@/components/TagsInput/types';
import { map } from 'lodash';
import type { ITag } from '@/types/interfaces';
import VueDraggable from 'vuedraggable';
import { Plus } from 'lucide-vue-next';

const emit = defineEmits(['add']);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
    usage: 'developer',
  },
  size: {
    type: String as PropType<InputSize>,
    default: 'md',
    usage: 'developer',
  },
  options: { type: [Array, Boolean], default: false },
  modelValue: {
    type: Array as PropType<ITag[]>,
    default: () => [],
  },
  color: {
    type: String as PropType<TagType>,
    default: 'default',
  },
  isDraggable: {
    type: Boolean,
    default: false,
  },
});
const tags = useVModel(props, 'modelValue');

const input = ref();
const inputFocus = () => {
  if (!props.isDraggable) input.value.focus();
};
// Tags
// const tags = ref(props.modelValue);
const newTag = ref('');

const addTag = tag => {
  if (!tag) return;
  if (map(tags.value, 'text').includes(tag)) {
    handleDuplicate(tag);
    return;
  }
  tags.value.push({ title: tag, id: Math.random().toString(36).substring(7) });
  newTag.value = '';
};
const removeTag = (index: number) => {
  tags.value.splice(index, 1);
};

// handling duplicates
const duplicate = ref(null);
const handleDuplicate = tag => {
  duplicate.value = tag;
  setTimeout(() => (duplicate.value = null), 1000);
  newTag.value = '';
};

provide('color', props.color);
</script>
<style>
@keyframes shake {
  10%,
  90% {
    transform: scale(0.9) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: scale(0.9) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: scale(0.9) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: scale(0.9) translate3d(4px, 0, 0);
  }
}
.duplicate {
  animation: shake 1s;
}
.tag-text {
  overflow-wrap: anywhere;
}
</style>
