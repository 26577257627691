<script setup lang="ts">
import { computed, ref, onUpdated, nextTick } from 'vue';
import type { ComputedRef } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import type { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import heLocale from '@fullcalendar/core/locales/he';
import type { ICalendarEvent } from '@/types/interfaces';
import { get } from 'lodash';

interface ICheckboxCalendar {
  initialView?: 'timeGridDay' | 'dayGridMonth' | 'timeGridWeek';
  events?: ICalendarEvent[];
  title?: string;
  modelValue: ICalendarEvent[];
  weekends?: boolean;
  nowIndicator?: boolean;
  businessDaysOfWeek?: number[];
  businessStartTime?: string;
  businessEndTime?: string;
}

const props = withDefaults(defineProps<ICheckboxCalendar>(), {
  initialView: 'dayGridMonth',
  events: () => [],
  title: '',
  modelValue: () => [],
  weekends: false,
  nowIndicator: true,
  businessDaysOfWeek: () => [0, 1, 2, 3, 4, 5, 6], // Monday - Thursday
  businessStartTime: '08:00', // a start time (10am in this example)
  businessEndTime: '18:00',
});

const emit = defineEmits(['event-click']);

const fullCalendar = ref<any>(null);

//this is a hack for triggering the resize event when the components is shown on the reveal
onUpdated(() => {
  nextTick(() => {
    let calendarApi = fullCalendar.value?.getApi();
    calendarApi.render();
  });
});

const calendarOptions: ComputedRef<CalendarOptions> = computed(() => ({
  plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
  initialView: 'timeGridWeek',
  weekends: true,
  locale: heLocale,
  height: 700,
  direction: 'rtl',
  scrollTime: '10:00:00',
  slotMinTime: '08:00',
  slotMaxTime: '22:00',
  businessHours: {
    daysOfWeek: props.businessDaysOfWeek,
    startTime: props.businessStartTime,
    endTime: props.businessEndTime,
  },

  events: props.events.map((event: any) => {
    return {
      title: event.event,
      daysOfWeek: [new Date(event.starts_at).getUTCDay()],
      color: event.color,
      startTime: new Date(event.starts_at).toUTCString().split(' ')[4],
      endTime: new Date(event.ends_at).toUTCString().split(' ')[4],
      editable: false,
      extendedProps: {
        question: event.id,
        clickable: event.clickable,
      },
    };
  }) as any,
  headerToolbar: {
    center: 'title',
    left: '',
    right: '',
  },
  eventClick: handleEventClick,
  allDaySlot: false,
  aspectRatio: 2,
}));

const handleEventClick = item => {
  const { clickable, question } = get(item, 'event._def.extendedProps', {});
  if (!clickable) {
    return;
  }
  emit('event-click', { question });
};
</script>

<template>
  <div id="calendar" style="width: 100%; display: inline-block">
    <span class="font-simplerBold whitespace-pre-line">{{ title }}</span>
    <FullCalendar ref="fullCalendar" :options="calendarOptions">
      <template #eventContent="arg">
        <div
          class="flex items-center justify-center"
          :class="{
            'cursor-pointer h-full': arg.event.extendedProps.clickable,
          }">
          <div class="text-default" :class="`bg-[${arg.event.color}]`">
            {{ arg.event.title }}
          </div>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>
