<template>
  <FormControl :title="title" :sub-title="subTitle" :identifier="identifier">
    <div class="flex flex-col gap-5 py-3" :class="identifier">
      <BaseTextArea
        v-for="(model, index) in models"
        :key="index"
        v-model="model.text"
        :title="model.title"
        class="flex w-80/100 gap-3"
        :placeholder="placeholder"
        :block="block"
        :rows="rows"
        :identifier="`${identifier}-${index}`"
        :editable="editable">
        <template #tip>
          <Trash
            v-if="multi && editable && index + 1 > min"
            class="cursor-pointer h-4 w-4 mb-2"
            @click="() => removeItem(index)"/>
        </template>
      </BaseTextArea>
      <button
        v-if="multi && editable && models.length < max"
        class="bg-primary-100 w-80/100 focus:outline-none rounded-lg flex items-center py-3 pr-4 gap-2"
        @click="addMore()">
        <Plus class="w-4.5 h-4.5 stroke-1" />
        <div>{{ addtext }}</div>
      </button>
    </div>
    <div
      v-if="$slots.tip"
      class="mt-2 text-sm text-secondary-500 dark:text-secondary-400">
      <slot name="tip" />
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useVModel } from '@vueuse/core';
import { watch, computed } from 'vue';
import { Trash2 as Trash, Plus } from 'lucide-vue-next';
import { generateObjectId } from '@/utils';
import BaseTextArea from '@/components/Textarea/BaseTextArea.vue';
import { isString } from 'lodash';

interface Option {
  id: string;
  title?: string;
  text?: string;
  placeholder?: string;
}

interface IProps {
  title?: string;
  subTitle?: string;
  placeholder?: string;
  modelValue: any[];
  options?: Option[];
  block?: string;
  identifier?: string;
  multi: boolean;
  min?: number;
  max?: number;
  rows?: number;
  editable: boolean;
  addtext?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  title: '',
  subTitle: '',
  modelValue: () => [],
  block: '',
  identifier: '',
  placeholder: 'התשובה שלי',
  options: () => [],
  multi: false,
  min: 1,
  max: 3,
  rows: 1,
  editable: true,
  addtext: 'הוספה',
});

const emit = defineEmits(['update:modelValue']);
const models = useVModel(props, 'modelValue', emit);

//remove this - its for old submissions
const containsString = computed(() => isString(models.value[0]));

watch(
  () => props.modelValue,
  () => {
    if (models.value.length && !containsString.value) {
      return;
    }
    props.options.forEach((option, index) => {
      models.value[index] = {
        id: option.id,
        text: containsString.value ? models.value[index] : option.text || '',
        title: option.title,
      };
    });
  },
  { immediate: true },
);

const removeItem = index => {
  models.value.splice(index, 1);
};

const addMore = () => {
  if (models.value.length < props.max) {
    models.value.push({
      id: generateObjectId(),
      text: '',
    });
  }
};
</script>
