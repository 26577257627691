<template>
  <FormControl :title="title" :sub-title="subTitle">
    <div v-if="inputTitle" class="mb-4 font-simplerBold">
      {{ inputTitle }}
    </div>
    <div
      ref="wordCloudRef"
      class="flex gap-3 flex-row-reverse w-[593px]"
      @keydown.enter="addWordsToCloud">
      <button
        class="bg-primary text-white text-sm relative flex rounded-lg px-4 items-center justify-center"
        :class="{
          'bg-secondary-700': !isWordValid,
        }"
        :disabled="!isWordValid"
        @click="addWordsToCloud">
        הוספה לענן
      </button>
      <BaseInput
        v-model="studentAnswer"
        size="md"
        placeholder="הקלידו מילה"
        :maxlength="maxlength"
        :disabled="modelValue.length === maxAnswers"></BaseInput>
    </div>

    <div class="mt-[18px]">
      {{ `העלת ${modelValue.length} תשובות מתוך ${maxAnswers}` }}
    </div>

    <div class="mt-[7px]">
      <div v-if="modelValue.length > 0">
        <vue-word-cloud
          style="height: 400px; width: 593px"
          :words="wordsCount"
          :color="
            ([, weight]) => colors[Math.floor(Math.random() * colors.length)]
          "
          :font-family="'SimplerBold'"/>
      </div>

      <div v-else class="py-[80px] w-[593px] bg-secondary-75">
        <div class="relative flex justify-center">
          <div class="relative text-gray-400">
            <span
              class="absolute text-secondary-400 left-[22px] w-[87px] text-center top-[44px] text-base font-simplerBold">
              word
            </span>
            <span
              class="absolute text-secondary-400 right-[21px] w-[87px] text-center bottom-[29px] text-md font-simplerBold">
              Cloud
            </span>
            <img
              alt="Word cloud emty image"
              :src="WordCloudIcon"
              class="h-[110px] w-[147px]"/>
          </div>
        </div>
        <div
          class="flex justify-center mt-[13px] font-simplerBold text-xs text-secondary-400">
          בקרוב תוכלו לראות את ענן המילים
        </div>
      </div>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import { ref, watchEffect, nextTick, computed } from 'vue';
import VueWordCloud from 'vuewordcloud';
import { BaseInput } from '@/components';
import WordCloudIcon from '@/assets/word-cloud-icon.svg?url';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';

type Word = {
  text: string;
  count: number;
};

interface IProps {
  modelValue: string[];
  title: string;
  inputTitle: string;
  subTitle: string;
  minAnswers: number;
  maxAnswers: number;
  maxlength: number;
  currentAnswer: number;
  words: Word[];
}
interface WordCount {
  0: string; // The first element is a string
  1: number; // The second element is a number
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => [],
  title: '',
  inputTitle: '',
  subTitle: '',
  minAnswers: 1,
  maxAnswers: 2,
  maxlength: 12,
  currentAnswer: 0,
  words: () => [],
});

const emit = defineEmits(['update', 'update:ModelValue']);
const model = useVModel(props, 'modelValue', emit);

const colors = ['rgb(64, 48, 48)', 'rgb(249, 122, 122)'];
const wordsCount = ref<WordCount[]>([]);
const studentAnswer = ref<string>('');
const wordCloudRef = ref(null);

watchEffect(() => {
  if (props.words) {
    wordsCount.value = props.words?.map(({ text, count }) => [text, count]);
  }
});
const isWordValid = computed(() => {
  return studentAnswer.value.trim();
});

const addWordsToCloud = () => {
  if (isWordValid.value) {
    const answerWord = studentAnswer.value;
    model.value.push(answerWord);

    nextTick(() => {
      emit('update');
    });
    studentAnswer.value = '';
  }
};
</script>
