<template>
  <div class="w-full flex gap-4 relative pe-10">
    <div
      class="flex flex-col w-full rounded-lg border border-secondary-250"
      :class="{ 'disabled-wrapper': wrapperDisabled }">
      <div
        v-if="barSettings"
        class="relative flex justify-end items-center px-6 h-8.5 w-full rounded-t-lg border-b-2"
        :style="{
          background: barSettings.background,
          borderColor: barSettings.border,
        }">
        <div
          class="absolute inset-x-1.75 top-1.75 flex items-start bg-[#F7D46A] h-[14px] rounded-full"
          :style="{ background: barSettings.variant }">
          <span
            class="z-[100] text-sm font-simplerBold px-4"
            :style="{ color: barSettings.color }">
            {{ barSettings.title }}
          </span>
        </div>
        <!-- <div class="z-[100] relative flex justify-end items-center w-[34px]">
          <div
            class="absolute end-4 flex items-center justify-center pe-0.5 pt-1 bg-[#FFF1AB] rounded-s-full h-5 w-full">
            <span class="text-xs font-simplerBold text-secondary-900">
              {{ block.settings?.coins ?? 0 }}
            </span>
          </div>
          <img :src="CoinIcon" alt="coin" class="z-[100] w-6.5 h-6.5" />
        </div> -->
      </div>
      <div class="flex flex-col gap-5 px-7 py-5">
        <div :class="{ 'pointer-events-none': wrapperDisabled }">
          <slot name="body" />
        </div>
        <div class="flex justify-between">
          <div v-tooltip.top="resubmitTooltip">
            <PVButton
              v-if="resubmitAllowed"
              severity="light"
              class="!shadow-light focus:!ring-0 !text-sm !text-secondary-800 !font-simplerBold"
              rounded
              :label="
                state.editing
                  ? getLocalizedText('cancel')
                  : getLocalizedText('correct')
              "
              @click="toggleResubmit">
              <template v-if="!state.editing" #icon>
                <TipexIcon class="ltr:scale-x-[-1]" />
              </template>
            </PVButton>
          </div>
          <div class="flex gap-6">
            <Transition name="bounce">
              <FeedbackAlert
                v-if="submimssionReady && message"
                :type="submissionRecord.completion"
                :score="submissionRecord.score"
                :message="message"
                :hide-completion="hideCompletion"/>
            </Transition>
            <BaseButton
              class="w-[78px] h-[34px] flex justify-center"
              :color="disabled ? 'gray' : 'blue'"
              :disabled="disabled"
              :loading="state.submitting"
              @click="submitAnswer">
              <span class="font-simplerBold text-sm">
                {{ getLocalizedText('submit') }}
              </span>
            </BaseButton>
          </div>
        </div>
        <div
          v-if="submimssionReady && reviewNotes"
          class="h-22 overflow-y-auto scrollbar-hide bg-[#F4F7FB] border border-secondary-300 px-4 py-3 rounded-lg">
          {{ reviewNotes }}
        </div>
      </div>
    </div>
    <div
      v-if="block.settings?.hints?.length"
      class="absolute top-1 flex items-center justify-center h-10 w-11 rounded-full cursor-pointer shadow-light hover:bg-[#F4F7FB] -end-3"
      @click="$emit('openHint', block)">
      <HintIcon />
    </div>
    <div
      v-if="submimssionReady && submission.history?.length"
      class="absolute bottom-0.5 flex flex-col gap-1.5 w-8.5 p-2 rounded-full shadow-light -end-3">
      <div
        v-for="(item, index) of shownVersions"
        :key="index"
        class="flex items-center justify-center rounded-full h-4.5 w-4.5 cursor-pointer"
        :class="[
          currentVersion === item.version ? 'bg-primary' : 'bg-secondary-500',
        ]"
        @click="setCurrentVersion(item.version)">
        <span class="text-xs text-white font-simplerBold h-3.5">
          {{ item.version }}
        </span>
      </div>
      <div
        class="cursor-pointer hover:bg-[#F4F7FB]"
        @click="toggleHistoryShown">
        <HistoryIcon
          v-if="!historyShown"
          class="stroke-secondary-800"
          :size="18"/>
        <CancelIcon v-else class="stroke-secondary-800 stroke-3" :size="18" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useReveal } from '@/apps/slideshow/useReveal';
import BaseButton from '@/components/Button/BaseButton.vue';
import type { BuildingBlock, BlockState } from '@/apps/slideshow/blocks';
import FeedbackAlert from '@/components/FeedbackAlert/FeedbackAlert.vue';
import PVButton from 'primevue/button';
// import CoinIcon from '@/assets/coin.svg?url';
import HintIcon from '@/assets/hint.svg?component';
import TipexIcon from '@/assets/tipex.svg?component';
import { History as HistoryIcon, X as CancelIcon } from 'lucide-vue-next';

const { getLocalizedText } = useReveal();

const block = defineModel<BuildingBlock>('block', { required: true });

const emit = defineEmits(['submit', 'openHint']);

const settings = {
  bonus: {
    title: getLocalizedText('bonus'),
    background: '#F5CB45',
    variant: '#F7D46A',
    color: '#75393C',
    border: '#ECB915',
  },
  reflection: {
    title: getLocalizedText('reflection'),
    background: '#D7DFEA',
    variant: '#E3E9F2',
    color: '#495465',
    border: '#C5CFDD',
  },
};

const currentVersion = ref();
const historyShown = ref(false);

const state = computed<BlockState>(() => block.value.state || {});
const submission = computed(() => state.value.submission ?? {});
const submimssionReady = computed(
  () => !state.value.submitting && !state.value.editing,
);
const wrapperDisabled = computed(
  () => state.value.submitted && !state.value.editing,
);

const disabled = computed(
  () => state.value.disabled || state.value.submitting || wrapperDisabled.value,
);

const shownVersion: any = computed(
  () =>
    shownVersions.value.find(item => item.version === currentVersion.value) ??
    {},
);

const submissionRecord = computed(() => {
  const records = shownVersion.value.records ?? [];
  return records.find(record => record.question === block.value.id) ?? {};
});

const exerciseAttempts = computed(
  () => state.value.exercise?.completion?.attempts ?? 3,
);

const changesRequested = computed(() =>
  ['changes_requested', 'pending_changes'].includes(submission.value.status),
);

const resubmitAllowed = computed(() => {
  const attemptsLeft =
    ['finished', 'awaiting_review'].includes(submission.value.status) &&
    submission.value.attempts < exerciseAttempts.value;
  return (
    currentVersion.value === lastVersion.value &&
    (changesRequested.value || attemptsLeft)
  );
});

const resubmitTooltip = computed(() => {
  if (changesRequested.value) {
    return getLocalizedText('changes_requested');
  }

  const attemptsLeft = exerciseAttempts.value - submission.value.attempts;
  return `${attemptsLeft}/${exerciseAttempts.value - 1} ${getLocalizedText('attempts_left')}`;
});

const barSettings = computed(() => {
  const type = block.value.settings?.type ?? 'default';
  return settings[type];
});

const message = computed(() => submissionRecord.value.feedback);
const reviewNotes = computed(() => shownVersion.value.review_notes);
const hideCompletion = computed(() => {
  const needsReview = state.value.exercise?.settings?.needs_review ?? false;
  const feedbackEnabled = block.value.completion?.feedback_enabled ?? true;
  return (
    !feedbackEnabled || (needsReview && submissionRecord.value.score === null)
  );
});

const history = computed(() =>
  JSON.parse(JSON.stringify(submission.value.history ?? [])),
);
const lastVersion = computed(() => history.value.length + 1);
const shownVersions = computed(() => {
  const versions = [{ ...submission.value, version: lastVersion.value }];
  if (historyShown.value) {
    const reversedHstory = [...history.value].reverse();
    versions.push(...reversedHstory);
  }
  return versions;
});

watch(
  () => lastVersion.value,
  lastVersion => {
    currentVersion.value = lastVersion;
  },
  { immediate: true },
);

const submitAnswer = () => {
  state.value.editing = false;
  emit('submit');
};

const toggleResubmit = () => {
  if (state.value.editing) {
    setCurrentVersion(lastVersion.value);
  } else {
    historyShown.value = false;
  }

  state.value.editing = !state.value.editing;
};

const setCurrentVersion = version => {
  currentVersion.value = version;
  block.value.state.historyState = JSON.parse(
    JSON.stringify(submissionRecord.value),
  ).answer;
};

const toggleHistoryShown = () => {
  if (historyShown.value && currentVersion.value !== lastVersion.value) {
    setCurrentVersion(lastVersion.value);
  }

  historyShown.value = !historyShown.value;
};
</script>

<style>
.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.disabled-wrapper,
.disabled-wrapper .text-area-wrapper {
  @apply !bg-secondary-75;
}
</style>
