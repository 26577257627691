<template>
  <FormControl :title="title" :sub-title="subTitle">
    <div class="flex flex-col gap-5">
      <div>יעד {{ currentTabIndex + 1 }} מתוך {{ models.length }}</div>
      <BaseTabs v-model="activeTab" color="blue" variant="progress">
        <template #pane="{ index, onClick }">
          <div
            class="grow h-2 rounded-full cursor-pointer"
            :class="[
              index <= currentTabIndex ? 'bg-primary' : 'bg-secondary-300',
            ]"
            @click="onClick"></div>
        </template>
        <BaseTab
          v-for="(model, index) in models"
          :key="model.id"
          :name="model.id">
          <TaskLine
            v-model="models[index]"
            :color="model.label"
            :editable="false"/>
          <div class="flex flex-col gap-3">
            <div class="font-simplerBold text-md">האם עמדת ביעד?</div>
            <div class="flex gap-5.5">
              <div
                v-for="(choice, choiceIndex) in choices"
                :key="choiceIndex"
                class="control-choice"
                :class="{
                  'control-choice-selected': model.status === choice.value,
                }"
                @click="setStatus(choice.value)">
                <img :src="choice.img" class="h-10" alt="status_img" />
                <span>{{ choice.text }}</span>
              </div>
            </div>
            <div
              v-if="showJumpToNextTab"
              class="self-end flex items-center gap-3 cursor-pointer"
              @click="nextTab">
              <span class="font-simplerBold text-primary">ליעד הבא</span>
              <button
                class="px-2 py-1 rounded-lg text-white bg-primary items-center justify-center">
                <ChevronLeft class="stroke-white w-4" />
              </button>
            </div>
          </div>
        </BaseTab>
      </BaseTabs>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed, ref, watchEffect } from 'vue';
import { useVModel } from '@vueuse/core';
import { ChevronLeft } from 'lucide-vue-next';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { TaskLine, BaseTabs, BaseTab } from '@/components/index';
import { ITaskModel, IPlanCheckModel } from '@/types/interfaces';
import { each } from 'lodash';
import StarsFace from '@/assets/starsFace.svg?url';
import SadFace from '@/assets/sadFace.svg?url';
import SmirkingFace from '@/assets/smirkingFace.svg?url';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<ITaskModel[]>,
    default: () => [],
  },
  modelValue: {
    type: Array as PropType<IPlanCheckModel[]>,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);
const models = useVModel(props, 'modelValue', emit);

const choices = [
  {
    text: 'ממש לא',
    value: 'failed',
    img: SadFace,
  },
  {
    text: 'חצי כח',
    value: 'partial',
    img: SmirkingFace,
  },
  {
    text: 'כמובן',
    value: 'success',
    img: StarsFace,
  },
];

const currentTabIndex = ref(0);
const activeTab = computed({
  get: () => models.value[currentTabIndex.value]?.id,
  set: value =>
    (currentTabIndex.value = models.value.findIndex(
      model => model.id === value,
    )),
});

const showJumpToNextTab = computed(() => {
  return (
    currentTabIndex.value < models.value.length - 1 &&
    models.value[currentTabIndex.value].status
  );
});

watchEffect(() => {
  if (props.modelValue.length) {
    return;
  }

  each(props.options, option => {
    let optionIndex = models.value.findIndex(model => option.id === model.id);

    const modelOption = {
      ...JSON.parse(JSON.stringify(option)),
      status: '',
    };

    if (optionIndex > -1) {
      models.value[optionIndex] = modelOption;
    } else {
      models.value.push(modelOption);
    }
  });
});

const nextTab = () => {
  if (currentTabIndex.value < models.value.length - 1) {
    currentTabIndex.value = currentTabIndex.value + 1;
  }
};

const setStatus = status => {
  models.value[currentTabIndex.value].status = status;
  nextTab();
};
</script>
<style scoped>
.control-choice {
  @apply w-[182px] h-[88px] border-2 border-secondary-300 rounded-xl bg-secondary-75 flex gap-1.5 justify-center items-center cursor-pointer;
}
.control-choice > span {
  @apply text-lg text-default font-simplerBold;
}
.control-choice-selected {
  @apply border-primary-700 bg-primary-200;
}
</style>
