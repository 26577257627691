<script setup lang="ts">
import { ref, computed } from 'vue';
import { FolderUp, X, FolderOpen } from 'lucide-vue-next';

const props = defineProps({
  textUpload: {
    type: String,
    default: '',
  },
  uploadProgress: {
    type: Number,
    default: -1,
  },
  textSuccess: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Object,
    default: () => ({
      file: null,
      index: 0,
      type: '',
    }),
  },
  index: {
    type: Number,
    default: 0,
  },
  loading: {
    type: Object,
    default: () => ({
      status: false,
      index: null,
    }),
  },
  isDraggable: {
    type: Boolean,
    default: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});
const error = ref('');
const emit = defineEmits(['upload-file', 'delete-file', 'abort-upload']);
const sizeStr = ref();
const fileNameError = ref();
const isDragging = ref(false);
const isHovered = ref(false);
const maxFileSize = 20;

const formatBytes = (bytes, decimals = 2) => {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const selectFile = event => {
  const file = event?.dataTransfer?.files[0] || event?.target?.files[0];
  if (!file) {
    return;
  }

  const { size, name, type } = file;

  sizeStr.value = formatBytes(size);
  const sizeMb = size / 1024 ** 2;
  if (sizeMb > maxFileSize) {
    error.value = 'הקובץ גדול מידי';
    fileNameError.value = name;
    return;
  }
  error.value = '';
  emit('upload-file', {
    index: props.index,
    file,
    preview: URL.createObjectURL(file),
    name,
    type,
  });
};

const removeFile = () => {
  emit('delete-file', {
    index: props.index,
    file: null,
  });
};

const getButtonText = () => {
  if (props.loading.status && props.loading.index === props.index) {
    return 'uploading';
  }
  return !props.modelValue.file
    ? 'גרירה/העלאת קובץ/תמונה לכאן'
    : 'הקובץ עלה בהצלחה';
};

const onDrop = e => {
  if (props.isDraggable) {
    e.preventDefault();
    selectFile(e);
  }
};

const isImage = type => type.startsWith('image/');

const abortUpload = () => {
  emit('abort-upload', props.index);
  setTimeout(() => removeFile(), 100);
};

const isCurrentLoading = computed(
  () => props.loading.status && props.loading.index === props.index,
);
const fileName = computed(() => {
  const { name, preview } = props.modelValue || {};
  return (
    name ||
    preview
      ?.match(/\/([^/]+)\.(\w+)$/)
      ?.slice(1)
      .join('.') ||
    ''
  );
});
</script>

<template>
  <div
    class="flex flex-col space-y-2"
    @drop="onDrop"
    @dragenter="isDragging = true"
    @dragleave="isDragging = false"
    @dragover.prevent="isDragging = true"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false">
    <label
      class="flex flex-col relative w-100 md:w-100 h-28 rounded-xl border-2 justify-center items-center text-secondary-900 text-sm space-y-2"
      :class="{
        'cursor-pointer': editable,
        'border-1 border-primary-700 border-dashed text-primary-700 bg-secondary-100':
          !isCurrentLoading && (isDragging || isHovered) && !modelValue.preview,
        'border-1 border-secondary-250 border-dashed text-secondary-900':
          !modelValue.preview,
      }"
      :disabled="modelValue.file || error">
      <a
        v-if="modelValue.preview"
        :href="modelValue.preview"
        :download="modelValue.type"
        target="_blank"
        class="file-link relative flex items-center h-full w-full cursor-pointer border rounded-xl justify-between">
        <img
          v-if="isImage(props.modelValue.type)"
          :src="modelValue.preview"
          class="h-full w-full object-fill rounded-xl"
          :alt="modelValue.preview"/>
        <div
          v-else
          class="flex justify-start items-center w-full h-full px-6 gap-2"
          dir="ltr">
          <component :is="modelValue.icon" class="stroke-1" />
          <div class="flex flex-col text-start gap-3 max-w-[70%]">
            <span class="font-bold text-sm text-[#1E232A]">
              {{ fileName }}
            </span>
            <span v-if="sizeStr" class="text-xs font-normal text-secondary-900">
              {{ sizeStr }}
            </span>
          </div>
        </div>
        <div
          class="remove-file absolute top-2 right-2 cursor-pointer bg-white flex items-center justify-center p-1 rounded-full"
          @click="removeFile"
          @click.stop="event => event.preventDefault()">
          <X :size="20" />
        </div>
      </a>

      <div
        v-else
        dir="ltr"
        class="flex flex-col justify-center items-center w-full px-5 gap-2">
        <div
          v-if="isCurrentLoading"
          class="absolute top-2 right-2 cursor-pointer bg-white flex items-center justify-center p-1 rounded-full"
          @click="abortUpload"
          @click.stop="event => event.preventDefault()">
          <X :size="20" />
        </div>
        <div v-if="!isCurrentLoading">
          <FolderOpen
            v-if="(isDragging || isHovered) && !isCurrentLoading"
            class="stroke-1"/>
          <FolderUp v-else class="stroke-1" />
        </div>
        <span
          class="text-xs font-normal"
          :class="{ 'self-start': isCurrentLoading }">
          {{ getButtonText() }}
          <span v-if="isCurrentLoading">{{ uploadProgress }}%</span>
        </span>
        <div
          v-if="isCurrentLoading"
          dir="ltr"
          class="w-full h-1 bg-gray-200 rounded-2xl overflow-hidden">
          <div
            class="h-full bg-primary-700 transition-width duration-300"
            :style="{ width: uploadProgress + '%' }"></div>
        </div>
        <span v-else class="text-[#B9C4D4] text-xs" dir="rtl">
          {{ `גודל מקסימלי: ${maxFileSize}MB  ` }}
        </span>
        <input
          class="sr-only"
          type="file"
          accept=".txt, .pdf, image/*, audio/*, .doc, .docx, .mp4, .xlsx"
          :disabled="!editable"
          @change="selectFile"/>
      </div>
    </label>
    <div
      v-if="error"
      dir="ltr"
      class="flex items-center py-4 px-2 w-full cursor-pointer border border-red-700 bg-red-100 rounded-xl justify-between">
      <div class="flex gap-4 items-center">
        <span class="font-simplerBold">{{ fileNameError }}</span>
        <span>{{ sizeStr }}</span>
      </div>
      <span class="font-simplerBold">{{ error }}</span>
      <X @click="error = ''" />
    </div>
  </div>
</template>
