import { PostData } from '../types/post';
import { sendbirdUtils } from './utils';

const { formatPostMessage, sortedByDate } = sendbirdUtils;

export function useChannelDemo() {
  const sendMessageDemo = (
    text: any,
    posts,
    parentId: number | null = null,
  ) => {
    if (!parentId) {
      const mockPost = {
        likes: [],
        group_name: 'Demo Group',
        comments: [],
        post_id: (Math.random() * 100000).toString(),
        message_id: Date.now(),
        reactions: [],
        title: text,
        metadata: {
          message_id: Date.now().toString(),
          title: text,
          text: text,
        },
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        cover_img: null,
        author: {
          _iid: 'demo-author-id',
          userId: 'demo-user-id',
          nickname: 'demo user',
          plainProfileUrl: '',
          requireAuth: false,
          metaData: {
            grade: 'N/A',
            role: 'Demo',
            school: 'Demo School',
            school_id: 'demo-school-id',
            username: 'demouser',
          },
          connectionStatus: 'online',
          isActive: true,
          lastSeenAt: null,
          preferredLanguages: ['en'],
          friendDiscoveryKey: null,
          friendName: null,
          isBlockedByMe: false,
          role: 'none',
        },
        status: 'ACTIVE',
        comment_status: true,
      };
      posts.value = sortedByDate([...posts.value, mockPost]);
    } else {
      const parentIndex = posts.value.findIndex(
        post => post.message_id === parentId,
      );
      let parentPost = posts.value[parentIndex];
      parentPost.comments.push({
        message: text,
        messageId: Date.now().toString(),
        sender: {
          nickname: 'demo user',
        },
      });
      posts[parentIndex] = parentPost;
    }
  };

  const editMessageDemo = (postToEdit: PostData, posts) => {
    if (postToEdit.parentId) {
      const parentIndex = posts.value.findIndex(
        post => post.message_id === postToEdit.parentId,
      );
      let parentPost = posts.value[parentIndex];
      const commentIndex = parentPost.comments.findIndex(
        comment => comment.messageId === postToEdit.message_id,
      );
      if (commentIndex !== -1) {
        parentPost.comments[commentIndex].message = postToEdit.title;
      }
      posts[parentIndex] = parentPost;
    } else {
      const index = posts.value.findIndex(
        post =>
          postToEdit?.message_id && post.message_id === postToEdit.message_id,
      );
      if (index !== -1) {
        const updatedPost = posts.value[index];
        updatedPost.title = postToEdit.title;
      }
    }
  };

  const deleteMessageDemo = (postToDelete: PostData, posts) => {
    if (postToDelete.parentId) {
      const parentIndex = posts.value.findIndex(
        post => post.message_id === postToDelete.parentId,
      );
      let parentPost = posts.value[parentIndex];
      const commentIndex = parentPost.comments.findIndex(
        comment => comment.messageId === postToDelete.message_id,
      );
      if (commentIndex !== -1) {
        parentPost.comments.splice(commentIndex, 1);
      }
      posts[parentIndex] = parentPost;
    } else
      posts.value = posts.value.filter(
        post => post.message_id !== postToDelete.message_id,
      );
  };
  const reactToMessageDemo = (
    posts,
    messageId: number,
    emojiKey: string,
    cancelReaction: boolean = false,
    userId: string | null,
  ) => {
    const index = posts.value.findIndex(
      post => post?.message_id && post.message_id === messageId,
    );
    if (index !== -1) {
      const updatedPost = posts.value[index];
      if (cancelReaction) {
        updatedPost.reactions = updatedPost.reactions.filter(
          reaction => reaction.emojiKey !== emojiKey,
        );
      } else {
        updatedPost.reactions.push({
          emojiKey: emojiKey,
          count: 1,
          userIds: [userId],
        });
      }
    }
  };

  return {
    sendMessageDemo,
    editMessageDemo,
    deleteMessageDemo,
    reactToMessageDemo,
  };
}
