<template>
  <FormControl
    :title="title"
    :sub-title="subTitle"
    :label="label"
    :disabled="!editable"
    class="w-full"
  >
    <div
      v-if="editor && !isLoading"
      :dir="locale != 'he' ? 'ltr' : ''"
      class="preview-wrapper"
      :class="{ 'border border-secondary-250 p-2': editable }"
    >
      <TiptapMenu v-if="editable && editor" :id="blockId" />
      <TiptapContent :editor="editor" />
    </div>
    <div v-else class="h-60">
      <LoadingOverlay
        v-model:active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      />
    </div>

    <p
      v-if="$slots.helper"
      class="mt-2 text-sm text-secondary-500 dark:text-secondary-400"
    >
      <slot name="helper" />
    </p>
  </FormControl>
</template>

<script lang="ts" setup>
import {
  computed,
  onUnmounted,
  provide,
  ref,
  resolveComponent,
  watch,
} from 'vue';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import TiptapMenu from './TiptapMenu.vue';
import TiptapContent from './TiptapContent.vue';
import { useEditor } from './composables/useEditor';
import isEmpty from 'lodash/isEmpty';
const LoadingOverlay = resolveComponent('LoadingOverlay');
const { getEditor, createEditor } = useEditor();
const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Array],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'my answer',
  },
  subTitle: {
    type: String,
    default: '',
  },
  editable: {
    type: Boolean,
    default: true,
  },
  text: {
    type: [Object, String],
    default: '',
  },
  locale: {
    type: String,
    default: 'he',
  },
  preview: {
    type: Boolean,
    default: true,
  },
  extensions: {
    type: Object,
    default: () => ({
      line_height: false,
      horizontal_rule: false,
      tooltip: false,
      background_color: false,
      hide_color: false,
      link: false,
      hide_image: false,
      hide_image_resize: false,
    }),
  },
});
const blockId = ref(`${props.preview ? 'preview-' : ''}${props.id}`);
provide('blockId', blockId.value);
const emit = defineEmits(['update:modelValue']);
const model = computed(() =>
  !isEmpty(props.modelValue) ? props.modelValue : props.text,
);
const isLoading = computed(() => Array.isArray(props.modelValue));
createEditor({
  id: blockId.value,
  editable: props.editable,
  direction: props.locale != 'he' ? 'ltr' : 'rtl',
  model: model.value,
  onUpdate: () => {
    if (!editor.value) return;
    const html = editor.value?.getHTML();
    emit('update:modelValue', html);
  },
  extensions: {
    placeholder: { placeholder: props.placeholder },
    ...props.extensions,
  },
});

const editor = getEditor(blockId.value);

watch(
  () => [editor.value, props.modelValue],
  () => {
    const html = editor.value?.getHTML();
    if (!isEmpty(props.modelValue) && html !== props.modelValue) {
      editor.value?.commands.setContent(props.modelValue);
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  editor.value.destroy();
});
</script>
