<script lang="ts" setup>
import type { IPostElement } from '@/types/interfaces';
interface IBoxItem {
  disabled: boolean;
  error: boolean;
  selected: boolean;
  success: boolean;
  item: Partial<IPostElement>;
}
const props = withDefaults(defineProps<IBoxItem>(), {
  disabled: false,
  error: false,
  selected: false,
  success: false,
});

const emit = defineEmits(['update:selected']);

const handleClick = () => {
  if (!props.disabled) emit('update:selected');
};
</script>

<template>
  <div
    class="rounded-3xl text-center border h-16 aspect-square border"
    :class="{
      'border-primary bg-primary-200': selected,
      'border-secondary-300 bg-secondary-75': !selected,
    }"
    @click="handleClick">
    <div
      v-if="item.title"
      class="w-full h-full flex flex-col items-center justify-center p-4">
      <div class="text-secondary-900 text-md font-simplerBold p-2">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
