<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import SelectInput from '@/components/ChoiceGroup/SelectInput.vue';
import PostItem from '@/components/Elements/PostItem.vue';
import { get, find, shuffle as shuffleArray } from 'lodash';
import { useSelect } from '@/components/ChoiceGroup/useSelect';
import type { IPostElement } from '@/types/interfaces';
import BoxItem from '@/components/Elements/BoxItem.vue';
import QuestionnaireBoxItem from '@/components/Elements/QuestionnaireBoxItem.vue';
import NumbersBoxItem from '@/components/Elements/NumbersBoxItem.vue';
import SmallNumbersBoxItem from '@/components/Elements/SmallNumbersBoxItem.vue';
import HorizontalCardItem from '@/components/Elements/horizontalCardItem.vue';
import BlockBoxItem from '@/components/Elements/BlockBoxItem.vue';
import RangeSelect from '@/components/RangeSelect/RangeSelect.vue';

export type ChoiceType =
  | 'checkbox'
  | 'radio'
  | 'post'
  | 'box'
  | 'switch'
  | 'numbers';
interface IProps {
  title?: string;
  subTitle?: string;
  label?: string;
  type: string;
  success?: boolean;
  error?: boolean;
  disabled?: boolean;
  modelValue?:
    | string[]
    | {
        id: string;
        text: string;
      };
  options: Partial<IPostElement>[];
  max?: number;
  multi?: boolean;
  modelName?: string;
  prefix?: string;
  suffix?: string;
  shuffle?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
  title: '',
  subTitle: '',
  label: '',
  type: 'radio',
  success: false,
  error: false,
  disabled: false,
  modelName: '',
  max: 1,
  multi: false,
  modelValue: () => [],
  prefix: '',
  suffix: '',
  shuffle: false,
});

const emit = defineEmits(['update:modelValue']);
const answer = ref<any>(props.modelValue);

const options = computed(() => {
  return props.shuffle ? shuffleArray(props.options) : props.options;
});

watch(
  () => props.modelValue,
  () => {
    answer.value = props.modelValue;
  },
);

const getType = (type: string, multi: boolean) => {
  if (type == 'radio') {
    return 'radio';
  }
  if (type == 'checkbox' || type == 'switch') {
    return 'checkbox';
  }
  return multi ? 'checkbox' : 'radio';
};
const { getIsSelected, handleSelection, handleInput } = useSelect({
  type: getType(props.type, props.multi),
  modelName: props.modelName,
  answer,
  max: props.max,
  disabled: props.disabled,
  emit,
  emitEvent: 'update:modelValue',
});
</script>

<template>
  <FormControl :title="title" :sub-title="subTitle" :label="label">
    <div class="flex items-center gap-2">
      <slot name="prefix">
        <span
          v-if="prefix"
          class="text-sm 2xl:text-base 3xl:text-xl text-secondary-900 whitespace-nowrap flex items-center gap-2">
          <span>{{ prefix }}</span>
          <span class="relative top-1">👎</span>
        </span>
      </slot>

      <div
        v-if="['radio', 'checkbox', 'switch'].includes(type)"
        class="flex flex-col space-y-2 w-full">
        <SelectInput
          v-for="(input, index) in options"
          :key="get(input, 'id', index)"
          :model-name="modelName"
          :model-value="getIsSelected(get(input, 'id', ''))"
          :type="type"
          :is-disabled="disabled"
          :is-error="error"
          :class="{ 'bg-white': input.editable }"
          :label="get(input, 'title', '')"
          @input-selected="
            handleSelection(get(input, 'id', ''), input.editable)
          ">
          <input
            v-if="input.editable"
            :placeholder="input.title"
            :value="get(find(answer, { id: input.id }), 'text', '')"
            class="focus:outline-none focus:border-0 mr-3"
            @click="e => e.stopPropagation()"
            @input="
              (event: any) => handleInput(input?.id, event?.target?.value)
            "/>
        </SelectInput>
      </div>
      <div
        v-if="type === 'post'"
        class="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        <PostItem
          v-for="(post, index) in options"
          :key="get(post, 'id', index)"
          :item="post as any"
          :selected="getIsSelected(get(post, 'id', 0))"
          :disabled="disabled"
          :success="success"
          :error="error"
          @post-clicked="handleSelection(get(post, 'id', null))"></PostItem>
      </div>

      <div
        v-if="type === 'box'"
        class="flex flex-wrap justify-center items-center gap-2 w-full">
        <BoxItem
          v-for="box in options"
          :key="get(box, 'id', '')"
          :item="box"
          :disabled="disabled"
          :success="success"
          :error="error"
          :selected="getIsSelected(get(box, 'id', ''))"
          @update:selected="handleSelection(get(box, 'id', ''))"></BoxItem>
      </div>
      <div
        v-if="type === 'box-grid'"
        class="grid grid-cols-2 lg:grid-cols-6 gap-5 auto-rows-[1fr]">
        <QuestionnaireBoxItem
          v-for="box in options"
          :key="get(box, 'id', '')"
          :item="box"
          :disabled="disabled"
          :success="success"
          :error="error"
          :selected="getIsSelected(get(box, 'id', ''))"
          @update:selected="handleSelection(get(box, 'id', ''))"></QuestionnaireBoxItem>
      </div>
      <div
        v-if="type === 'box-number'"
        class="grid grid-cols-2 lg:flex lg:flex-row justify-center items-center gap-2 w-full">
        <NumbersBoxItem
          v-for="box in options"
          :key="get(box, 'id', '')"
          :item="box"
          :disabled="disabled"
          :success="success"
          :error="error"
          :selected="getIsSelected(get(box, 'id', ''))"
          @update:selected="handleSelection(get(box, 'id', ''))"></NumbersBoxItem>
      </div>
      <div
        v-if="type === 'small-box-number'"
        class="grid grid-cols-2 lg:flex lg:flex-row justify-center items-center gap-2 w-full">
        <SmallNumbersBoxItem
          v-for="box in options"
          :key="get(box, 'id', '')"
          :item="box"
          :disabled="disabled"
          :success="success"
          :error="error"
          :selected="getIsSelected(get(box, 'id', ''))"
          @update:selected="handleSelection(get(box, 'id', ''))"></SmallNumbersBoxItem>
      </div>
      <div
        v-if="type === 'horizontal-card'"
        class="grid grid-cols-2 lg:flex lg:flex-row justify-center items-center gap-2 w-full">
        <HorizontalCardItem
          v-for="box in options"
          :key="get(box, 'id', '')"
          :item="box"
          :disabled="disabled"
          :success="success"
          :error="error"
          :selected="getIsSelected(get(box, 'id', ''))"
          @update:selected="handleSelection(get(box, 'id', ''))"></HorizontalCardItem>
      </div>
      <div
        v-if="type === 'box-block'"
        class="grid grid-cols-2 lg:flex lg:flex-row justify-center items-center gap-6 lg:w-full">
        <BlockBoxItem
          v-for="box in options"
          :key="get(box, 'id', '')"
          :item="box"
          :disabled="disabled"
          :success="success"
          :error="error"
          :selected="getIsSelected(get(box, 'id', ''))"
          @update:selected="handleSelection(get(box, 'id', ''))"></BlockBoxItem>
      </div>
      <div
        v-if="type === 'range'"
        class="flex flex-wrap justify-center items-center gap-3.5 px-4">
        <RangeSelect
          v-for="range in options"
          :id="get(range, 'id', '')"
          :key="get(range, 'id', '')"
          :title="range.title"
          :disabled="disabled"
          :success="success"
          :error="error"
          :selected="getIsSelected(get(range, 'id', ''))"
          @update:selected="handleSelection(get(range, 'id', ''))"></RangeSelect>
      </div>

      <slot name="suffix">
        <span
          v-if="suffix"
          class="text-sm 2xl:text-base 3xl:text-xl text-secondary-900 whitespace-nowrap flex items-center gap-2">
          <span>{{ suffix }}</span>
          <span class="relative top-1">👍</span>
        </span>
      </slot>
    </div>
  </FormControl>
</template>
