import { addHours, areIntervalsOverlapping, format } from 'date-fns';
import { ComputedRef } from 'vue';
import { ICalendarEvent } from '@/types/interfaces';

interface IDraggableCalendar {
  schoolTimeEvents: ComputedRef<ICalendarEvent[]>;
  withNoSchoolTimeEvents: ComputedRef<ICalendarEvent[]>;
  deadlineEvent: ComputedRef<ICalendarEvent | undefined>;
}

export function useDraggableCalendar(props: IDraggableCalendar) {
  const isOverlapsAtSamePoint = (
    events: ICalendarEvent[],
    date: Date | string,
  ) => {
    return events.some(({ end, start }) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const { start: startEventDate, end: endEventDate } =
        extractStartAndEndDate(date);

      return areIntervalsOverlapping(
        {
          start: startDate,
          end: endDate,
        },
        {
          start: startEventDate,
          end: endEventDate,
        },
      );
    });
  };

  const extractStartAndEndDate = (date: Date | string) => {
    const start = new Date(date);
    const end = addHours(new Date(date), 1);
    return {
      start,
      end,
    };
  };

  const getConditionMessage = (
    date: Date | string,
  ): { message: string; buttons: string[] | undefined; allowed: boolean } => {
    const { isAfterDeadline, isNight, isSchoolTime, isOverlaps, isEarlyTime } =
      extractAlertConditions(date as Date);

    let message = '';
    let buttons;
    let allowed = true;
    if (isAfterDeadline) {
      message =
        'שיבצת את המשימה אחרי זמן השגת המטרה – אחרי המבחן אליו צריך להתכונן, \n נסה לשבץ אותה בזמן אחר';
      allowed = false;
    } else if (isOverlaps) {
      message = 'שיבצת את המשימה בזמן של משימה אחרת, נסה לשבץ אותה בזמן אח';
      allowed = false;
    } else if (isSchoolTime) {
      message =
        'שיבצת את המשימה בזמן בי"ס.\n' + 'האם תוכל באמת לבצע אותה בזמן הזה?';
      buttons = ['לא, אזיז את המשימה לזמן אחר', 'כן, חשבתי על זה'];
    } else if (isEarlyTime) {
      message =
        'שיבצת את המשימה בשעה מוקדמת בבוקר.\n' +
        'האם תוכל באמת לבצע אותה בזמן הזה?';
    } else if (isNight) {
      message =
        'שיבצת את המשימה בשעה מאוחרת. האם תוכל באמת לבצע אותה בזמן הזה?\n';
      buttons = ['לא, אזיז את המשימה לזמן אחר', 'כן, חשבתי על זה'];
    }

    return {
      message,
      buttons,
      allowed,
    };
  };

  const extractDate = (date: Date) => {
    return {
      day: Number(date.getDay()),
      hour: Number(format(date, 'kk')),
      endHour: Number(format(date, 'kk')) + 1,
    };
  };

  const isEventAfterDeadline = (date: Date) => {
    if (!props.deadlineEvent.value?.start) {
      return false;
    }

    const deadlineStart = new Date(props.deadlineEvent.value.start).getTime();
    return date.getTime() >= deadlineStart;
  };

  const extractAlertConditions = (date: Date) => {
    const { hour, endHour } = extractDate(date);
    const isNight = hour > 21 || endHour >= 21;
    const isEarlyTime = hour < 8;

    const isAfterDeadline = isEventAfterDeadline(date);

    const isSchoolTime = isOverlapsAtSamePoint(
      props.schoolTimeEvents.value,
      date,
    );
    const isOverlaps = isOverlapsAtSamePoint(
      props.withNoSchoolTimeEvents.value,
      date,
    );

    return {
      isAfterDeadline,
      isNight,
      isSchoolTime,
      isOverlaps,
      isEarlyTime,
    };
  };

  return {
    getConditionMessage,
  };
}
