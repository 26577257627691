<template>
  <div
    class="cursor-pointer border-[0.5px] rounded-xl h-[40px] w-[40px] flex items-center justify-center"
    :class="{
      'border-secondary-300 bg-secondary-75': !selected,
      'border-primary-700 bg-primary-200': selected,
    }"
    @click="handleClick">
    <span
      class="font-bold text-base"
      :class="{ 'text-secondary-700': !selected, 'text-primary-700': selected }">
      {{ title }}
    </span>
  </div>
</template>

<script lang="ts" setup>
interface IRangeSelect {
  id: string;
  disabled: boolean;
  selected: boolean;
  success: boolean;
  title?: string | number;
}
const props = withDefaults(defineProps<IRangeSelect>(), {
  disabled: false,
  error: false,
  selected: false,
  success: false,
  title: 1,
});

const emit = defineEmits(['update:selected']);

const handleClick = () => {
  if (!props.disabled) emit('update:selected');
};
</script>
