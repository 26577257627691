<template>
  <FormControl :title="title" :sub-title="subTitle">
    <div v-if="!showResults">
      <PollRange
        v-if="type === 'RANGE'"
        v-model="model"
        :options="options"
        :prefix="prefix"
        :suffix="suffix"/>
      <PollChoice v-if="type === 'CHOICE'" v-model="model" :options="options" />
      <PollCardsChoice
        v-if="type === 'CARD_CHOICE'"
        v-model="model"
        :options="options"/>
      <PollSupportCard
        v-if="type === 'SUPPORT_CHOICE'"
        v-model="model"
        :options="options"/>
    </div>

    <div v-else class="poll-result-wrapper">
      <BarsChart
        :bars-data="votesData"
        :horizontal="type === 'CHOICE'"
        :percentage-results="percentageResults"
        :amount-results="amountResults"/>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref, watch } from 'vue';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useVModel } from '@vueuse/core';
import PollRange from './PollRange.vue';
import PollChoice from './PollChoice.vue';
import PollCardsChoice from './PollCardsChoice.vue';
import PollSupportCard from './PollSupportCard.vue';
import type { PollOption, PollSummary } from './poll'; //'../types/poll.ts';
// import BarsChart from './BarsChart.vue';
import { BarsChart } from '@amit/components';

const props = defineProps({
  modelValue: {
    type: Array<String>,
    default: () => [],
  },
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  resultType: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<PollOption[]>,
    default: () => [],
  },
  prefix: {
    type: String,
    default: '',
  },
  suffix: {
    type: String,
    default: '',
  },
  percentageResults: {
    type: Boolean,
    default: false,
  },
  amountResults: {
    type: Boolean,
    default: true,
  },

  answers: {
    type: Array as PropType<PollSummary[]>,
    default: () => [],
  },
});

// const emit = defineEmits(['update', 'update:ModelValue']);
const model = useVModel(props, 'modelValue');
const votesData = ref<PollSummary[]>([]);
const showResults = computed<boolean>(() => {
  return props.answers.some(answer => answer.currentUserVote);
});

onMounted(() => {
  props.options.forEach((option: { id: string; title: string }) => {
    votesData.value.push({
      id: option.id,
      title: option.title,
      count: 0,
      currentUserVote: false,
    });
  });
});

watch(
  () => props.answers,
  newAnswers => {
    newAnswers.forEach(answer => {
      const vote = votesData.value.find(item => item.id === answer.id);
      if (vote) {
        vote.count = answer.count;
        vote.currentUserVote = answer.currentUserVote;
      }
    });
  },
);
</script>
