<template>
  <PVDialog
    v-if="isOpen"
    v-model:visible="isOpen"
    :draggable="false"
    :modal="true"
    :closable="true"
    :dismissable-mask="true"
    :style="{ width: '35rem' }"
    :pt="{
      content: {
        class: ['bg-yellow-500'],
        style: {
          boxShadow: 'inset 0 -2px 4px 0 rgba(0, 0, 0, 0.05)',
        },
      },
    }"
    @hide="emit('close')"
  >
    <template #header>
      <div class="text-secondary-975 font-simplerBold text-md">
        {{ headerText }}
      </div>
    </template>
    <div>
      <div v-if="editor" class="flex flex-col w-full post-editor-content">
        <BubbleMenu :editor="editor">
          <div class="bubble-menu">
            <TiptapMenu v-if="editor" :id="id" :editor="editor" />
          </div>
        </BubbleMenu>
        <TiptapContent v-if="editor" class="px-4 py-18" :editor="editor" />
      </div>
    </div>
    <template #footer>
      <PVButton
        :label="publishButtonText"
        severity="primary"
        class="bg-primary text-white"
        :loading="false"
        :disabled="publishButtonDisable"
        @click="publishPost"
      />
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { TiptapMenu, useEditor, TiptapContent } from '@amit/tiptap';
import { BubbleMenu } from '@tiptap/vue-3';
import { useVModel } from '@vueuse/core';
import PVButton from 'primevue/button';
import PVDialog from 'primevue/dialog';
import { containsRealText } from '../composables/utils';

import { computed, PropType, provide, ref, watch } from 'vue';
import { Post, PostData } from '../types/post';

const emit = defineEmits(['close', 'publish-new-post', 'publish-update-post']);

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
  postToEdit: {
    type: Object as PropType<Post | null>,
    default: null,
  },
});

const { getEditor, createEditor } = useEditor();
const isOpen = useVModel(props, 'open', emit, { eventName: 'close' });
const postText = ref(props.postToEdit ? props.postToEdit.title : '');
const postData = ref<PostData>({ title: '', text: '' });
const publishButtonText = ref(props.postToEdit ? 'עדכון' : 'פרסום פתק');
const headerText = ref(props.postToEdit ? 'יצירת פתק' : 'יצירת פתק');

createEditor({
  id: props.id,
  editable: true,
  model: postText,
  onUpdate: () => {
    if (!editor.value) return;
    const html = editor.value?.getHTML();
    postText.value = html;
  },
  extensions: {
    placeholder: { placeholder: 'כתוב פתק' },
    line_height: false,
    horizontal_rule: false,
    block_quote: false,
    image: false,
    image_resize: false,
    tooltip: false,
    link: false,
    background_color: false,
    color: false,
    table: false,
    indent: false,
    direction: false,
    text_align: false,
    text_type: false,
    font_size: false,
  },
});
const editor = getEditor(props.id);
provide('blockId', props.id);
watch(
  () => props.open,
  () => {
    if (props.open && props.postToEdit) {
      publishButtonText.value = 'עדכון';
      headerText.value = 'עריכת פתק';
      postText.value = props.postToEdit.title;
      editor.value?.commands.setContent(postText.value);
    } else if (!props.postToEdit) {
      postText.value = '';
      editor.value?.commands.setContent(postText.value);
      publishButtonText.value = 'פרסום פתק';
      headerText.value = 'יצירת פתק';
    }
  },
);

const publishPost = () => {
  props.postToEdit ? publishUpdatePost() : publishNewPost();
};

const publishNewPost = () => {
  postData.value.title = postText.value;
  postData.value.text = postText.value;
  emit('publish-new-post', postData.value);
  emit('close');
};

const publishUpdatePost = () => {
  postData.value.message_id = props.postToEdit?.message_id;
  postData.value.text = postText.value;
  postData.value.title = postText.value;
  emit('publish-update-post', postData.value);
  emit('close');
};

const publishButtonDisable = computed(() => {
  return (
    !containsRealText(postText.value) ||
    postText.value === props.postToEdit?.title
  );
});
</script>

<style scoped>
.border-footer {
  margin-left: -20px;
  margin-right: -20px;
}

.bubble-menu {
  display: flex;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 99999;
}
.bubble-menu > div {
  @apply border-0 py-2;
}
.post-editor-content {
  background-color: #fdf4d8;
  height: 400px;
  overflow: auto;
}
</style>
