<template>
  <FormControl :title="title" :sub-title="subtitle" class="w-full">
    <div class="container w-full mx-auto p-4 grid grid-cols-12">
      <ContentTable
        :headers="headers"
        :items="rowItems"
        theme="gray"
        display="flex">
        <template #cell="{ content, rowIndex, header }">
          <div v-if="header.key === 'title'">
            <div
              v-if="content.text"
              class="flex justify-left w-full font-simplerBold">
              {{ content.text }}
            </div>
          </div>
          <div
            v-for="(rowContent, index) in model[rowIndex][header.key]?.content"
            v-else
            :key="index">
            <textarea
              v-if="editable"
              v-model="rowContent.text"
              placeholder="כיצד מתקיים"
              class="h-30 text-black focus:ring-0 border-1 border-secondary-300 overflow-hidden w-full p-4 resize-none"></textarea>
            <div
              v-if="tickable"
              class="h-30 text-black focus:ring-0 border-1 border-secondary-300 overflow-hidden w-full p-3 pl-2 resize-none flex items-center justify-between">
              <div class="ml-2 break-words h-full">
                {{ content.text }}
              </div>
              <div
                class="cursor-pointer flex items-start align-top h-full"
                @click="toggleCheck(rowIndex, header.key, content.id)">
                <div
                  class="rounded-full p-0.5 w-6 h-6"
                  :class="{
                    'bg-green-600': rowContent.checked,
                    'bg-[#D7DFEA]': !rowContent.checked,
                  }">
                  <Check v-if="rowContent.checked" color="white" :size="20" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </ContentTable>
    </div>
  </FormControl>
</template>

<script lang="ts" setup>
import { watch, ref } from 'vue';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useVModel } from '@vueuse/core';
import { Check } from 'lucide-vue-next';
import ContentTable from '@/components/ContentTable/ContentTable.vue';
import { generateObjectId } from '@/utils';

const props = defineProps({
  modelValue: {
    type: Array as any,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  items: {
    type: Array as any,
    default: () => [],
  },
  headers: {
    type: Array as any,
    default: () => [],
  },
  editable: {
    type: Boolean,
    default: false,
  },
  tickable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);

const rowItems = ref([]);

const toggleCheck = (rowIndex, key, contentId) => {
  props.headers.forEach(header =>
    model.value[rowIndex][header.key].content.forEach(content => {
      content.checked =
        key === header.key && content.id === contentId && !content.checked;
    }),
  );
};

watch(
  () => props.modelValue,
  () => {
    rowItems.value = props.items.map((item, index) => {
      props.headers.forEach((header, headerIndex) => {
        item[header.key] = item[header.key] || {
          id: headerIndex,
          content: [{ id: generateObjectId(), text: '', checked: false }],
        };
      });
      if (!model.value[index]) {
        model.value[index] = item;
      }
      return item;
    });
  },
  { immediate: true },
);
</script>
