<template>
  <FormControl :title="title" :sub-title="subTitle">
    <div class="flex flex-col gap-4 mt-5">
      <div
        v-for="item in items"
        :key="item.id"
        class="rounded-xl bg-secondary-75 p-5">
        <div class="flex items-start justify-between gap-5">
          <div
            class="rounded-full w-15 h-15 bg-primary text-white font-anomaliaBold text-2xl flex items-center justify-center leading-none pb-1">
            {{ item.letter }}
          </div>
          <div class="grow">
            <p class="font-simplerBold mb-2">{{ item.caption }}</p>
            <p class="font-simplerRegular">{{ item.description }}</p>

            <div v-for="choice in item.choices" :key="choice.id" class="mt-5">
              <BaseInput
                v-model="model[choice.id]"
                :label="choice.text"
                placeholder="התשובה שלי"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FormControl>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import BaseInput from '@/components/Input/BaseInput.vue';

interface ISmart {
  id: string;
  caption: string;
  description: string;
  letter: string;
  choices: { id: string; text: string }[];
}

interface IProps {
  title?: string;
  subTitle?: string;
  items: ISmart[];
  modelValue: Record<string, string>;
}

const props = withDefaults(defineProps<IProps>(), {
  title: '',
  subTitle: '',
  items: () => [],
  modelValue: () => ({}),
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
</script>
