<template>
  <SafeTeleport to="#deck-activity" :disabled="display === 'relative'">
    <div
      ref="bannerRef"
      class="flex justify-end relative select-none max-w-[796px] xl:max-w-[902px] 2xl:max-w-[1120px]"
      :dir="rtl ? 'rtl' : 'ltr'">
      <TheCard slot-classes="flex gap-3 justify-between relative ">
        <div
          class="flex absolute bottom-6 left-5"
          :class="{ 'left-5': !rtl, 'right-5': rtl }">
          <button class="h-4 w-4 cursor-pointer" @click="toggleMaximized">
            <MaximizeIcon v-if="!isMaximized" class="h-4 w-4" />
            <MinimizeIcon v-else class="h-4 w-4" />
          </button>
        </div>
        <div
          class="flex items-center flex-col-reverse ml-10"
          :class="{ 'ml-10': !rtl, 'mr-10': rtl, 'gap-6': isMaximized }">
          <Transition name="bounce" appear :duration="{ enter: 0, leave: 1 }">
            <slot v-bind="{ minimized: !isMaximized }" />
          </Transition>
          <div class="flex-shrink-0 w-full draggable-element cursor-move">
            <p class="font-simplerBold text-sm 2xl:text-base">
              {{ text }} {{ isMaximized ? ':' : '' }}
            </p>
          </div>
        </div>
      </TheCard>
    </div>
  </SafeTeleport>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TheCard from '@/components/Card/TheCard.vue';
import {
  Maximize2 as MaximizeIcon,
  Minimize2 as MinimizeIcon,
} from 'lucide-vue-next';
import SafeTeleport from '@/apps/slideshow/teleport/SafeTeleport.vue';
import { useReveal } from '@/apps/slideshow/useReveal';
import { useEventListener } from '@vueuse/core';
enum LegendBankPosition {
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
}

interface IProps {
  text?: string;
  display?: LegendBankPosition;
}

const emit = defineEmits(['hidden']);

withDefaults(defineProps<IProps>(), {
  text: '',
  display: LegendBankPosition.ABSOLUTE,
});
const { rtl } = useReveal();
const bannerRef = ref(null);
const isMaximized = ref(true);
const toggleMaximized = () => {
  isMaximized.value = !isMaximized.value;
};

useEventListener(bannerRef, 'HideMe', () => {
  emit('hidden');
});
</script>
