<template>
  <BaseBank :text="getLocalizedText('word_bank')" @hidden="$emit('hidden')">
    <template #default="{ minimized }">
      <div
        v-if="!minimized"
        class="w-full flex gap-2 flex-wrap external-events max-h-[80px] xl:max-h-[90px] 2xl:max-h-[100px] overflow-y-auto">
        <VueDraggable
          v-model="model"
          :group="groupName"
          item-key="id"
          :sort="false"
          class="grow w-full flex flex-wrap gap-2 cursor-pointer"
          @remove="event => model.slice(event.oldIndex, 1)">
          <template #item="{ index }">
            <TagInput
              :title="getTitle(model[index])"
              :color="getColor(model[index])"
              class="h-[28px] xl:h-[38px] 2xl:h-[44px] max-w-full"
              :removable="false"/>
          </template>
        </VueDraggable>
      </div>
    </template>
  </BaseBank>
</template>

<script setup lang="ts">
import VueDraggable from 'vuedraggable';
import TagInput from '@/components/TagsInput/TagInput.vue';
import { useVModel } from '@vueuse/core';
import type { ITaskModel, ICalendarEvent } from '@/types/interfaces';
import BaseBank from '@/components/BaseBank/BaseBank.vue';
import { useReveal } from '@/apps/slideshow/useReveal';

interface IProps {
  modelValue: ITaskModel[] | ICalendarEvent[] | string[];
  groupName: string;
}
defineEmits(['hidden']);

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => [],
  groupName: '',
});
const { getLocalizedText } = useReveal();

const model = useVModel(props, 'modelValue');

const getTitle = element => {
  if (typeof element === 'object') {
    return element.title;
  }
  return element;
};
const getColor = element => {
  if (typeof element === 'object') {
    return element.label;
  }
  return 'blue';
};
</script>
