<script lang="ts" setup>
import type { IPostElement } from '@/types/interfaces';
import block from '@/assets/mapping-block.svg?url';
import selectedBlock from '@/assets/mapping-block-selected.svg?url';
import { ref } from 'vue';
import { useElementVisibility } from '@vueuse/core';

interface IBoxItem {
  disabled: boolean;
  error: boolean;
  selected: boolean;
  success: boolean;
  item: Partial<IPostElement>;
}
const props = withDefaults(defineProps<IBoxItem>(), {
  disabled: false,
  error: false,
  selected: false,
  success: false,
});
const target = ref();
const targetIsVisible = useElementVisibility(target);

const emit = defineEmits(['update:selected']);

const handleClick = () => {
  if (!props.disabled) emit('update:selected');
};
const getBlock = () => {
  if (props.selected) return selectedBlock;

  return block;
};
</script>

<template>
  <div
    ref="target"
    class="col-span-1 relative cursor-pointer"
    :tabindex="targetIsVisible ? 0 : -1"
    role="button"
    @click="handleClick"
    @keyup.enter="handleClick">
    <img :src="getBlock()" alt="background" />
    <div>
      <div
        v-if="item.title"
        class="absolute h-full top-0 w-full flex items-center justify-center text-center text-white font-anomaliaBold p-2">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
