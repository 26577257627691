<template>
  <BaseInput
    v-model="localSearchTerm"
    type="search"
    :placeholder="t('blocksSettings.image.search')"
    @keydown.enter.prevent="searchPhotos">
    <template #prefix>
      <SearchIcon :size="16" class="search" />
    </template>
  </BaseInput>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import BaseInput from '@/components/Input/BaseInput.vue';
import { createApi } from 'unsplash-js';
import { Search as SearchIcon } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['update-photos']);

const localSearchTerm = ref('');

const unsplash = createApi({
  accessKey: '0HAU2LH1rJVLGArxuiw7Ic2lpY2aVqCcmd3rxXNDvkA',
});

onMounted(() => searchPhotos());

const searchPhotos = () => {
  unsplash.search
    .getPhotos({ page: 1, query: localSearchTerm.value || 'school' })
    .then(result => {
      if (result.errors) {
        console.log('error occurred: ', result.errors[0]);
      } else {
        emit('update-photos', result.response.results);
      }
    });
};
</script>
