import axios from 'axios';

export function useFileUpload() {
  const unsignedUploadPreset = 'r6mwkrzq';
  const cloudName = 'dcodkxpej';
  const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloudName}/`;
  const uploadFile = async ({ file }) => {
    const formData = new FormData();
    formData.append('upload_preset', unsignedUploadPreset);
    formData.append('file', file);
    formData.append('folder', 'Amit/Files');

    try {
      const response = await axios.post(`${cloudinaryUrl}upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  return {
    uploadFile,
  };
}
