<template>
  <div class="flex items-center gap-2 font-simplerBold" :class="color">
    <div
      v-if="hideCompletion"
      class="w-8.5 h-8.5 bg-primary rounded-full border-4 border-white outline outline-primary flex items-center justify-center">
      <CheckIcon :size="14" class="stroke-white stroke-4" />
    </div>
    <div v-else class="w-10 relative flex items-center justify-center">
      <ScoreBorderIcon class="absolute" :class="fill" />
      <span>{{ score }}</span>
    </div>
    <div>{{ message }}</div>
  </div>
</template>

<script setup lang="ts">
import { FeedbackType } from '@/components/FeedbackAlert/types';
import { PropType, computed } from 'vue';
import ScoreBorderIcon from '@/assets/scoreBorder.svg?component';
import { Check as CheckIcon } from 'lucide-vue-next';

const colors = {
  failed: {
    color: 'text-red-600',
    fill: 'fill-red-600',
  },
  partial: {
    color: 'text-red-600',
    fill: 'fill-red-600',
  },
  decent: {
    color: 'text-orange-600',
    fill: 'fill-orange-600',
  },
  good: {
    color: 'text-green-600',
    fill: 'fill-green-600',
  },
  great: {
    color: 'text-green-600',
    fill: 'fill-green-600',
  },
  perfect: {
    color: 'text-green-600',
    fill: 'fill-green-600',
  },
};

const props = defineProps({
  type: {
    type: String as PropType<FeedbackType>,
    default: 'perfect',
  },
  score: {
    type: Number,
    default: 0,
  },
  message: {
    type: String,
    default: '',
  },
  hideCompletion: {
    type: Boolean,
    default: false,
  },
});

const color = computed(() =>
  props.hideCompletion ? 'text-primary' : colors[props.type]?.color,
);
const fill = computed(() => colors[props.type]?.fill);
</script>
