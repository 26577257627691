<template>
  <div v-if="!filteredMonths.length" class="flex justify-start w-40/100">
    <ChoiceGroup
      v-model="model.deadline"
      title="בחרו דד ליין - באיזה חודש תשיגו את המטרה?"
      type="radio"
      :options="displayedMonths"/>
  </div>
  <FormControl v-else :title="title" :sub-title="subTitle">
    <div class="flex flex-col space-y-3">
      <div class="flex">
        <span class="w-10/100 text-sm font-simplerBold">חודש</span>
        <span class="w-10/100 text-sm font-simplerBold">שבוע</span>
        <span class="w-55/100 text-sm font-simplerBold">פעולות</span>
        <span v-if="estimation" class="w-20/100 text-sm font-simplerBold">
          שעות
        </span>
      </div>
      <div
        v-for="(month, monthIndex) in filteredMonths"
        :key="monthIndex"
        class="flex w-full">
        <div class="flex flex-col w-full gap-2">
          <div class="flex w-full gap-2">
            <div class="text-sm w-20 bg-secondary-75 rounded-lg pr-3 pt-6">
              {{ month.title }} {{ new Date().getFullYear() }}
            </div>
            <div class="flex w-full gap-2">
              <div class="w-full space-y-2">
                <div
                  v-for="(week, weekIndex) in weeks"
                  :key="weekIndex"
                  class="flex gap-2 w-full">
                  <div
                    class="bg-secondary-75 w-10/100 rounded-lg flex p-2 text-sm">
                    {{ week }}
                  </div>
                  <div class="flex flex-col gap-2 w-full">
                    <VueDraggable
                      v-model="gantt[month.id][week]"
                      :disabled="!droppable"
                      group="answers"
                      item-key="id"
                      :sort="false"
                      class="min-h-10 w-full flex flex-col gap-2"
                      @add="handleDragAdd(month.id, week, $event)">
                      <template #item="{ element }">
                        <div class="flex gap-2">
                          <div
                            v-if="element.title"
                            class="flex items-center gap-2 h-12 w-68/100 rounded-lg bg-primary-100 border border-primary px-2">
                            <Menu class="h-4 w-4" />
                            <span>{{ element.title }}</span>
                          </div>
                          <div
                            v-if="estimation"
                            class="flex w-33/100 gap-2 bg-secondary-75 rounded-lg">
                            <input
                              :value="
                                element.duration
                                  ? element.duration / 60
                                  : (element.duration = 0)
                              "
                              type="number"
                              class="w-full bg-transparent focus:ring-0 border-none h-12 px-3"
                              @input="updateTaskDuration(element, $event)"/>
                            <div class="relative cursor-pointer pl-2">
                              <ChevronUp
                                class="absolute stroke-1 top-1.5 left-2"
                                @click="
                                  updateTaskDuration(element, null, 'increment')
                                "/>
                              <ChevronDown
                                v-if="element.duration > 0"
                                class="absolute stroke-1 bottom-1.5 left-2"
                                @click="
                                  updateTaskDuration(element, null, 'decrement')
                                "/>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template #footer>
                        <div v-if="estimation" class="flex w-full gap-2">
                          <div
                            class="flex items-center gap-2 h-12 w-68/100 rounded-lg border-2 border-dashed border-secondary-300 bg-secondary-75 px-2 text-secondary-900 text-sm">
                            <Plus class="stroke-secondary-900 h-4 w-4" />
                            ניתן לגרור את הפעולה לכאן
                          </div>
                          <div
                            class="flex items-center px-3 gap-2 h-12 w-33/100 bg-secondary-75 rounded-lg">
                            0
                          </div>
                        </div>
                      </template>
                    </VueDraggable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BankBanner
      v-if="droppable"
      v-model="tasksRef"
      group-name="answers"
      class="bank-button"/>
  </FormControl>
</template>

<script setup lang="ts">
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import BankBanner from '@/components/BankBanner/BankBanner.vue';
import { useVModel } from '@vueuse/core';
import { ref, watch, computed, onMounted, watchEffect } from 'vue';
import { ChevronUp, ChevronDown, Plus, Menu } from 'lucide-vue-next';
import ChoiceGroup from '@/components/ChoiceGroup/ChoiceGroup.vue';
import VueDraggable from 'vuedraggable';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  tasks: {
    type: Array as any,
    default: () => [],
  },
  estimation: {
    type: Boolean,
    default: true,
  },
  droppable: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Object as any,
    default: () => ({}),
  },
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
const tasksRef = ref();
const displayedMonths = ref<any>([]);
const weeks = [1, 2, 3, 4];
const gantt = ref<any>([]);

watchEffect(() => {
  if (!model.value?.tasks) return;
  tasksRef.value = props.tasks.filter(
    task => !model.value.tasks.some(t => t.id === task.id),
  );
});
const months = [
  { id: 1, title: 'ינואר' },
  { id: 2, title: 'פבואר' },
  { id: 3, title: 'מרץ' },
  { id: 4, title: 'אפריל' },
  { id: 5, title: 'מאי' },
  { id: 6, title: 'יוני' },
  { id: 7, title: 'יולי' },
  { id: 8, title: 'אוגוסט' },
  { id: 9, title: 'ספטמבר' },
  { id: 10, title: 'אוקטובר' },
  { id: 11, title: 'נובמבר' },
  { id: 12, title: 'דצמבר' },
];

const currentMonth = new Date().getMonth();

const filteredMonths = computed(() => {
  if (!props.droppable && props.tasks.length) {
    const tasksMonth = props.tasks.flatMap(task => task.month);
    return months.filter(month => tasksMonth.includes(month.id));
  }
  if (model.value?.deadline) {
    const selectedMonth = model.value.deadline[0] % 12;
    if (currentMonth <= selectedMonth) {
      return months.slice(currentMonth, selectedMonth);
    } else {
      return months.slice(currentMonth).concat(months.slice(0, selectedMonth));
    }
  } else {
    return [];
  }
});

onMounted(() => {
  for (let i = 0; i < 4; i++) {
    const index = (currentMonth + i) % 12;
    displayedMonths.value.push(months[index] as { id: number; title: string });
  }
});

const handleDragAdd = (month, week, event) => {
  const element = event.item.__draggable_context.element;
  const existingTaskIndex = model.value.tasks.findIndex(
    task => task.id === element.id,
  );
  if (existingTaskIndex !== -1) {
    model.value.tasks.splice(existingTaskIndex, 1);
  }
  model.value.tasks.push({
    week,
    id: element.id,
    duration: 0,
    title: element.title,
    month,
  });
};

const updateTaskDuration = (element, value, action = '') => {
  const currentTask = model.value.tasks.find(task => task.id === element.id);
  if (value) {
    currentTask.duration = value.data * 60;
  } else if (action === 'increment') {
    currentTask.duration += 60;
    element.duration += 60;
  } else if (action === 'decrement') {
    currentTask.duration -= 60;
    element.duration -= 60;
  }
};

const initGantt = () => {
  filteredMonths.value.forEach(month => {
    weeks.forEach(week => {
      if (!gantt.value[month.id]) {
        gantt.value[month.id] = [];
      }
      if (!gantt.value[month.id][week]) {
        gantt.value[month.id][week] = [];
      }
    });
  });
};

watch(
  () => props.tasks,
  () => {
    if (props.droppable) {
      return;
    }
    initGantt();
    props.tasks.forEach(task => {
      if (!gantt.value[task.month]) {
        gantt.value[task.month] = [];
      }
      if (!gantt.value[task.month][task.week]) {
        gantt.value[task.month][task.week] = [];
      }
      gantt.value[task.month][task.week].push({
        week: task.week,
        id: task.id,
        duration: task.duration,
        title: task.title,
        month: task.month,
      });
    });
  },
  { immediate: true },
);

watch(
  () => model.value.deadline,
  () => {
    initGantt();
  },
);

watch(
  () => props.modelValue,
  () => {
    if (model.value.deadline) {
      gantt.value = {};
      initGantt();
      model.value.tasks.forEach(task => {
        if (task.title) {
          gantt.value[task.month][task.week].push({
            week: task.week,
            id: task.id,
            duration: task.duration,
            title: task.title,
            month: task.month,
          });
        }
      });
    }
  },
  { immediate: true },
);
</script>
