<template>
  <FormControl :title="title" :sub-title="subTitle">
    <span class="font-simplerBold">{{ description }}</span>
    <ContentTable :headers="headers" :items="items" :display="display">
      <template #cell="{ content, rowIndex, header }">
        <div
          v-if="content.text"
          class="bg-primary-200 border border-primary w-full rounded-lg p-1 text-xs p-2">
          {{ content.text }}
        </div>
        <div
          v-if="content.droppable"
          class="w-full rounded-xl bg-secondary-75 border-dashed border-2 flex items-center justify-center p-2">
          <VueDraggable
            v-model="model[rowIndex][header.key]"
            group="answers"
            item-key="id"
            :sort="false"
            class="drag-box w-full flex flex-col gap-2 overflow-y-auto scroll"
            :class="display === 'block' ? 'h-30' : 'min-h-10'"
            @add="emit('add')">
            <template #item="{ index }">
              <div
                class="item rounded-lg flex flex-col items-stretch bg-primary-200 border border-primary text-xs p-2">
                {{ model[rowIndex][header.key][index].title }}
              </div>
            </template>
            <template #footer>
              <div
                v-if="!model[rowIndex][header.key]?.length"
                class="flex w-full items-center justify-center"
                :class="display === 'block' ? 'h-30' : 'min-h-10'">
                <Plus class="stroke-secondary-900 h-4 w-4" />
              </div>
            </template>
          </VueDraggable>
        </div>
      </template>
    </ContentTable>
    <div class="bank-button sticky left-2 right-2 bottom-0 shadow-box-light">
      <BankBanner v-model="optionsRef" group-name="answers" />
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import BankBanner from '@/components/BankBanner/BankBanner.vue';
import { ref, watch, watchEffect } from 'vue';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import ContentTable from '@/components/ContentTable/ContentTable.vue';
import VueDraggable from 'vuedraggable';
import { Plus } from 'lucide-vue-next';
import type { ITagTable } from '@/types/interfaces';
import type { PropType } from 'vue';

const emit = defineEmits(['update:modelValue', 'add']);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  headers: {
    type: Array as any,
    required: true,
  },
  items: {
    type: Array as any,
    required: true,
  },
  options: {
    type: Array as any,
    default: () => [],
  },
  display: {
    type: String,
    default: 'flex',
  },
  description: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array as PropType<ITagTable[]>,
    default: () => [],
  },
});

const optionsRef = ref();
const model = useVModel(props, 'modelValue', emit);

watchEffect(() => {
  const modelValueIds = props.modelValue.flatMap(row => {
    return props.headers.flatMap(header => {
      return row[header.key].flatMap(item => item.id);
    });
  });
  optionsRef.value = props.options.filter(
    option => !modelValueIds.includes(option.id),
  );
});

watch(
  () => props.modelValue,
  () => {
    props.items?.forEach((_, index) => {
      if (!model.value[index]) {
        model.value[index] = {
          id: index,
        };
        props.headers.forEach(header => {
          model.value[index][header.key] = [];
        });
      }
    });
  },
  { immediate: true },
);
</script>

<style scoped>
.scroll::-webkit-scrollbar-track {
  @apply bg-secondary-75;
}
.scroll::-webkit-scrollbar-thumb {
  @apply bg-clip-content border-solid border-3 border-transparent;
}
.scroll::-webkit-scrollbar {
  @apply w-5;
}
</style>
