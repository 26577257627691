<template>
  <div v-if="!isPreview" class="flex flex-wrap gap-3">
    <div
      v-for="(option, index) in options"
      :key="option.id"
      class="flex justify-center gap-2">
      <PollCard
        :option="option"
        :is-active="false"
        @title-changed="title => (option.title = title)"
        @image-changed="image => (option.image = image)"/>
      <XCircle
        v-if="options.length > 2"
        class="stroke-primary hover:cursor-pointer h-4 w-4"
        @click="$emit('delete-option', index)"/>
    </div>

    <div
      v-if="options.length < 5"
      style="height: 150px"
      class="flex items-center h-35 w-40 justify-center cursor-pointer gap-2 rounded-lg border-1 border-solid border-primary hover:bg-secondary-100"
      @click="$emit('add-option')">
      <Plus class="stroke-primary h-3.5 w-3.5" />
      <span class="text-sm text-primary-600">
        {{ t('blocksSettings.choice.more_option') }}
      </span>
    </div>
  </div>

  <div v-else class="flex flex-wrap gap-3 w-full">
    <div v-for="option in options" :key="option.id" class="flex justify-center">
      <Card
        style="overflow: hidden; box-shadow: none"
        class="p-4 bg-secondary-75 hover:cursor-pointer w-36 h-40 border-none"
        :class="{
          '!bg-primary-200 border-1 border-primary': model[0] === option.id,
          'flex justify-center': !option.title || !option.image,
        }"
        :pt="{
          body: {
            class: ['mt-2'],
          },
        }"
        @click="model = [option.id]">
        <template #header>
          <img
            v-if="option.image"
            :src="option.image"
            alt="pair_match_card"
            class="w-full object-contain h-18"/>
        </template>

        <template #content>
          <p
            v-if="option.title"
            class="text-secondary-900 m-0 flex justify-center line-clamp-2 text-center"
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            "
            :style="{
              '-webkit-line-clamp': option.image ? '2' : '5',
            }"
            :title="option.title">
            {{ option.title }}
          </p>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import PollCard from './PollCard.vue';
import { XCircle, Plus } from 'lucide-vue-next';
import Card from 'primevue/card';
import { useI18n } from 'vue-i18n';
import type { PollOption } from '../types/poll.ts';
import { useVModel } from '@vueuse/core';

const props = defineProps({
  type: {
    type: String,
    default: 'radio',
  },
  options: {
    type: Array as PropType<PollOption[]>,
    default: () => [],
  },
  isPreview: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Array<String>,
    default: () => [],
  },
  addOptionEnable: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
defineEmits(['add-option', 'delete-option']);

const model = useVModel(props, 'modelValue');
</script>
