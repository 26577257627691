<script lang="ts" setup>
interface IProps {
  type?: string;
  text?: string;
  link?: string;
}
const props = withDefaults(defineProps<IProps>(), {
  type: 'page | external',
  text: '',
  link: '',
});
const emit = defineEmits(['jump-page']);

const handleClick = () => {
  if (props.type === 'page') {
    emit('jump-page', { page: props.link });
    return;
  }
  if (props.type === 'external') {
    window.open(props.link, '_blank');
  }
};
</script>

<template>
  <div
    class="w-full text-start text-primary font-simplerBold cursor-pointer"
    @click="handleClick">
    {{ text }}
  </div>
</template>
