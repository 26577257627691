<template>
  <Transition
    enter-active-class="transition ease-in-out duration-700 opacity"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in-out duration-100 opacity"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0">
    <div v-if="showPersona">
      <div class="mt-5">
        <PersonasMenuSelection
          :personas="personas"
          :videos="videos"
          :hide-select-persona="!selectable"
          :display-persona="showPersona"
          :model-value="selectedPersona"
          @update:model-value="updateValue"
          @video-next-enabled="$emit('video-next-enabled')"/>
      </div>
    </div>

    <div
      v-else
      class="h-full w-full flex flex-col justify-between lg:justify-start">
      <div
        class="flex flex-col lg:flex-row justify-center flex-grow py-3 lg:py-5 space-y-3 lg:space-y-0 lg:gap-x-3">
        <label
          v-for="{ id, name, description, avatar } in personas"
          :key="id"
          class="p-3 lg:p-5 flex flex-col space-y-2 rounded-3xl shadow-solid w-full lg:w-33/100 max-w-100 cursor-pointer justify-between">
          <div class="flex items-center justify-start w-full gap-2">
            <BigSelectedIcon v-if="selectedPersona === id" />
            <span
              class="font-simplerBold text-lg text-dark gap-3"
              :class="{ 'text-primary': selectedPersona === id }">
              {{ name }}
            </span>
          </div>
          <div class="flex flex-col justify-between grow">
            <span class="flex font-simplerRegular text-secondary-900 leading-6">
              {{ description }}
            </span>
            <div class="relative">
              <img
                class="rounded-lg w-full overflow-hidden"
                :src="avatar"
                alt="Coach image"/>
              <div
                class="absolute top-0 flex items-center justify-center w-full h-full bg-imgFound rounded-lg"
                @click="() => setShowPersona(id)">
                <Play
                  class="stroke-white border-2 border-white rounded-full h-10 w-10 p-2"/>
              </div>
            </div>
          </div>
          <BaseButton
            class="flex justify-center w-full py-2 bg-white border border-primary rounded-lg text-primary font-simplerBold"
            color="blue"
            :class="{ '!bg-primary-200': selectedPersona === id }"
            @click="() => setShowPersona(id)">
            <span v-if="selectedPersona === id" class="text-primary">
              בחרת ב- {{ name }}
            </span>
            <span v-else class="text-primary">בחירה {{ name }}</span>
          </BaseButton>
        </label>
      </div>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import { ref, computed, watchEffect, PropType } from 'vue';
import { BaseButton } from '@/components';
import { Play } from 'lucide-vue-next';
import BigSelectedIcon from '@/assets/bigSelectedIcon.svg';
import PersonasMenuSelection from '@/components/PersonasSelection/PersonasMenuSelection.vue';
import { IPersona, IVideo } from '@/types/interfaces';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  personas: {
    type: Array as PropType<IPersona[]>,
    default: () => [],
  },
  videos: {
    type: Array as PropType<IVideo[]>,
    default: () => [],
  },
  selectable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue', 'video-next-enabled']);

const selectedPersona = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
});

const showPersona = ref();

const setShowPersona = personaId => {
  showPersona.value = personaId;
};
const updateValue = value => {
  selectedPersona.value = value;
};

watchEffect(() => {
  if (!props.selectable) {
    setShowPersona(selectedPersona.value);
  }
});
</script>
