<script setup lang="ts">
type PlacementType = 'center' | 'start' | 'end';

const placementClasses: Record<PlacementType, string> = {
  center: 'items-center',
  start: 'items-start',
  end: 'items-end',
};

interface IProps {
  title?: string;
  subtitle?: string;
  placement?: PlacementType;
}

const props = withDefaults(defineProps<IProps>(), {
  title: '',
  subtitle: '',
  placement: 'start',
});
</script>

<template>
  <div
    class="w-full flex flex-col gap-5"
    :class="placementClasses[props.placement]">
    <h1 v-if="props.title">
      {{ props.title }}
    </h1>
    <p v-if="props.subtitle" class="whitespace-pre-line">
      {{ props.subtitle }}
    </p>
  </div>
</template>
