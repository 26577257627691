import type { ThemeVariant } from '@/types/types';
import classNames from 'classnames';

const defaultClasses =
  'border-1 rounded-4 p-1 min-h-10 mb-3 flex flex-col items-stretch h-full';
const eventColorClasses: Record<ThemeVariant, string> = {
  none: '',
  default: 'bg-secondary-200',
  blue: 'bg-blue-100 border-primary',
  alternative: '',
  dark: 'bg-secondary-100 border-default',
  light: 'bg-white border-default',
  green: 'bg-green-100 border-green-600',
  red: 'bg-red-100  border-red-600',
  yellow: 'bg-orange-100  border-orange-600',
  purple: 'bg-purple-100 border-purple-600',
  pink: 'bg-pink-100  border-pink-600',
};

const defaultTimeClasses =
  'flex items-center justify-center px-2 text-xxs rounded-full';
const TimeColorClasses: Record<ThemeVariant, string> = {
  none: '',
  default: 'bg-secondary-200',
  blue: 'bg-primary text-white',
  alternative: '',
  dark: 'bg-default text-secondary-100',
  light: 'bg-white border-default',
  green: 'bg-green-600 text-green-100',
  red: 'bg-red-600  text-red-100',
  yellow: 'bg-orange-600  text-orange-100',
  purple: 'bg-purple-600 text-purple-100',
  pink: 'bg-pink-600  text-pink-100',
};

export function useDraggableCalendarClasses(): {
  getEventColors: Function;
  getTimeColor: Function;
  getTagColor: Function;
} {
  const getEventColors = (color: string) => {
    return classNames(defaultClasses, eventColorClasses[color]);
  };

  const getTimeColor = (color: string) => {
    return classNames(defaultTimeClasses, TimeColorClasses[color]);
  };

  const getTagColor = (color: string) => {
    return classNames('border', eventColorClasses[color]);
  };
  return {
    getEventColors,
    getTimeColor,
    getTagColor,
  };
}
