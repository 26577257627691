import type { TagType } from '../types';
import { computed } from 'vue';
import type { Ref } from 'vue';
import classNames from 'classnames';

const defaultTagClasses =
  'px-2.5 py-1 rounded flex items-center justify-center rounded text-secondary-900 text-sm';

const tagTypeClasses: Record<TagType, string> = {
  none: '',
  alternative: 'bg-default/80 text-white border border-secondary-100',
  light: 'bg-white border border-default',
  default: 'bg-primary-200 border border-primary dark:bg-blue-200',
  blue: 'bg-primary-100 border border-primary dark:bg-blue-200',
  dark: 'bg-gray-100 border border-gray-200 dark:bg-gray-700',
  red: 'bg-red-100 border border-red-200 dark:bg-red-200',
  green: 'bg-green-100 border border-green-200 dark:bg-green-200',
  yellow: 'bg-yellow-100 border border-yellow-200 dark:bg-yellow-200',
  purple: 'bg-purple-100 border border-purple-200 dark:bg-purple-200',
  pink: 'bg-pink-100 border border-pink-200 dark:bg-pink-200',
};

export function useTagInputClasses(color: string): {
  tagClasses: Ref<string>;
} {
  const tagClasses = computed<string>(() => {
    return classNames(defaultTagClasses, tagTypeClasses[color]);
  });
  return {
    tagClasses,
  };
}
