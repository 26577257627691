<script lang="ts" setup>
import type { PropType } from 'vue';
import type { IPostElement } from '@/types/interfaces';
import { Heart, MessageCircle, Send, Bookmark } from 'lucide-vue-next';

const props = defineProps({
  disabled: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
  selected: { type: Boolean, default: false },
  success: { type: Boolean, default: false },
  item: { type: Object as PropType<IPostElement>, default: () => ({}) },
});

const emit = defineEmits(['post-clicked']);
const handleClick = () => {
  if (!props.disabled) emit('post-clicked');
};
</script>

<template>
  <div
    class="rounded-xl p-5 text-center border"
    :class="{
      'cursor-pointer': !selected && !error && !success && !disabled,
      'border bg-primary-200 border-primary': selected && !success && !error,
      'border border-error bg-error-light': error && selected,
      'border-success bg-success-light': success && selected,
      'border-borders-hover bg-backgrounds-disabled cursor-not-allowed':
        disabled,
    }"
    @click="handleClick">
    <div class="flex items-center justify-start gap-2">
      <img class="w-10 h-10 rounded-full" :src="item.icon" :alt="item.title" />
      <span class="label font-simplerBold">{{ item.title }}</span>
    </div>
    <img
      v-if="item.img"
      class="w-full object-cover max-h-52 rounded-lg my-2"
      :src="item.img"
      alt="option image"/>

    <div class="w-full flex flex-col items-start pt-2">
      <div class="flex items-center justify-between w-full">
        <Bookmark class="stroke-1 h-5" />
        <div class="flex">
          <Send class="stroke-1 h-5" />
          <MessageCircle class="stroke-1 h-5" />
          <Heart class="stroke-1 h-5" />
        </div>
      </div>
      <p
        v-if="item.description"
        class="my-4 text-justify text-secondary-900 leading-6">
        {{ item.description }}
      </p>
      <div class="flex gap-2">
        <span v-for="tag in item.tags" :key="tag" class="text-primary">
          {{ tag }}
        </span>
      </div>
    </div>
  </div>
</template>
