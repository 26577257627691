<template>
  <div class="flex flex-col w-full space-y-3">
    <span class="font-simplerBold whitespace-pre-line">{{ title }}</span>
    <div class="flex items-center">
      <div>
        <canvas ref="canvas" :data="data" :width="width" :height="height" />
      </div>
      <div class="p-5 flex">
        <div class="flex flex-col flex-wrap h-45">
          <BaseButton
            v-for="(label, index) in data?.labels"
            :key="index"
            size="sm"
            color="none"
            @click="labelClicked(label, index)">
            <div
              v-if="label"
              class="border border-secondary-300 rounded-sm p-2 flex items-center gap-1">
              <div
                class="h-3 w-3 rounded-sm"
                :style="{ background: getColor(index) }"></div>
              <span class="text-xs">{{ label }}</span>
            </div>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue';
import type { PropType } from 'vue';
import { BaseButton } from '@/components';
import { get } from 'lodash';
import {
  Chart,
  DoughnutController,
  ArcElement,
  TimeScale,
  Tooltip,
  Colors,
} from 'chart.js';

import type { ChartData, ChartOptions } from 'chart.js';
Chart.register(DoughnutController, ArcElement, TimeScale, Tooltip, Colors);

const props = defineProps({
  data: {
    type: Object as PropType<ChartData>,
    default: () => {},
  },
  title: {
    type: String,
    default: '',
  },
  width: {
    type: Number,
    default: 166,
  },
  height: {
    type: Number,
    default: 166,
  },
});

const canvas = ref();
let chart;

const labelClicked = (item, index) => {
  chart.toggleDataVisibility(index, !item.index);
  chart.update();
};

const getColor = index => {
  return get(props.data, `datasets.0.backgroundColor.${index}`);
};

onMounted(() => {
  initChart();
});

watch(
  () => props.data?.labels,
  () => {
    if (chart) chart.destroy();
    initChart();
  },
);

const initChart = () => {
  const ctx = canvas.value;

  /* @ts-ignore */
  const options: ChartOptions = {
    /* @ts-ignore */
    cutout: '80%',
    layout: {
      padding: 4,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    animation: {
      duration: 500,
    },
    maintainAspectRatio: false,
    resizeDelay: 200,
  };
  chart = new Chart(ctx, {
    type: 'doughnut',
    data: props.data,
    options,
  });
};
onUnmounted(() => chart.destroy());
</script>
