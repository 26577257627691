import { ref, watch, Ref } from 'vue';
import axios from 'axios';
import SendbirdChat, { ChannelType } from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelModule,
  MessageCollectionInitPolicy,
  MessageFilter,
} from '@sendbird/chat/groupChannel';
import type {
  BaseMessage,
  UserMessage,
  FileMessage,
  UserMessageCreateParams,
} from '@sendbird/chat/message';
import { sendbirdUtils } from './utils';
import { Post } from '../types/post';

const { formatPostMessage, sortedByDate, cloudinaryParams } = sendbirdUtils;

export function useSendbird(
  appId: string,
  token: string,
  userId: string,
  messageHandlers,
  onApiResult,
) {
  const BASE_URL = `https://api-${appId}.sendbird.com/v3`;

  let sbConfig = {
    headers: {
      'Api-Token': token,
    },
  };

  const connected = ref(false);
  const postsStore = ref([]);
  const currentlyShownChannelPosts = ref(null);

  const sb = ref(
    SendbirdChat.init({
      appId,
      localCacheEnabled: false,
      modules: [new GroupChannelModule()],
    }),
  );

  const login = async userId => {
    await axios
      .post(`${BASE_URL}/users/${userId}/token`, {}, sbConfig)
      .then(async res => {
        try {
          await sb.value.connect(userId, res.data.token);
          connected.value = true;
        } catch (err) {
          console.error(err);
        }
      })
      .catch(async err => {
        console.error(err);
      });
  };

  const retrieveChannel = async (channelUrl: string) => {
    try {
      if (!connected.value) return;
      return await sb.value.groupChannel.getChannel(channelUrl);
    } catch (err) {
      console.error(err);
    }
  };

  const getMessage = async (messageId: number, channelUrl: string) => {
    const message = await sb.value.message.getMessage({
      channelUrl: channelUrl,
      messageId: messageId,
      channelType: ChannelType.GROUP,
    });
    return message;
  };

  const retrieveMessagesForChannel = async (
    channel: GroupChannel,
    messageHandlers,
    onCacheResult,
    onApiResult,
  ) => {
    try {
      const messageFilter = new MessageFilter();
      const collection = channel.createMessageCollection({
        filter: messageFilter,
        startingPoint: Date.now(),
        limit: 200,
      });

      collection.setMessageCollectionHandler(messageHandlers);
      await collection
        .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
        .onCacheResult(onCacheResult)
        .onApiResult(onApiResult);

      const loadNext = async () => {
        if (collection.hasNext) {
          return await collection.loadNext();
        }
        return [];
      };

      return {
        collection,
        loadNext,
      };
    } catch (e) {
      console.error(e);
    }
  };

  const sendMessageToChannel = async (
    channel,
    message,
    options = {},
    isPost = false,
    posts: Ref<Array<Post>>,
    parentMessageId: number | null = null,
  ) => {
    const params: UserMessageCreateParams = {
      message,
      data: JSON.stringify(options),
    };
    if (isPost) {
      params.customType = 'post';
    }
    if (parentMessageId) {
      params.parentMessageId = parentMessageId;
    }
    channel.sendUserMessage(params).onSucceeded((message: UserMessage) => {
      if (
        posts.value?.length &&
        !parentMessageId &&
        message.customType === 'post'
      ) {
        const formattedMessage = formatPostMessage(message);
        posts.value.unshift(formattedMessage);
      } else if (
        posts.value?.length === 0 &&
        !parentMessageId &&
        message.customType === 'post'
      ) {
        const formattedMessage = formatPostMessage(message);
        posts.value = [formattedMessage];
      }
      if (parentMessageId) {
        const parentMessageIndex = posts.value.findIndex(
          post => post.message_id === parentMessageId,
        );
        if (parentMessageIndex !== -1) {
          let parentPost = posts.value[parentMessageIndex];
          sortedByDate(parentPost.comments.push(message));
          posts.value[parentMessageIndex] = parentPost;
        }
      }
    });
  };

  const getParamsForThreading = async (
    parentMessage,
    currentlyJoinedChannel,
  ) => {
    const params = {
      messageId: parentMessage.messageId,
      channelType: 'group', // Acceptable values are open and group.
      channelUrl: currentlyJoinedChannel.url,
    };

    const paramsThreadedMessageListParams = {
      prevResultSize: 100,
      nextResultSize: 100,
      isInclusive: true,
      reverse: false,
      includeParentMessageInfo: false,
    };

    try {
      const { threadedMessages } =
        await parentMessage.getThreadedMessagesByTimestamp(
          30,
          paramsThreadedMessageListParams,
        );

      return { params: params, threadedMessages: threadedMessages };
    } catch (e) {
      console.log('Error:', e);
    }
  };
  const uploadFileToCloudinary = async (
    file: File,
    channelId: string | undefined,
  ) => {
    const { cloudinaryUrl, unsignedUploadPreset } = cloudinaryParams;

    const formData = new FormData();
    formData.append('upload_preset', unsignedUploadPreset);
    formData.append('tags', 'lesson_3_exercise_3');
    formData.append('file', file);
    formData.append('folder', `Amit/Files/Posts/${channelId}`);

    try {
      const response = await axios.post(`${cloudinaryUrl}upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      return {
        fileUrl: response.data.secure_url,
        public_id: response.data.public_id,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const reactToMessageInGroupChannel = async (
    channel: GroupChannel,
    message: BaseMessage,
    emojiKey: string = 'like',
    cancelReaction: boolean = false,
  ) => {
    const reactionEvent = !cancelReaction
      ? await channel.addReaction(message, emojiKey)
      : await channel.deleteReaction(message, emojiKey);
    await message.applyReactionEvent(reactionEvent);
    return reactionEvent;
  };

  const initChannelAndMessages = async (channelId: string) => {
    const currentChannel = await retrieveChannel(channelId);
    if (!currentChannel) return;

    await retrieveMessagesForChannel(
      currentChannel,
      messageHandlers,
      onCacheResult,
      (err, messages) => onApiResult(err, messages, currentChannel),
    );
  };

  const deleteMessage = async (
    message: UserMessage | FileMessage | BaseMessage | any,
    channelUrl: string,
  ) => {
    const channel: GroupChannel =
      await sb.value.groupChannel.getChannel(channelUrl);
    const baseMessage = await getMessage(message.message_id, channelUrl);
    if (message.comments?.length) {
      const threadParams = await getParamsForThreading(baseMessage, channel);
      if (threadParams?.threadedMessages?.length) {
        await Promise.all(
          threadParams.threadedMessages.map((comment: BaseMessage) =>
            channel.deleteMessage(comment),
          ),
        );
      }
    }
    await channel.deleteMessage(baseMessage as BaseMessage);
  };

  const editMessage = async (message: any, channelUrl: string) => {
    const channel: GroupChannel =
      await sb.value.groupChannel.getChannel(channelUrl);
    return await channel.updateUserMessage(message.message_id, {
      message: message.title,
    });
  };

  const onCacheResult = (err, messages) => {};
  // watch(
  //   () => connected.value,
  //   async () => {
  //     if (connected.value) {
  //       // await initChannelAndMessages('');
  //     }
  //   },
  // );

  if (userId) {
    login(userId);
  }

  return {
    sb,
    retrieveChannel,
    retrieveMessagesForChannel,
    sendMessageToChannel,
    uploadFileToCloudinary,
    reactToMessageInGroupChannel,
    initChannelAndMessages,
    postsStore,
    deleteMessage,
    editMessage,
    currentlyShownChannelPosts,
    getParamsForThreading,
    getMessage,
    login,
    connected,
  };
}
