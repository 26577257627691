<template>
  <div class="flex flex-col space-y-4 items-start">
    <div class="w-full items-center font-simplerBold">{{ title }}</div>
    <div class="w-full items-center whitespace-pre-line">{{ subtitle }}</div>
    <button
      class="relative flex py-4 pr-6 pl-20 button bg-orange-500 hover:contrast-125 items-center shadow-md rounded-2xl text-white text-sm font-simplerBold"
      @click="goToGame">
      {{ genderLabel }}
      <img
        class="absolute bottom-0 left-3"
        :class="{ '!-bottom-3': userGender === 'male' }"
        :src="genderSrc"
        :alt="`${userGender}_img`"/>
    </button>
  </div>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue';
import { useReveal } from '@/apps/slideshow/useReveal';
import { useRoute, useRouter } from 'vue-router';
import { useVModel } from '@vueuse/core';
import emitter from '@/emitter';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const router = useRouter();
const { params, name, query } = useRoute();
const { user, toPage, context, onReady } = useReveal();
const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
const userGender = computed(() => user.value?.gender || 'male');

const genderData = {
  male: {
    label: 'איך אני מקבל החלטות?',
    src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1709809807/Decision%20making%20game/boy/Group_36672_ppjjkh.svg',
  },
  female: {
    label: 'איך אני מקבלת החלטות?',
    src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710154467/Decision%20making%20game/girls/Group_36673_hgtokq.svg',
  },
};

const genderLabel = computed(() => genderData[userGender.value].label);
const genderSrc = computed(() => genderData[userGender.value].src);

const updateGenderQuery = () => {
  router.push({ query: { ...query, gender: userGender.value } });
};

watch(userGender, updateGenderQuery);
updateGenderQuery();

const goToGame = () => {
  router.push({
    name: 'decision-game',
    query: { ...query, gender: userGender.value },
  });
};

emitter.on('close-game-modal', async submission => {
  model.value = submission;
  await router.push({ name, params });
  const currentPageId = context.value.getId();
  onReady(async () => toPage(currentPageId));
});
</script>
<style>
.button {
  background: radial-gradient(
      69.38% 52.97% at 15.75% 19.57%,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ff9d09;
}
</style>
