<template>
  <div
    :title="title"
    :class="{ [tagClasses]: true }"
    class="flex items-center gap-1 rounded-lg">
    <slot name="prefix">
      <button v-if="removable" class="pl-1" @click="$emit('removeTag')">
        <Close :size="12" />
      </button>
      <Menu v-else class="h-4 w-4" />
    </slot>
    <slot name="text">
      {{ trucatedTitle }}
    </slot>
  </div>
</template>
<script setup lang="ts">
import { useTagInputClasses } from '@/components/TagsInput/composables/useTagInputClasses';
import type { TagType } from '@/components/TagsInput/types';
import { Menu, X as Close } from 'lucide-vue-next';
import { computed } from 'vue';

interface IProps {
  title?: string;
  removable?: boolean;
  color?: TagType;
}

const props = withDefaults(defineProps<IProps>(), {
  title: '',
  removable: true,
  color: 'blue',
});

defineEmits(['removeTag']);
const { tagClasses } = useTagInputClasses(props.color || 'default');

const trucatedTitle = computed(() => {
  if (props.title.length > 100) return `${props.title.slice(0, 100)}...`;
  return props.title;
});
</script>
