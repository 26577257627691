<template>
  <FormControl :title="title" :sub-title="subTitle" :label="label">
    <div class="flex flex-col gap-5">
      <template v-for="(_, index) in models" :key="index">
        <TaskLine
          v-model="models[index]"
          :color="models[index].label"
          :editable="editable"/>
      </template>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import TaskLine from '@/components/Tasks/TaskLine.vue';
import { useVModel } from '@vueuse/core';
import type { PropType } from 'vue';
import type { ITaskModel } from '@/types/interfaces';
import { watchEffect } from 'vue';
import { each } from 'lodash';
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  editable: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Array as PropType<ITaskModel[]>,
    default: () => [],
  },
  modelValue: {
    type: Array as PropType<ITaskModel[]>,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);
const models = useVModel(props, 'modelValue', emit, {
  passive: true,
  deep: true,
});

watchEffect(() => {
  if (!props.options.length) return;

  each(props.options, option => {
    const modelOption = models.value.find(model => option.id === model.id);

    if (modelOption) {
      modelOption.title = option.title;
    } else {
      models.value.push({
        id: option.id,
        title: option.title,
        tasks: [],
        resources: [],
        criteria: [],
        estimation: '',
        label: option.label,
      });
    }
  });
});
</script>
