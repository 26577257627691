<template>
  <div
    class="w-full flex relative"
    :class="{ 'bg-[#F2F4F8]': disabled }"
    @focusout="editable = false">
    <textarea
      ref="textareaRef"
      v-model="model.text"
      :placeholder="placeholder"
      class="text-dark resize-none break-all caret-primary caret-5 rounded-sm w-full bg-transparent outline-none border-1 focus:block-outline overflow-y-hidden p-3"
      :disabled="disabled"
      @input="adjustHeight"/>
    <div
      v-if="disabled"
      class="absolute left-0 top-2 translate-middle-y h-6 bg-white hidden group-hover:block cursor-pointer p-1 rounded-md shadow-md ml-2"
      @click="editable = true">
      <Pencil class="stroke-gray-blue-600 h-4 w-4" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useVModel, useElementVisibility } from '@vueuse/core';
import { Pencil } from 'lucide-vue-next';
import { computed, ref, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  min: {
    type: Number,
    default: 1,
  },
  max: {
    type: Number,
    default: 10,
  },
  maxHeight: {
    type: Number,
    default: 100,
  },
  placeholder: {
    type: String,
    default: 'הכנס טקטס כאן',
  },
  type: {
    type: String,
    default: 'textarea',
  },
});
const emit = defineEmits(['update:modelValue', 'updateHeight']);

const model = useVModel(props, 'modelValue', emit);

const editable = ref(false);

const disabled = computed(() => props.type === 'text' && !editable.value);

const textareaRef = ref<HTMLTextAreaElement | null>(null);
const targetIsVisible = useElementVisibility(textareaRef);

const adjustHeight = () => {
  if (!textareaRef.value) return;

  let scrollHeight =
    model.value.text.split('\n').length <= 1
      ? 38
      : textareaRef.value.scrollHeight;
  const lineHeight = parseFloat(getComputedStyle(textareaRef.value).lineHeight);
  const maxAllowedHeight = lineHeight * (props.max + 2);
  const minAllowedHeight = lineHeight * props.min;
  let cellHeight = Math.max(
    Math.min(scrollHeight, maxAllowedHeight),
    minAllowedHeight,
  );

  emit('updateHeight', { [model.value.id]: cellHeight });
  textareaRef.value.style.height = `${props.maxHeight}px`;
  textareaRef.value.style.overflowY =
    cellHeight === maxAllowedHeight ? 'auto' : 'hidden';
};

watch(
  () => props.maxHeight,
  () => {
    if (textareaRef.value) {
      textareaRef.value.style.height = `${props.maxHeight}px`;
    }
  },
);

watch(
  () => targetIsVisible.value,
  isVisible => {
    if (isVisible) {
      adjustHeight();
    }
  },
);
</script>

<style scoped>
textarea:focus::placeholder {
  color: transparent;
}
</style>
