<template>
  <div class="flex gap-2">
    <div class="flex items-center gap-1">
      <slot name="prefix">
        <span
          v-if="prefix"
          class="text-sm text-secondary-900 whitespace-pre-wrap">
          {{ prefix }}
        </span>
      </slot>
    </div>

    <div
      v-for="option in options"
      :key="option.id"
      :title="option.title"
      class="cursor-pointer border-[0.5px] rounded-xl h-10 w-11 flex items-center justify-center"
      :class="{
        'border-secondary-300 bg-secondary-75': model[0] != option.id,
        'border-primary-700 bg-primary-200': model[0] === option.id,
      }"
      @click="handleClick(option.id)">
      <span
        class="font-simplerBold"
        :class="{
          'text-primary-650': model[0] === option.id,
          'text-primary-900': model[0] !== option.id,
        }">
        {{ option.title }}
      </span>
    </div>
    <div class="flex items-center gap-1">
      <slot name="suffix">
        <span
          v-if="suffix"
          class="text-sm text-secondary-900 whitespace-pre-wrap">
          {{ suffix }}
        </span>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { useVModel } from '@vueuse/core';

interface Option {
  title: string;
  id: string;
}

const props = defineProps({
  options: {
    type: Array as PropType<Option[]>,
    default: () => [],
  },
  prefix: {
    type: String,
    default: 'מאוד',
  },
  suffix: {
    type: String,
    default: '',
  },
  selected: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  isPreview: {
    type: Boolean,
    default: true,
  },
});

const model = useVModel(props, 'modelValue');

const handleClick = (id: string) => {
  if (props.isPreview) model.value = [id];
};
</script>
