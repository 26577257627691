<template>
  <FormControl>
    <div class="relative pb-20">
      <FullCalendar ref="fullCalendar" class="mt-10" :options="calendarOptions">
        <template #eventContent="arg">
          <div
            :class="
              getEventColors(get(arg.event, 'extendedProps.label', 'blue'))
            ">
            <div class="overflow-hidden">
              <div
                :class="
                  getTimeColor(get(arg.event, 'extendedProps.label', 'blue'))
                ">
                {{ arg.timeText }}
              </div>
              <BaseTooltip trigger="hover">
                <template #trigger>
                  <div class="text-default">{{ arg.event.title }}</div>
                </template>
                <template #content>{{ arg.event.title }}</template>
              </BaseTooltip>
            </div>
          </div>
        </template>
      </FullCalendar>
      <BaseBank text="בנק פעולות" class="bank-button">
        <template #default="{ minimized }">
          <div
            v-if="!minimized"
            ref="externalEvents"
            class="w-full flex gap-2 h-auto flex-wrap external-events max-h-[70px] xl:max-h-[80px] 2xl:max-h-[100px] overflow-y-auto">
            <TagInput
              v-for="option in bankOptions"
              :id="option.id"
              :key="option.id"
              class="fc-event cursor-pointer h-[28px] xl:h-[38px] 2xl:h-[44px]"
              :color="option.label"
              :removable="false">
              <template #text>
                <div class="flex gap-2">
                  <span>{{ option.title }}</span>
                  <span
                    v-if="option.duration && durationToText[option.duration]">
                    {{ `(${durationToText[option.duration]})` }}
                  </span>
                </div>
              </template>
            </TagInput>
          </div>
        </template>
      </BaseBank>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import { BaseTooltip } from '@amit/foundation';
import { computed, onMounted, ref, watchEffect } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import type { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import heLocale from '@fullcalendar/core/locales/he';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useVModel } from '@vueuse/core';
import { useDraggableCalendarClasses } from '@/components/DraggableCalendar/composables/useDraggableCalendarClasses';
import { get, map } from 'lodash';
import type { ICalendarEvent } from '@/types/interfaces';
import TagInput from '@/components/TagsInput/TagInput.vue';
import swal from 'sweetalert';
import { useDraggableCalendar } from '@/composables';
import BaseBank from '@/components/BaseBank/BaseBank.vue';

export interface IDraggableCalendar {
  initialView: 'timeGridDay' | 'dayGridMonth' | 'timeGridWeek';
  events: ICalendarEvent[];
  options: ICalendarEvent[];
  modelValue: {
    events: ICalendarEvent[];
  };
  weekends: boolean;
  nowIndicator: boolean;
  businessDaysOfWeek: number[];
  businessStartTime: string;
  businessEndTime: string;
  initialDate: string;
  controls: boolean;
}

const props = withDefaults(defineProps<IDraggableCalendar>(), {
  initialView: 'dayGridMonth',
  events: () => [],
  options: () => [],
  modelValue: () => ({ events: [] }),
  weekends: false,
  nowIndicator: true,
  businessDaysOfWeek: () => [0, 1, 2, 3, 4, 5, 6], // Monday - Thursday
  businessStartTime: '08:00', // a start time (10am in this example)
  businessEndTime: '18:00',
  initialDate: '2023-02-20',
  controls: false,
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
const bankOptions = ref(props.options);
const schoolTimeEvents = computed(() =>
  props.events.filter(({ type }) => type === 'school'),
);
const externalEvents = ref(null);

const withNoSchoolTimeEvents: any = computed(() =>
  //@ts-ignore
  calendarEvents.value.filter(({ type }: { type: any }) => type !== 'school'),
);

const deadlineEvent = computed(() =>
  props.events.find(({ type }) => type === 'deadline'),
);

const calendarEvents = computed(() => {
  return [
    ...props.events,
    ...bankOptions.value.filter((event: ICalendarEvent) =>
      map(model.value.events, 'id').includes(event.id.toString()),
    ),
    ...[...new Set(model.value.events)],
  ];
});

const { getConditionMessage } = useDraggableCalendar({
  schoolTimeEvents,
  withNoSchoolTimeEvents,
  deadlineEvent,
});

const fullCalendar = ref<any>(null);
const durationToText = {
  5: '5 דקות',
  60: 'שעה',
  120: 'שעתיים',
  180: '3 שעות',
};

watchEffect(() => {
  const options = props.options;
  const modelEventsIds = map(model.value.events, 'id');
  bankOptions.value = options.filter(
    (option: ICalendarEvent) => !modelEventsIds.includes(option.id.toString()),
  );
});

const init = () => {
  if (!model.value?.events?.length) return;
};

onMounted(() => {
  init();

  watchEffect(() => {
    if (externalEvents.value) {
      new Draggable(externalEvents.value as HTMLElement, {
        itemSelector: '.fc-event',
        eventData: function (eventEl) {
          const currentEvent = props.options.find(
            option => option.id === eventEl.id,
          );
          if (!currentEvent) return;
          return {
            title: eventEl.innerText,
            id: eventEl.id,
            label:
              props.options.find(op => op.id === eventEl.id)?.label || 'blue',
          };
        },
      });
    }
  });
});

const headerToolbar = computed(() => {
  const left = props.controls ? 'next' : '';
  const right = props.controls ? 'prev' : '';
  const toolbar = {
    center: 'title',
    left,
    right,
  };
  return toolbar;
});

const calendarOptions: ComputedRef<CalendarOptions> | any = computed(() => ({
  plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
  initialView: props.initialView,
  weekends: props.weekends,
  locale: heLocale,
  direction: 'rtl',
  scrollTime: '10:00:00',
  slotMinTime: '07:00',
  slotMaxTime: '24:00',
  allDaySlot: false,
  initialDate: new Date(props.initialDate),
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: false,
  },
  dayHeaderFormat: {
    weekday: 'long',
  },
  businessHours: {
    daysOfWeek: props.businessDaysOfWeek, // Monday - Thursday
    startTime: props.businessStartTime, // a start time (10am in this example)
    endTime: props.businessEndTime,
  },
  nowIndicator: props.nowIndicator,
  events: calendarEvents.value,
  headerToolbar: headerToolbar.value,
  editable: true,
  droppable: true,
  drop: handleDrop,
  eventClick: () => {},
  eventsSet: () => {},
  eventChange: handleEventChange,
}));

const getEventId = async id => {
  const eventApi = await fullCalendar.value.getApi();

  return eventApi.getEventById(id);
};

const handleDrop = event => {
  onDropEvent(event.draggedEl.id, event.date);
};
const handleEventChange = ({ event }) => {
  addEventToBankOptions(event.id);
  removeEventFromModel(event.id);
  onDropEvent(event.id, event.start, event.end);
};

const addEventToBankOptions = id => {
  const event = model.value?.events?.find(event => event.id === id);
  if (event) {
    bankOptions.value.push(event);
  }
};

const removeEventFromModel = id => {
  const eventIndex = model.value.events?.findIndex(event => event.id === id);
  if (eventIndex !== -1) {
    model.value.events.splice(eventIndex, 1);
  }
};
const onDropEvent = async (eventId, date, endDate = null) => {
  const currentEvent = await getEventId(eventId);
  const { message, buttons, allowed } = getConditionMessage(date);
  if (message) {
    const confirmed = await swal({
      text: message,
      buttons,
    });
    if (allowed && confirmed) {
      addEventToModel(eventId, date, endDate);
    } else {
      currentEvent?.remove();
      removeEventFromModel(eventId);
    }
  } else {
    addEventToModel(eventId, date, endDate);
  }
};

const getEndDate = (date, duration) => {
  const endDate = new Date(date);
  let hoursToAdd = 1;
  if (duration) {
    hoursToAdd = Math.ceil(duration / 60);
  }
  endDate.setHours(endDate.getHours() + hoursToAdd);
  return endDate;
};

const addEventToModel = (id, date, endDate = null) => {
  const droppedItem = bankOptions.value.find((option: ICalendarEvent) => {
    return option.id === id.toString();
  });
  if (!droppedItem) {
    return;
  }

  const { title, label, duration } = droppedItem;
  const itemEndDate = endDate || getEndDate(date, duration);

  model.value.events?.push({
    editable: true,
    end: itemEndDate.toISOString(),
    id: id.toString(),
    label,
    start: date.toISOString(),
    title,
  });
  const eventIndex = bankOptions.value.findIndex((option: ICalendarEvent) => {
    return option.id === id.toString();
  });

  if (eventIndex !== -1) {
    bankOptions.value.splice(eventIndex, 1);
  }
};

const { getTimeColor, getEventColors } = useDraggableCalendarClasses();
</script>
<style>
.fc {
  @apply font-simplerRegular text-base text-sm h-[525px] overflow-y-auto;
}

.fc-scroller {
}

.fc-timegrid-slot-label-frame {
  @apply p-0 text-secondary-600 !important;
}

.fc-scrollgrid.fc-scrollgrid-liquid {
  @apply border-0;
}

.fc-timegrid-slot-label-frame.fc-scrollgrid-shrink-frame {
  @apply border-r-0;
}

.fc .fc-timegrid-slot-minor {
}

.fc .fc-button-primary:not(:disabled):active:focus {
  @apply focus:bg-primary-200 focus:border-primary-200 active:bg-primary-200 active:border-primary-200;
}

.fc-next-button.fc-button-primary,
.fc-prev-button.fc-button-primary {
  @apply bg-white border-0 hover:bg-primary-100;
}

fc-event,
.fc-event-draggable,
.fc-event-resizable,
.fc-event-start.fc-event-end,
.fc-event-past,
.fc-timegrid-event,
.fc-v-event {
  @apply border-secondary-300 bg-transparent;
}

.fc-col-header {
  @apply border border-slate-200;
}

.fc-timegrid-divider {
  @apply hidden;
}

.fc-timegrid-slot {
  @apply h-14 bg-transparent !important;
}

.fc-theme-standard th {
  @apply border-0;
}

.fc .fc-button {
  box-shadow: none !important;
}

.fc .fc-button .fc-icon {
  color: black;
}
</style>
