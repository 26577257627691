<template>
  <div
    ref="barsContainer"
    :class="{
      'grid grid-cols-12 gap-4  mt-10': !horizontal,
      'mt-20': !horizontal,
      'flex  gap-3 flex-col': horizontal,
    }">
    <div
      v-for="(item, index) in barsData"
      ref="bars"
      :key="item.id"
      class="flex flex-col justify-center relative"
      :class="{ [`col-span-${barColNumber}`]: !horizontal }">
      <ProgressBar :id="item.id" :key="item.id" :pt="getPt(item)"></ProgressBar>
      <div
        ref="result"
        class="absolute flex justify-center items-center gap-2"
        :class="{ 'flex-col': barColNumber === 1, 'w-full': !horizontal }"
        :style="{
          'inset-inline-end': horizontal ? '0px' : '',
          bottom: !horizontal
            ? resultVerticalPosition(item.count).bottom + 'px'
            : '',
        }">
        <img
          v-if="item.currentUserVote && user.avatar && isStudent"
          :src="user.avatar"
          alt="user_avatar"
          class="h-5 w-5"/>
        <LetterAvatar
          v-else-if="item.currentUserVote && !user.avatar && isStudent"
          :user-name="user.fullName"/>
        <span v-if="percentageResults && amountResults" class="text-center">
          {{ calculateProgress(item.count, true) }}% ({{ item.count }})
        </span>
        <span v-else-if="amountResults">{{ item.count }}</span>

        <span v-else-if="percentageResults">
          {{ calculateProgress(item.count, true) }}%
        </span>
      </div>

      <div
        class="whitespace-nowrap overflow-hidden text-ellipsis text-center"
        :style="{
          'inset-inline-start': horizontal ? '10px' : '',
        }"
        :class="{ 'font-simplerBold mt-2': !horizontal, absolute: horizontal }"
        :title="item.title">
        {{ item.title ? item.title : index + 1 }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, PropType, computed, ref } from 'vue';
import ProgressBar from 'primevue/progressbar';
import LetterAvatar from '@amit/layout/src/components/LetterAvatar.vue';
import { useReveal } from '@/apps/slideshow/useReveal';

export type BarChart = {
  id: string;
  title: string;
  count: number;
  currentUserVote?: boolean;
  percentage?: number;
};

const props = defineProps({
  barsData: {
    type: Array as PropType<BarChart[]>,
    required: true,
  },
  horizontal: {
    type: Boolean,
    default: false,
    require: false,
  },
  percentageResults: {
    type: Boolean,
    default: false,
  },
  amountResults: {
    type: Boolean,
    default: true,
  },
});
const { user }: { user: any } = useReveal();

const barsContainer = ref();
const bars = ref();
const result = ref();
const barsContainerWidth = ref(200);
const barHeight = ref(50);
const resultWidth = ref(20);
const barColNumber = computed(() => {
  const calculatedColNumber = Math.floor(12 / props.barsData.length);
  return Math.min(calculatedColNumber, 3);
});
/* eslint-disable @typescript-eslint/no-unused-vars */
const maxPercentage = '100%';

const isStudent = computed(() => {
  return user.value.role?.includes('student');
});

onMounted(() => {
  nextTick(() => {
    const rectBarsContainer =
      barsContainer.value && barsContainer.value.getBoundingClientRect();
    if (rectBarsContainer) {
      barsContainerWidth.value = rectBarsContainer.width;
    }
    const rectBar = bars.value && bars.value[0].getBoundingClientRect();
    if (rectBar) {
      barHeight.value = rectBar.height;
    }
    const rectResult = result.value && result.value[0].getBoundingClientRect();
    if (rectResult) {
      resultWidth.value = rectResult.width;
    }
  });
});

const totalCounts = computed(() => {
  return props.barsData.reduce((acc, curr) => acc + (curr.count || 0), 0);
});

const calculateProgress = (count: number, round: Boolean = false) => {
  const totalCount = totalCounts.value;
  if (totalCount === 0) {
    return 0; // Avoid division by zero
  }
  if (round) return Math.round((count / totalCount) * 100);
  return (count / totalCount) * 100; // Calculate percentage as string
};

const resultVerticalPosition = (count: number): { bottom: number } => {
  const bottom = (barHeight.value * calculateProgress(count)) / 100 + 30;

  return { bottom };
};

const getPt = (item: BarChart) => {
  return {
    root: {
      class: [
        'overflow-hidden border-0 h-[5px] bg-transparent flex',
        !props.horizontal
          ? 'h-full rotate-0 flex-col-reverse max-w-[100%] h-auto'
          : 'h-[30px] flex-direction: column-reverse',
      ],
      style: {
        backgroundColor: '#FFFFF',
        height: !props.horizontal ? '200px' : '44px',
        width: !props.horizontal ? 'auto' : '88%',
      },
    },
    value: {
      class: [
        !props.horizontal
          ? 'animate-bar-progress-height h-[200px] w-full items-center justify-center'
          : 'animate-bar-progress-width h-full flex items-center justify-center rounded-md',
        item.currentUserVote ? 'bg-primary-400' : 'bg-primary-200',
      ],
      style: {
        backgroundColor: '#FFFFF',
        width: !props.horizontal
          ? '100%'
          : 1 + calculateProgress(item.count) + '%',
        height: !props.horizontal
          ? 1 + calculateProgress(item.count) + '%'
          : '100%',
      },
    },
    label: {
      class: [
        'text-black',
        props.horizontal ? 'w-full flex justify-between p-4' : 'hidden',
      ],
      style: {
        width: '100%',
        wordBreak: 'break-word',
      },
    },
  };
};
</script>

<style>
.animate-bar-progress-width {
  animation: progress-fill-width 1s ease-in-out forwards;
  /* Animation definition */
}

.animate-bar-progress-height {
  animation: progress-fill-height 1s ease-in-out forwards;
  /* Animation definition */
}

@keyframes progress-fill-width {
  from {
    width: 0;
  }

  to {
    width: var(maxPercentage);
  }
}

@keyframes progress-fill-height {
  from {
    height: 0;
  }

  to {
    height: var(maxPercentage);
  }
}
</style>
