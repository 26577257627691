<template>
  <div>
    <div
      v-if="!isEditing"
      class="flex gap-2 font-simplerBold font-bold text-secondary-900">
      {{ pretext }}
      {{ model }}
      <button @click="isEditing = !isEditing">
        <Pencil width="14" height="14" />
      </button>
    </div>
    <div v-else class="text-area-input w-full flex-col relative rounded-4">
      <div class="w-full items-center font-simplerBold font-bold">
        <BaseTextArea
          v-model="model"
          class="w-80/100 gap-2"
          :placeholder="placeholder"
          :rows="1"
          @focusout="isEditing = false"
          @keyup.enter="isEditing = false"></BaseTextArea>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Pencil } from 'lucide-vue-next';
import BaseTextArea from '@/components/Textarea/BaseTextArea.vue';
import { useVModel } from '@vueuse/core';

interface IProps {
  modelValue: string;
  placeholder: string;
  pretext: string;
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: '',
  placeholder: '',
  pretext: '',
});

const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);

const isEditing = ref(false);
</script>
