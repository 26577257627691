<template>
  <div class="flex items-start">
    <Tooltip
      :distance="distance"
      :placement="placement"
      :triggers="[trigger]"
      :show-triggers="[trigger]"
      :hide-triggers="[trigger]"
      :theme="theme"
      :shown="isOpen"
      :auto-hide="autoHide"
      :popper-class="popperClass"
      :container="popperContainer"
      :positioning-disabled="positioningDisabled">
      <slot name="trigger" />
      <template #popper>
        <slot name="content" />
      </template>
    </Tooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Tooltip } from 'floating-vue';
import type { TooltipPlacement, TooltipStyle, TooltipTrigger } from './types';
import 'floating-vue/dist/style.css';
interface TooltipProps {
  distance?: number;
  skidding?: number;
  placement?: TooltipPlacement;
  theme?: TooltipStyle;
  trigger?: TooltipTrigger;
  showTriggers?: string[];
  hideTriggers?: string[];
  isOpen?: boolean;
  autoHide?: boolean;
  popperClass?: string;
  popperContainer?: string;
  positioningDisabled?: boolean;
}
const props = withDefaults(defineProps<TooltipProps>(), {
  distance: 10,
  skidding: 5,
  placement: 'top',
  theme: 'dark',
  trigger: 'hover',
  showTriggers: () => ['hover', 'click'],
  hideTriggers: () => ['hover', 'click'],
  isOpen: false,
  autoHide: true,
  popperClass: '',
  popperContainer: 'body',
  positioningDisabled: false,
});
const theme = computed(() => {
  const themes = {
    light: 'tooltip-light',
    dark: 'tooltip-dark',
    transparent: 'tooltip-transparent',
  };
  return themes[props.theme];
});
</script>

<style>
.v-popper--theme-tooltip-dark .v-popper__wrapper .v-popper__inner {
  background: rgba(0, 0, 0);
  color: #fff;
  border-radius: 6px;
  padding: 7px 12px 6px;
}

.v-popper--theme-tooltip-dark .v-popper__wrapper .v-popper__inner * {
  color: #fff;
}

.v-popper--theme-tooltip-dark .v-popper__arrow-inner {
  visibility: hidden;
}

.v-popper--theme-tooltip-dark .v-popper__arrow-outer {
  border-color: #000c;
}

@media (prefers-color-scheme: dark) {
  .v-popper--theme-tooltip-dark .v-popper__wrapper .v-popper__inner {
    background: rgb(55 65 81);
  }

  .v-popper--theme-tooltip-dark .v-popper__arrow-outer {
    border-color: rgb(55 65 81);
  }
}

.v-popper--theme-tooltip-light .v-popper__wrapper .v-popper__inner {
  background: #fff;
  color: black;
  padding: 7px 12px 6px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.25);
}

.v-popper--theme-tooltip-light .v-popper__arrow-inner {
  visibility: visible;
  border-color: #fff;
}

.v-popper--theme-tooltip-light .v-popper__arrow-outer {
  border-color: #ddd;
}

.v-popper--theme-tooltip-transparent .v-popper__wrapper .v-popper__inner {
  background: rgba(255, 255, 255, 0.6);
  color: black;
  padding: 10px 12px;
  border-radius: 8px;
  /* border: 1px solid #eeeeee; */
  box-shadow:
    0px 2px 10px 1px rgba(12, 74, 110, 0.07),
    0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.v-popper--theme-tooltip-transparent .v-popper__arrow-inner {
  display: none;
}

.v-popper--theme-tooltip-transparent .v-popper__arrow-outer {
  display: none;
}
.v-popper__popper {
  z-index: 10;
}

.v-popper__popper[data-popper-placement^='bottom'] .v-popper__inner {
  margin-top: -1px;
}
.v-popper__popper[data-popper-placement^='right'] .v-popper__inner {
  margin-left: -1px;
}
</style>
