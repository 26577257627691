<template>
  <FormControl :title="title" :sub-title="subtitle" class="w-full">
    <div class="container mx-auto p-4">
      <ContentTable
        :headers="headers"
        :items="items"
        :selected-header="maxWeight"
        theme="light"
        display="flex">
        <template #subheader="{ header }">
          <div
            v-if="header.type === 'decision_score'"
            :class="{ 'cell-shadow': header.key === maxWeight }"
            class="flex items-center justify-center w-full h-12 text-md">
            <div
              class="flex items-center justify-center w-7/12 h-full font-simplerRegular font-normal text-secondary-900 border-1 border-secondary-250">
              ציון השיקול
            </div>
            <div
              class="flex items-center justify-center w-5/12 h-full font-simplerRegular font-normal text-secondary-900 border-1 border-secondary-250">
              שקלול
            </div>
          </div>
        </template>
        <template #cell="{ content, rowIndex, header }">
          <div v-if="header.type === 'decision_rank'">
            <div
              v-if="content.text"
              class="flex justify-left w-full font-simplerBold">
              {{ content.text }}
            </div>
            <component
              :is="Star"
              v-for="({}, index) in content.value"
              :key="index"
              class="ml-1 mt-0 inline-block"/>
          </div>
          <div
            v-if="header.type === 'decision_score'"
            :class="{ 'cell-shadow': header.key === maxWeight }">
            <div
              v-for="({}, index) in model[rowIndex]?.[header.key]?.content ??
              []"
              :key="index"
              class="w-full flex">
              <div class="w-7/12 h-12">
                <BaseDropdown
                  v-model="model[rowIndex][header.key].content[index]"
                  :options="options"
                  :selected-options="options"
                  :label="header.selectLabel"/>
              </div>
              <div
                class="w-5/12 flex items-center justify-center bg-secondary-50 border-1 border-secondary-250">
                {{
                  model[rowIndex][header.key].content[index].value *
                    model[rowIndex].title.content[index].value || 0
                }}
              </div>
            </div>
          </div>
        </template>
        <template v-if="formatting.totalRow" #totalrow="{ header }">
          <div
            v-if="header.type === 'decision_score'"
            :class="{ 'bottom-shadow': header.key === maxWeight }"
            class="flex border-1 border-secondary-250 items-center justify-center h-12">
            {{ getTotalWeight[header.key] }}
          </div>
          <div
            v-else
            class="flex border-1 border-secondary-250 items-center justify-center h-12">
            סך הכל
          </div>
        </template>
      </ContentTable>
    </div>
  </FormControl>
</template>

<script lang="ts" setup>
import { watch, computed } from 'vue';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useVModel } from '@vueuse/core';
import ContentTable from '@/components/ContentTable/ContentTable.vue';
import BaseDropdown from '@/components/Table/BaseDropdown.vue';
import Star from '@/assets/star.svg?component';
import { isEmpty } from 'lodash';

const props = defineProps({
  modelValue: {
    type: Array as any,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  items: {
    type: Array as any,
    default: () => [],
  },
  headers: {
    type: Array as any,
    default: () => [],
  },
  formatting: {
    type: Object,
    default: () => ({
      totalRow: true,
    }),
  },
});

const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);

const options = [
  { text: '1 - אין מענה', value: 1 },
  { text: '2 - מענה מועט', value: 2 },
  { text: '3 - מענה חלקי', value: 3 },
  { text: '4 - מענה מרובה', value: 4 },
  { text: '5 -  מענה מלא', value: 5 },
];

watch(
  () => props.modelValue,
  () => {
    if (model.value.length) {
      return;
    }
    props.items.forEach((item: any) => {
      model.value.push(item);
    });
  },
  { immediate: true },
);

const getTotalWeight = computed(() => {
  return props.headers.reduce((totals, header) => {
    if (header.type === 'decision_score') {
      let total = 0;
      let allSelected = props.items.some((item, index) => {
        const weight = item.title?.content[0].value;
        const options = model.value[index];
        const value = !isEmpty(options)
          ? options[header.key]?.content[0].value
          : 0;
        total += value * weight;
        return value === 0;
      });
      totals[header.key] = allSelected ? 0 : total;
    }
    return totals;
  }, {});
});

const maxWeight = computed(() => {
  const totalWeight = getTotalWeight.value;
  const allValuesNotEmpty = Object.values(totalWeight).every(
    value => value !== 0,
  );
  return allValuesNotEmpty
    ? Object.keys(totalWeight).reduce((a, b) =>
        totalWeight[a] > totalWeight[b] ? a : b,
      )
    : '';
});
</script>

<style scoped>
.bottom-shadow {
  box-shadow:
    -3px 0 20px 0px rgba(0, 0, 0, 0.1),
    -3px 0 20px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 20;
  width: 100%;
  height: 70px;
  top: 60%;
  clip-path: inset(0px -15px -10px -15px);
  margin-bottom: 20px;
}

.cell-shadow {
  box-shadow:
    3px 0 20px 0px rgba(0, 0, 0, 0.2),
    -3px 0 20px 0px rgba(0, 0, 0, 0.1);
  clip-path: inset(0px -15px 0px -15px);
}

:deep(.selected-header) {
  box-shadow:
    10px 0 10px -5px rgba(0, 0, 0, 0.3),
    -10px 0 10px -5px rgba(0, 0, 0, 0.3);
  position: relative;
  background-color: #49b64e;
  color: white;
  width: 100%;
  height: 70px;
}
</style>
