<script setup lang="ts">
import { ref, watch } from 'vue';
import BaseFile from '@/components/BaseFile/BaseFile.vue';
import axios from 'axios';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import PDF from '@/assets/pdf.svg?component';
import JPG from '@/assets/jpg.svg?component';
import EXCEL from '@/assets/excel.svg?component';
import DOC from '@/assets/doc.svg?component';
import TXT from '@/assets/text.svg?component';
import MP3 from '@/assets/mp3.svg?component';
import MP4 from '@/assets/mp4.svg?component';

interface FileData {
  file: File | null;
  preview: string | unknown;
  name: string | null;
  public_id: string | null;
  type: string | null;
  icon: any | null;
}

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Array<{ url; name }>,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const cloudName = 'dcodkxpej';
const unsignedUploadPreset = 'r6mwkrzq';
const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);

const files = ref<FileData[]>([]);

const fileExtensionMap = {
  '.pdf': { type: 'application/pdf', icon: PDF },
  '.txt': { type: 'text/plain', icon: TXT },
  '.mp3': { type: 'audio/mpeg', icon: MP3 },
  '.jpg': { type: 'image/jpeg', icon: JPG },
  '.jpeg': { type: 'image/jpeg', icon: JPG },
  '.doc': { type: 'application/msword', icon: DOC },
  '.docx': {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: DOC,
  },
  '.mp4': { type: 'video/mp4', icon: MP4 },
  '.xlsx': {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    icon: EXCEL,
  },
  '.png': { type: 'image/png', icon: JPG },
};

const getFileExtension = filename => {
  return filename?.slice(filename.lastIndexOf('.')).toLowerCase();
};

watch(
  () => props.modelValue,
  newValue => {
    newValue.forEach((modelValue, index) => {
      const fileUrl = modelValue.url ?? modelValue;
      const fileExtension = getFileExtension(fileUrl);
      const fileInfo = fileExtensionMap[fileExtension] || {
        type: 'unknown',
        icon: null,
      };

      files.value[index] = {
        ...files.value[index],
        preview: fileUrl,
        name: modelValue?.name,
        type: fileInfo.type,
        icon: fileInfo.icon,
      };
    });
  },
  { immediate: true },
);

const isLoading = ref({
  status: false,
  index: null,
});
const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloudName}/`;

const updatedFile = async (data: any) => {
  isLoading.value = {
    status: true,
    index: data.index,
  };
  await uploadFile(data).then(() => {
    isLoading.value = {
      status: false,
      index: null,
    };
    const fileExtension = getFileExtension(data.file.name);
    const fileInfo = fileExtensionMap[fileExtension];

    files.value[data.index] = {
      ...files.value[data.index],
      file: data.file,
      preview: data.preview,
      name: data.name,
      type: fileInfo.type,
      icon: fileInfo.icon,
    };
  });
};

const deleteFile = ({ index }: any) => {
  files.value[index] = {
    file: null,
    preview: null,
    public_id: null,
    type: 'unknown',
    name: null,
    icon: null,
  };
  model.value.splice(index, 1);
};

const uploadProgress = ref(0);

const abortController = ref();

const abortUpload = () => {
  abortController.value.abort();
};

const uploadFile = async ({ file, index }) => {
  const formData = new FormData();
  formData.append('upload_preset', unsignedUploadPreset);
  formData.append('tags', 'lesson_3_exercise_3');
  formData.append('file', file);
  formData.append('folder', 'Amit/Files');

  abortController.value = new AbortController();
  const signal = abortController.value.signal;

  try {
    const response = await axios.post(`${cloudinaryUrl}upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
      onUploadProgress: progressEvent => {
        const total = progressEvent.total ?? 100;
        uploadProgress.value = Math.round((progressEvent.loaded * 100) / total);
      },
      signal: signal,
    });
    const secureUrl = response.data.secure_url;
    model.value.push({ url: secureUrl, name: file.name });
    files.value[index].public_id = response.data.public_id;
    uploadProgress.value = 0;
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <FormControl :title="title" :sub-title="subTitle">
    <div class="flex flex-col justify-center items-start gap-4">
      <div
        v-for="(choice, index) in options"
        :key="index"
        class="flex items-center space-y-5">
        <base-file
          v-model="files[index]"
          :editable="editable"
          :loading="isLoading"
          :upload-progress="uploadProgress"
          :index="index"
          :text-success="(choice as any).text"
          :text-upload="(choice as any).text"
          @upload-file="updatedFile"
          @abort-upload="abortUpload"
          @delete-file="deleteFile"/>
      </div>
    </div>
  </FormControl>
</template>
