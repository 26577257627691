<template>
  <div class="flex gap-10">
    <div v-if="currentVideo" class="w-4/5">
      <Transition
        enter-active-class="transition ease-in-out duration-700 opacity"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in-out duration-100 opacity"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <div :key="currentVideo.id">
          <CustomVideo
            class="flex w-full items-center justify-between pb-10"
            size="full"
            :persistent="false"
            :src="currentVideo?.src"
            :caption="currentVideo?.caption"
            :transcription="currentVideo?.transcription"
            :auto-play="true"
            @video-next-enabled="$emit('video-next-enabled')"></CustomVideo>

          <div class="flex flex-col pb-15">
            <div class="flex justify-between items-center w-full">
              <div class="text-lg text-dark font-simplerBold">
                {{ personaIntro }}
              </div>
              <BaseButton
                v-if="
                  !hideSelectPersona || selectedPersona === currentPersona?.id
                "
                outline
                :color="
                  selectedPersona === currentPersona?.id ? 'pink' : 'blue'
                "
                class="flex gap-2 items-center p-2 rounded-lg font-simplerBold"
                @click="$emit('update:modelValue', currentPersona?.id)">
                <div class="flex items-center gap-2 text-inherit">
                  <span
                    v-if="selectedPersona === currentPersona?.id"
                    class="text-inherit">
                    בחרת ב{{ currentPersona?.name }}
                  </span>
                  <span v-else class="text-primary">
                    בחירה ב{{ currentPersona?.name }}
                  </span>
                  <Heart
                    :class="{
                      'fill-pink-500 stroke-pink-500':
                        selectedPersona === currentPersona?.id,
                      'stroke-primary': selectedPersona != currentPersona?.id,
                    }"
                    :size="16"
                    :stroke-width="2"/>
                </div>
              </BaseButton>
            </div>
            <p class="font-simplerRegular mt-5">
              {{ personaDescription }}
            </p>
          </div>
        </div>
      </Transition>
    </div>

    <div class="w-1/5 flex flex-col gap-5 mt-10 pb-20">
      <div
        v-for="{ id, name, avatar } in personas"
        :key="id"
        class="cursor-pointer max-w-100"
        @click="currentDisplayedPersonaId = id">
        <div class="flex items-center justify-between w-full gap-2">
          <span
            class="font-simplerBold text-lg text-dark gap-3"
            :class="{ 'text-primary': currentDisplayedPersonaId === id }">
            {{ name }}
          </span>
          <Heart
            v-if="selectedPersona === id"
            class="fill-pink-500 stroke-pink-500"
            :size="16"
            :stroke-width="2"/>
        </div>
        <div class="flex flex-col justify-between grow">
          <div class="relative">
            <img
              class="rounded-lg w-full overflow-hidden"
              :src="avatar"
              alt="Coach image"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watchEffect, PropType } from 'vue';
import { BaseButton, CustomVideo } from '@/components';
import { Heart } from 'lucide-vue-next';
import { IPersona, IVideo } from '@/types/interfaces';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  displayPersona: {
    type: String,
    default: '',
  },
  hideSelectPersona: {
    type: Boolean,
    default: false,
  },
  personas: {
    type: Array as PropType<IPersona[]>,
    default: () => [],
  },
  videos: {
    type: Array as PropType<IVideo[]>,
    default: () => [],
  },
});
const emit = defineEmits(['update:modelValue', 'video-next-enabled']);

const selectedPersona = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
});

const selectedPersonaName = computed(
  () =>
    props.personas.find(persona => persona.id === selectedPersona.value)?.name,
);
const currentDisplayedPersonaId = ref(props.displayPersona);
const currentPersona = computed(() =>
  props.personas.find(
    persona => persona.id === currentDisplayedPersonaId.value,
  ),
);
const currentVideo = computed(() =>
  props.videos.find(video => video.persona === currentDisplayedPersonaId.value),
);

watchEffect(() => {
  currentDisplayedPersonaId.value = props.displayPersona;
});

const personaDescription = computed(() =>
  props.hideSelectPersona
    ? `אנחנו ממליצים בחום לצפות בדעות של החברים הנוספים מצד שמאל. אין מה לדאוג, ${selectedPersonaName.value} איתך לכל אורך הדרך!`
    : currentPersona.value?.description,
);
const personaIntro = computed(() => {
  if (selectedPersona.value === currentPersona.value?.id)
    return `המסע עם ${currentPersona.value?.name}`;

  if (props.hideSelectPersona)
    return `כדאי לשמוע גם את ${currentPersona.value?.name}!`;

  return `רוצה לצאת למסע עם ${currentPersona.value?.name}?`;
});
</script>
