<template>
  <Card
    style="overflow: hidden; box-shadow: none"
    class="bg-secondary-75 cursor-pointer"
    :pt="{
      header: {
        class: ['p-2 post-header  text-sm'],
      },
      body: {
        class: [post ? 'bg-yellow-500-custom' : ''],
      },
      content: {
        class: ['p-3 post-content relative'],
        style: {
          maxHeight: '300px',
          overflow: 'hidden',
        },
      },
      footer: {
        class: ['p-2  border-t border-secondary-300 flex justify-between'],
      },
    }"
    @click="$emit('click-post', true, post)"
  >
    <template #header>
      <div v-if="post" class="flex justify-between items-center">
        <div class="flex gap-2 items-center w-full">
          <Avatar
            :label="ownerAvatar"
            class="!h-6 !w-6"
            shape="circle"
            style="background-color: #ece9fc; color: #2a1261; font-size: 14px"
          />
          <div class="font-semibold text-xs">{{ ownerName }}</div>
          <div class="text-secondary-800 text-xs">
            {{ timeAgo(post.created_at) }}
          </div>
        </div>
        <BaseDropdown
          v-if="
            post.author.userId === currentUser?.id ||
            post.author.userId === 'demo-user-id' ||
            isOperator
          "
          placement="bottom"
          align="left"
        >
          <template #trigger>
            <Ellipsis
              class="w-7 h-6 active:bg-secondary-250 hover:bg-gray-200 rounded-xl"
              @click.stop
            />
          </template>
          <div
            class="flex flex-col rounded-full p-1 cursor-pointer divide-y divide-solid"
          >
            <Pencil
              class="h-6 stroke-[1.2px] p-1 hover:bg-gray-200 rounded-md"
              @click="handleEdit"
            />
            <Trash2
              v-if="canDelete"
              class="h-6 stroke-[1.2px] p-1 hover:bg-gray-200"
              @click="handleDelete"
            />
          </div>
        </BaseDropdown>
      </div>
      <div v-if="!post" class="flex flex-col gap-2 flex-1">
        <Skeleton width="30%" height="1rem" />
        <Skeleton width="80%" height="1rem" />
      </div>
    </template>

    <template #content>
      <div
        v-if="post"
        v-show="post && post.title"
        class="text-sm editor-content post-text-content"
        :class="[postBackground ? `bg-${postBackground}` : '']"
        v-html="post.title"
      ></div>

      <div v-else class="flex flex-col gap-2 flex-1">
        <Skeleton width="30%" height="1rem" />
        <Skeleton width="80%" height="1rem" />
      </div>
      <div class="gradient-overlay"></div>
    </template>
    <template v-if="post" #footer>
      <button v-if="!post?.comment_status">
        <MessageSquarePlus class="stroke-secondary-900 h-4" />
      </button>

      <button v-else class="flex items-center text-secondary-800">
        <MessageSquare class="stroke-secondary-900 h-4" />
        {{ post.comments?.length }}
      </button>
      <span class="flex items-center text-secondary-800">
        <span v-if="post?.reactions?.length">{{ reactionsUsers.length }}</span>
        <button class="text-white rounded-full" @click.stop="handleLike">
          <div class="flex justify-center items-center">
            <div class="flex justify-center items-center h-6 w-6 rounded-full">
              <BlueLike v-if="userLiked" />
              <GreyLike v-else />
            </div>
          </div>
        </button>
      </span>
    </template>
  </Card>

  <AlertModal
    :open="alertDeleteVisable"
    :text="'האם אתה בטוח שתרצה למחוק את הפתק?'"
    :approve-button-text="'מחיקה'"
    @close="alertDeleteVisable = false"
    @approve="deletePost"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref } from 'vue';
import Card from 'primevue/card';
import {
  getPostOwnerName,
  getPostAvatarName,
  timeAgo,
} from '../composables/utils';
import {
  Trash2,
  Pencil,
  Ellipsis,
  MessageSquarePlus,
  MessageSquare,
  ThumbsUp,
} from 'lucide-vue-next';
import { AlertModal } from '@amit/components';
import { Post } from '../types/post';
import {
  BaseDropdown,
  BaseTooltip,
  ListGroup,
  ListGroupItem,
} from '@/components';
import BlueLike from '@/assets/blue-like.svg?component';
import GreyLike from '@/assets/grey-like.svg?component';
import Skeleton from 'primevue/skeleton';
import Avatar from 'primevue/avatar';

const props = defineProps({
  post: {
    type: Object as PropType<Post>,
    required: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  currentUser: {
    type: Object as PropType<{ id: string; name: string }>,
  },
  postBackground: {
    type: String,
    default: '#ffff',
  },
  isOperator: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['delete', 'edit', 'click-post', 'add-like']);
const ownerName = ref('');
const ownerAvatar = ref('');
const alertDeleteVisable = ref(false);
const reactionsUsers = computed(
  () => props.post?.reactions[0].userIds, //.map(reaction => reaction.userIds),
);
const userLiked = computed(() =>
  props.post?.reactions.find(reaction =>
    reaction.userIds.includes(props.currentUser?.id),
  ),
);

const handleEdit = () => {
  emit('edit', props.post);
};
const handleDelete = () => {
  alertDeleteVisable.value = true;
};
const deletePost = () => {
  emit('delete', props.post);
};
const handleLike = () => {
  userLiked.value
    ? emit('add-like', props.post.message_id, true)
    : emit('add-like', props.post.message_id, false);
};

const canDelete = computed(() => {
  return (
    !props.post?.comments.some(
      comment => comment.sender?.userId !== props.currentUser?.id,
    ) || props.isOperator
  );
});

onMounted(() => {
  if (props.post) {
    ownerName.value = getPostOwnerName(props.post?.author?.nickname);
    ownerAvatar.value = getPostAvatarName(props.post?.author?.nickname);
  }
});
</script>

<style>
.post-header {
  background-color: #fff;
}

.bg-yellow-500-custom {
  background-color: #fdf4d8;
}

.post-text-content p {
  font-size: 14px !important;
  margin-bottom: 2px;
}

.post-content {
  position: relative;
  max-height: 300px;
  overflow: hidden;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px; /* Adjust height as needed */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #fdf4d8
  ); /* Adjust background color to match */
  pointer-events: none; /* Ensures the overlay doesn't interfere with interactions */
}
</style>
