<template>
  <div class="flex gap-2 items-center group/add" :class="{ '-mr-7': closable }">
    <div
      v-if="closable"
      class="flex items-center justify-center cursor-pointer rounded-full p-1 bg-secondary-200 hover:bg-secondary-300 invisible group-hover/add:visible"
      @click="$emit('hide')">
      <X class="w-4 h-4 stroke-[1.2px]" />
    </div>
    <textarea
      ref="textareaRef"
      v-model="model"
      :rows="rows"
      :placeholder="placeholder"
      :maxlength="maxLength"
      class="resize-none caret-primary caret-5 p-1 rounded-sm w-full bg-transparent outline-none border-none focus:block-outline overflow-y-hidden placeholder-secondary-500 text-secondary-900"
      :class="[
        {
          'font-simplerBold': type === 'bold',
          'break-all': growVertically,
        },
        placementClasses[placement],
      ]"
      @input="onInput"
      @blur="onBlur"/>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { X } from 'lucide-vue-next';
import type { PropType } from 'vue';

type PlacementType = 'center' | 'start' | 'end';

const placementClasses: Record<PlacementType, string> = {
  center: 'text-center',
  start: 'text-start',
  end: 'text-end',
};

const props = defineProps({
  type: {
    type: String,
    default: 'regular',
  },
  placeholder: {
    type: String,
    default: '',
  },
  closable: {
    type: Boolean,
    default: true,
  },
  placement: {
    type: String as PropType<PlacementType>,
    default: 'start',
  },
  rows: {
    type: Number,
    default: 1,
  },
  maxRows: {
    type: Number,
    default: Infinity,
  },
  maxWidth: {
    type: String,
    default: '98%',
  },
  growVertically: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 500,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel<string>();
defineEmits(['hide']);
const textareaRef = ref<HTMLTextAreaElement | null>(null);

const adjustHeight = () => {
  if (textareaRef.value) {
    textareaRef.value.style.height = 'auto';

    if (!model.value) {
      textareaRef.value.style.height = '32px';
    } else {
      const scrollHeight = textareaRef.value.scrollHeight;
      const lineHeight = parseFloat(
        getComputedStyle(textareaRef.value).lineHeight,
      );
      const maxAllowedHeight = lineHeight * props.maxRows;
      textareaRef.value.style.height = `${Math.min(scrollHeight, maxAllowedHeight)}px`;
    }
  }
};

const onInput = (event: Event) => {
  const textarea = event.target as HTMLTextAreaElement;
  const lines = textarea.value.split('\n');

  if (lines.length > props.maxRows) {
    textarea.value = lines.slice(0, props.maxRows).join('\n');
    event.preventDefault();
  }

  model.value = textarea.value;
  adjustHeight();
};

watch(
  () => props.autoFocus,
  () => {
    props.autoFocus ? textareaRef.value.focus() : textareaRef.value.blur();
  },
);

onMounted(() => {
  adjustHeight();
  if (props.autoFocus) {
    textareaRef.value.focus();
  }
  // textareaRef.value.addEventListener('blur', textareaRef.value.focus());
});

const onBlur = () => {
  if (model.value) {
    model.value = model.value
      .split('\n')
      .filter(line => line.trim() !== '')
      .join('\n');
  }
  if (!model.value && textareaRef.value) {
    textareaRef.value.style.height = '32px';
  }
};

watch(model, adjustHeight);
</script>

<style scoped>
textarea:focus::placeholder {
  color: transparent;
}
</style>
